import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Theme,
    Switch,
    IconButton,
    Grid,
    Tooltip,
    TextField,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    Slide
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';
import UnitModalDetail from './UnitModalDetail';
import SwitchAvailableModal from './_components/_modals/SwitchAvailableModal';
import SwitchPublishModal from './_components/_modals/SwitchPublishModal';
import SwitchEntrustKeyModal from './_components/_modals/SwitchEntrustKeyModal';
import AssignPICListingModal from './_components/_modals/AssignPICListingModal';

/**
 * Icons
 */
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { TransitionProps } from '@mui/material/transitions';

/**
 * Types
 */
import { IColumn } from '../../_components/_dataTable/DataTable';
import {
    dateFormat,
    generalErrorHandler,
    renderToastSuccess,
    renderWarningButton
} from '../../_utils/Helper';
import AdvisorPlacementModal from '../_apartment/_components/AdvisorPlacementModal';
import SwitchAvailableSellModal from './_components/_modals/SwitchAvailableSellModal';
import { generateStyle } from '../../_utils/DefaultStyle';

interface IParams extends Record<string, string | undefined> {
    id?: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = generateStyle((theme: Theme) =>
({
    appBar: {
        position: 'relative'
    },
    addButton: {
        marginBottom: '20px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    filterButton1: {
        color: '#fff',
        backgroundColor: '#f7f7f7'
    },
    filterButton2: {
        color: '#fff',
        backgroundColor: '#DFF2FF'
    },
    filterButton3: {
        color: '#fff',
        backgroundColor: '#c7f4ad'
    },
    filterButton4: {
        color: '#fff',
        backgroundColor: '#feda08'
    },
    filterButton5: {
        color: '#fff',
        backgroundColor: '#f59456'
    },
    filterButton6: {
        color: '#fff',
        backgroundColor: '#f56c6c'
    },
    filterButtonSelected: {
        color: '#fff!important',
        backgroundColor: '#00aeef'
    },
    filterButtonLabel1: {
        color: '#fff',
    },
    filterButtonLabel2: {
        color: '#00aeef',
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}), "ListUnit"
)

const ListUnit = () => {
    const permissions = useContext(PermissionContext)
    const API_URL = process.env.REACT_APP_API_URL + '/unit';
    const { Root, classes } = useStyles();
    const navigate = useNavigate();
    const params = useParams<IParams>()
    const [modalState, setModalState] = useState(0);
    const [redrawIndex, setRedrawIndex] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [advisorModal, setAdvisorModal] = useState(false)
    const [apartmentID, setApartmentID] = useState(0);
    const [picListingState, setPicListingState] = useState({
        unit_id: 0,
        id: 0,
        name: "",
    })

    const [unitCode, setUnitCode] = useState('')

    const handleCloseDetail = () => {
        setModalState(0);
        setUnitCode('')
        window.history.pushState([], 'Unit List', `/unit`);
    };

    const handleSearch = () => {
        if (searchValue) {
            setIsLoading(true);
            DefaultAxios.get(`${process.env.REACT_APP_API_URL}/unit/search-data`, { params: { search: searchValue } })
                .then(res => { navigate(`/unit/${res.data}`); })
                .catch(err => { generalErrorHandler(err); })
                .finally(() => { setIsLoading(false); });
        }
    }

    const showAdvisorPlacementModal = (id: number) => {
        setApartmentID(Number(id));
        setAdvisorModal(true);
    }

    const handleCloseAdvisor = () => {
        setRedrawIndex(redrawIndex + 1);
        setAdvisorModal(false);
    }

    const columns: IColumn[] = [
        {
            name: 'code',
            label: 'Unit Code',
            filterable: true,
            type: 'string',
            width: "150",
            render: (data: string, row: any) => {
                return (
                    <div>
                        <span
                            className={classes.link}
                            onClick={() => {
                                setUnitCode(row.code)
                                setModalState(1)
                                window.history.pushState([], 'Unit List', `/unit/${row.code}`);
                            }}
                        >
                            {data}
                        </span>
                        <IconButton color="primary" href={`${process.env.REACT_APP_MAIN_URL}/${row.code}`} target="_blank">
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                )
            }
        },
        {
            name: 'vacant_days',
            label: 'Vacant Days',
            filterable: true,
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '0', value: 'Rented' },
                { key: '999', value: 'Empty' },
                { key: '20', value: '<= 20' },
                { key: '21', value: '21-40' },
                { key: '41', value: '41-60' },
                { key: '61', value: '61-90' },
                { key: '91', value: '91-180' },
                { key: '181', value: '>181' },
            ],
            columnStyle: (row: any) => {
                let backgroundColor = '#FFFFFF';

                if (+row.vacant_days > 60) {
                    backgroundColor = '#f56c6c';
                } else if (+row.vacant_days > 40) {
                    backgroundColor = '#f59456'
                } else if (+row.vacant_days > 20) {
                    backgroundColor = '#feda08'
                } else if (+row.vacant_days <= 20) {
                    backgroundColor = '#7f4ad'
                } else if (row.vacant_days === 'rented') {
                    backgroundColor = '#DFF2FF'
                }

                return { backgroundColor: backgroundColor };
            },
            render: (data: any) => {
                return +data === -1 ? '-' : (data === 'rented' ? 'Rented' : data);
            }
        },
        {
            name: 'bedroom',
            label: 'Type',
            filterable: true,
            type: 'string',
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '0', value: 'Studio' },
                { key: '1', value: '1BR' },
                { key: '2', value: '2BR' },
                { key: '3', value: '3BR' },
            ],
            render: (data: number) => {
                return data > 0 ? data + 'BR' : 'Studio';
            }
        },
        {
            name: 'apartment_name',
            label: 'Apartemen',
            filterable: true,
            type: 'string'
        },
        {
            name: 'area_name',
            label: 'Area',
            filterable: true,
            type: 'string'
        },
        {
            name: 'pic_name',
            label: 'Owner Name',
            filterable: true,
            type: 'string',
            render: (data: string, row: any) => {
                let link = data && row.is_owner ? `/landlord/${row.landlord_id}` : `/agent/${row.agent_id}`;

                return (
                    data ?
                        <div>
                            <a
                                className={classes.link}
                                href={link}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {data}
                            </a>
                        </div>
                        : '-'
                )
            }
        },
        {
            name: 'is_owner',
            label: 'Ownership',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Owner' },
                { key: '0', value: 'Agent' }
            ],
            render: (data: number) => {
                return data === 1 ? 'Owner' : 'Agent';
            }
        },
        {
            name: 'furnished',
            label: 'Furnish',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: 'Non', value: 'non' },
                { key: 'Semi', value: 'semi' },
                { key: 'Full', value: 'full' }
            ],
        },
        {
            name: 'tower',
            label: 'Tower',
            filterable: true,
            type: 'string'
        },
        {
            name: 'floor_level',
            label: 'Lantai',
            filterable: true,
            type: 'string'
        },
        {
            name: 'unit_number',
            label: 'No. Unit',
            filterable: true,
            type: 'string'
        },
        {
            name: 'zone_wing',
            label: 'Zone/ Wings',
            filterable: true,
            type: 'string',
            render: (data: number) => {
                return data || '-'
            }
        },
        ...(
            permissions['apartment.see-advisor-list']
                ? [
                    {
                        name: 'assigned_pm',
                        label: 'Assigned Property Manager',
                        filterable: true,
                        render: (data: string, row: any) => {
                            let text: any = "";
                            if (data) {
                                if (data.includes(",")) {
                                    let new_data = data.split(",");

                                    text = new_data.map((advisor_data, key) => {
                                        if (advisor_data.includes('IS_NOT_ACTIVE')) {
                                            let advisor_name = advisor_data.replace('IS_NOT_ACTIVE', '');

                                            return (
                                                <span style={{ textDecoration: "line-through", color: "red" }}>
                                                    {advisor_name}{key + 1 === new_data.length ? "" : ","}
                                                </span>
                                            )
                                        } else {
                                            return (
                                                <span>{advisor_data}{key + 1 === new_data.length ? "" : ","}</span>
                                            )
                                        }
                                    })
                                } else {
                                    if (data.includes('IS_NOT_ACTIVE')) {
                                        const advisor_name = data.replace('IS_NOT_ACTIVE', '');
                                        text = (
                                            <span style={{ textDecoration: "line-through", color: "red" }}>
                                                {advisor_name}
                                            </span>
                                        )
                                    } else {
                                        text = <span>{data}</span>
                                    }
                                }
                            } else {
                                text = "-";
                            }

                            return (
                                <>
                                    {text}
                                    <Tooltip title="Edit Advisor Placement">
                                        <IconButton
                                            color="primary"
                                            component="span"
                                            onClick={() => showAdvisorPlacementModal(row.apartment_id)}
                                        >
                                            <GroupIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            );
                        }
                    }
                ]
                : []
        ),
        {
            name: 'pic_listing_name',
            label: 'Assigned PIC Listing',
            filterable: true,
            width: "200",
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return (
                    <>
                        {data}
                        {permissions['unit.assign-pic-listing'] &&
                            <>
                                <Tooltip title="Change PIC Listing">
                                    <IconButton color="primary" onClick={() => {
                                        setPicListingState({
                                            unit_id: row.id,
                                            id: row.pic_listing_id,
                                            name: row.pic_listing_name
                                        })
                                        setModalState(3)
                                    }} size="small">
                                        <EditIcon style={{ fontSize: "inherit" }} />
                                    </IconButton>
                                </Tooltip>

                                {row.pic_listing_id &&
                                    <Tooltip title="Change Advisor">
                                        <IconButton color="secondary" onClick={() => handleUnassignPicListing(row.id, setRows, rows)} size="small">
                                            <HighlightOffIcon style={{ fontSize: "inherit" }} />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: 'is_special_price',
            label: 'Special Price',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Yes' },
                { key: '0', value: 'No' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-special-price']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleSpecialPriceSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_available',
            label: 'Available Rent',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Available' },
                { key: '0', value: 'Unavailable' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                let permission;

                if ([1, 3].includes(+row.category)) { permission = permissions['unit.switch-available-star']; } else { permission = permissions['unit.switch-available']; }

                return permission
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleAvailableSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'rent_date',
            label: 'Available Date',
            filterable: true,
            type: 'date',
            render: (data: string) => {
                return data ? dateFormat(data, 'DD MMM YYYY') : "-";
            }
        },
        {
            name: 'is_available_sell',
            label: 'Available Sell',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Available' },
                { key: '0', value: 'Unavailable' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-available']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleAvailableSellSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_active',
            label: 'Published',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Published' },
                { key: '0', value: 'Unpublished' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-active']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handlePublishSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_entrusted_key',
            label: 'Titip Kunci',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '1', value: 'Yes' },
                { key: '0', value: 'No' }
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                if (permissions['unit.switch-key']) {
                    return <Switch
                        checked={data === 1}
                        onChange={() => handleEntrustedKeySwitch(row, setRows, rows)}
                        color="secondary"
                    />
                } else { return data === 1 ? "YES" : "NO" }
            }
        },
        {
            name: 'category',
            label: 'Category',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: '0', value: 'Reguler' },
                { key: '2', value: 'Verified' },
                { key: '1', value: 'Star Listing' }
            ],
            render: (data: number) => {
                switch (data) {
                    case 0:
                        return 'Reguler';
                    case 1:
                        return 'Star Listing';
                    case 2:
                        return 'Verified';
                    case 3:
                        return 'SLOH'
                    default:
                        return 'N/A';
                }
            }
        },
        {
            name: 'created_at',
            label: 'Created Date',
            filterable: true,
            type: 'date',
            render: (data: string) => {
                return dateFormat(data, 'DD MMM YYYY');
            }
        },
        {
            name: 'creator_name',
            label: 'Created By',
            filterable: true,
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [modalAvailableOpen, setModalAvailableOpen] = useState(false);
    const [modalAvailableSellOpen, setModalAvailableSellOpen] = useState(false)
    const [modalPublishOpen, setModalPublishOpen] = useState(false);
    const [modalEntrustKeyOpen, setModalEntrustKeyOpen] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState<any>({});
    const [tempDTRows, setTempDTRows] = useState<{ setRows: Function, rows: any[] }>({
        setRows: () => { },
        rows: []
    });

    useEffect(() => {
        if (typeof params.id !== "undefined") {
            setModalState(1);
        }
    }, [params]);

    const handleAvailableSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setTempDTRows({
            setRows,
            rows
        });
        setSelectedUnit(unit);
        setModalAvailableOpen(true);
    }

    const handleAvailableSellSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setTempDTRows({
            setRows,
            rows
        });
        setSelectedUnit(unit)
        setModalAvailableSellOpen(true)
    }

    const handlePublishSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setTempDTRows({
            setRows,
            rows
        });
        setSelectedUnit(unit);
        setModalPublishOpen(true);
    }

    const handleSpecialPriceSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setIsLoading(true);
        DefaultAxios.post(`${API_URL}/switch-special-price`, { id: unit.id })
            .then(res => {
                renderToastSuccess('Unit berhasil diganti status special pricenya');
                const newRows = rows.map((element: any) => {
                    if (+element.id === +unit.id) element['is_special_price'] = unit.is_special_price ? 0 : 1;
                    return element
                })
                setRows(newRows);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleUnassignPicListing = (id: string, setRows: Function, rows: any[]) => {
        renderWarningButton('Apakah anda yakin unassign PIC Listing ini?')
            .then((result) => {
                if (result.value) {
                    setIsLoading(true);
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/assign-pic-listing/unassign`, { id: id })
                        .then(res => {
                            renderToastSuccess('PIC Listing berhasil diunassign');
                            setRedrawIndex(redrawIndex + 1);
                        })
                        .catch(err => {
                            generalErrorHandler(err);
                        })
                        .finally(() => {
                            setIsLoading(false);
                        })
                }
            })
    }

    const handleEntrustedKeySwitch = (unit: any, setRows: Function, rows: any[]) => {
        setTempDTRows({
            setRows,
            rows
        });
        setSelectedUnit(unit);
        setModalEntrustKeyOpen(true);
    }

    const unitCodeSearch = <Grid container spacing={3} style={{ marginBottom: '10px' }}>
        <Grid item xs={2}>
            <TextField
                fullWidth
                variant="outlined"
                label="Search"
                value={searchValue}
                placeholder="Isi Kode Unit"
                onChange={(e) => setSearchValue(e.target.value)}
            />
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
            >
                Search
            </Button>
        </Grid>
    </Grid>



    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <Dialog fullScreen open={advisorModal} onClose={handleCloseAdvisor} TransitionComponent={Transition}>
                <Root>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseAdvisor} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Advisor Placement
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <AdvisorPlacementModal
                        apartment_id={apartmentID}
                    />
                </Root>
            </Dialog>

            <SwitchAvailableSellModal
                id={selectedUnit.id}
                isAvailable={selectedUnit.is_available_sell}
                onClose={() => setModalAvailableSellOpen(false)}
                open={modalAvailableSellOpen}
                callBackSubmitSuccess={() => {
                    const newRows = tempDTRows.rows.map((element: any) => {
                        if (+element.id === +selectedUnit.id) element['is_available_sell'] = selectedUnit.is_available_sell ? 0 : 1;
                        return element
                    })
                    tempDTRows.setRows(newRows);
                }}
                setIsLoading={(value: boolean) => setIsLoading(value)}
            />

            <SwitchAvailableModal
                id={selectedUnit.id}
                open={modalAvailableOpen}
                isAvailable={selectedUnit.is_available}
                onClose={() => setModalAvailableOpen(false)}
                callBackSubmitSuccess={() => {
                    const newRows = tempDTRows.rows.map((element: any) => {
                        if (+element.id === +selectedUnit.id) element['is_available'] = selectedUnit.is_available ? 0 : 1;
                        return element
                    })
                    tempDTRows.setRows(newRows);
                }}
                setIsLoading={(value: boolean) => setIsLoading(value)}
            />

            <SwitchPublishModal
                id={selectedUnit.id}
                open={modalPublishOpen}
                isActive={selectedUnit.is_active}
                isOwner={selectedUnit.is_owner}
                onClose={() => setModalPublishOpen(false)}
                callBackSubmitSuccess={() => {
                    const newRows = tempDTRows.rows.map((element: any) => {
                        if (+element.id === +selectedUnit.id) element['is_active'] = selectedUnit.is_active ? 0 : 1;
                        return element
                    })
                    tempDTRows.setRows(newRows);
                }}
                setIsLoading={(value: boolean) => setIsLoading(value)}
            />

            <SwitchEntrustKeyModal
                id={selectedUnit.id}
                open={modalEntrustKeyOpen}
                status={{
                    isEntrustedKey: selectedUnit.is_entrusted_key,
                    category: selectedUnit.category
                }}
                onClose={() => setModalEntrustKeyOpen(false)}
                callBackSubmitSuccess={(isCategoryChanged) => {
                    const newRows = tempDTRows.rows.map((element: any) => {
                        if (+element.id === +selectedUnit.id) {
                            element['is_entrusted_key'] = selectedUnit.is_entrusted_key ? 0 : 1;
                            if (isCategoryChanged) {
                                element['category'] = selectedUnit.category ? 0 : 1;
                            }
                        }
                        return element
                    })
                    tempDTRows.setRows(newRows);
                }}
                setIsLoading={(value: boolean) => setIsLoading(value)}
            />

            <UnitModalDetail
                open={modalState === 1}
                unitCode={unitCode}
                onModalClose={handleCloseDetail}
            />

            <AssignPICListingModal
                unitId={picListingState.unit_id}
                open={modalState === 3}
                picId={picListingState.id}
                name={picListingState.name}
                onClose={() => {
                    setModalState(0)
                }}
                callBackSubmitSuccess={() => {
                    setRedrawIndex(redrawIndex + 1);
                }}
            />

            {permissions['unit.create'] &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.addButton}
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/unit/add')}
                >
                    Add Listing
                </Button>
            }

            {permissions['unit.list'] ?
                <>
                    {unitCodeSearch}
                    <DataTable
                        reDraw={redrawIndex}
                        url={API_URL}
                        columns={columns}
                        rowCallback={(row: any) => {
                            if (+row.is_requested_change_availability === 1) {
                                return {
                                    backgroundColor: 'yellow'
                                };
                            }

                            return null;
                        }}
                        filterButtons={[
                            {
                                key: 'is_sell',
                                defaultValue: null,
                                options: [
                                    {
                                        value: null,
                                        label: 'All',
                                    },
                                    {
                                        value: '0',
                                        label: 'Not Available Sell',
                                    },
                                    {
                                        value: '1',
                                        label: 'Available Sell',
                                    },
                                ]
                            },
                        ]}
                    />
                </>
                :
                unitCodeSearch
            }
        </Root>
    );
}

export default ListUnit;