import React, { useState } from "react";
import { Grid, CardMedia, Theme, Card, Box } from "@mui/material";
import { generateStyle } from "../../../_utils/DefaultStyle";
import ImagePreview from "../../../_components/ImagePreview";
import PDFImage from "../../../Assets/Images/png/pdf.png";

interface Props {
  images: { id: string; url: string }[];
  selectedImage: string | undefined;
  onImageClick: (id: string) => void;
}

const useStyles = generateStyle(
  (theme: Theme) => ({
    submitButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    mainImage: {
      display: "block",
      width: "100%",
      aspectRatio: '1/1',
      cursor: "pointer",
      objectFit: 'contain',
      padding: 0
    },
    imageThumbnail: {
      width: "64px",
      height: "64px",
      cursor: "pointer",
    },
    cardContent: {
      display: "grid",
      //   justifyContent: "center",
    },
  }),
  "CardImageStatement"
);

const CardImageStatement = (props: Props) => {
  const [selectedImageId, setSelectedImageId] = useState<string | undefined>(
    props.selectedImage
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const { Root, classes } = useStyles();

  const handleThumbnailClick = (id: string, url: string) => {
    setSelectedImageId(id);
    props.onImageClick(id);
  };

  const selectedImage = props.images.find((img) => img.id === selectedImageId);
  const selectedImageUrl = selectedImage?.url || props.images[0]?.url || "";

  const handleMainImageClick = () => {
    if (selectedImageUrl.includes(".pdf")) {
      window.open(selectedImageUrl, "_blank");
    } else {
      setIsPreviewOpen(true);
    }
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  return (
    <>
      <Card sx={{ padding: "10px"}}>
        <Root>
          <Grid container className={classes.cardContent} spacing={1}>
            <Grid item xs={12} sx={{ p: 0 }}>
              {selectedImageUrl ? (
                <Box
                  component="img"
                  src={
                    selectedImageUrl.includes(".pdf")
                      ? PDFImage
                      : selectedImageUrl
                  }
                  alt="preview"
                  className={classes.mainImage}
                  onClick={handleMainImageClick}
                  sx={{ p: 0, m: 0 }}
                />
              ) : (
                <div>No image available</div>
              )}
            </Grid>
            {/* thumbnail */}
            {props.images.length > 1 && (
              <Grid item container xs={12} spacing={1}>
                {props.images.map((img) => (
                  <Grid item key={img.id}>
                    <CardMedia
                      component="img"
                      image={img.url.includes(".pdf") ? PDFImage : img.url}
                      alt="thumbnail"
                      className={classes.imageThumbnail}
                      onClick={() => handleThumbnailClick(img.id, img.url)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Root>
      </Card>
      {isPreviewOpen && (
        <ImagePreview image={selectedImageUrl} onClose={handleClosePreview} />
      )}
    </>
  );
};

export default CardImageStatement;
