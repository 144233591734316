import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

/**
 * Components
 */
import DateRangePicker, { DateRange, OnDatesChange } from '../../../../_components/DateRangePicker'

/**
 * Utils
 */
import DefaultAxios from '../../../../_utils/DefaultAxios'
import { generalErrorHandler, renderToastSuccess } from '../../../../_utils/Helper'
import { format } from 'date-fns'
import LoadingScreen from '../../../../_components/LoadingScreen'

type Props = {
    open: boolean
    onClose: (refresh: boolean) => void
    id: string
    defaultValue: {
        start_date: DateRange
        end_date: DateRange
    }
}

const RentPeriodEditModal = (props: Props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [date, setDate] = useState<Props["defaultValue"]>({
        start_date: null,
        end_date: null
    })

    const handleChangeDate = ({ startDate, endDate }: OnDatesChange) => {
        setDate({
            start_date: startDate,
            end_date: endDate
        })
    }

    const handleSubmit = () => {
        setIsLoading(true)
        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/new-closing/change-closing-date`, {
            id: props.id,
            start_date: date.start_date ? format(date.start_date, 'dd-MM-yyyy') : null,
            end_date: date.end_date ? format(date.end_date, 'dd-MM-yyyy') : null,
        })
            .then(res => res.data)
            .then(res => {
                renderToastSuccess(`Data berhasil dirubah`)
                props.onClose(true)
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (props.defaultValue.end_date || props.defaultValue.start_date) {
            setDate({
                start_date: props.defaultValue.start_date,
                end_date: props.defaultValue.end_date
            })
        }
    }, [props.defaultValue])

    useEffect(() => {
        if (!props.open) {
            setDate({
                start_date: null,
                end_date: null
            })
        }
    }, [props.open])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <LoadingScreen fullScreen open={isLoading}/>
            <DialogTitle>Change Rent Period</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DateRangePicker
                            startDate={date.start_date}
                            endDate={date.end_date}
                            onDatesChange={handleChangeDate}
                            onFinished={() => { }}
                            fullWidth

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ float: 'right' }}
                            disabled={isLoading}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default RentPeriodEditModal