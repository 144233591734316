import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import TextArea from '../../../_components/_form/TextArea';
import ButtonWithLoading from '../../../_components/_form/ButtonWithLoading';
import DefaultAxios from '../../../_utils/DefaultAxios';
import { generalErrorHandler, renderToastSuccess } from '../../../_utils/Helper';
import { useNavigate } from 'react-router-dom';

interface IProps {
    id: number;
    open: boolean;
    onClose: () => void;
    setIsLoading: Function;
    isLoading?: boolean
    onSucces: () => void;
}

const RejectRequestModal = ({open, onClose, id, isLoading, setIsLoading, onSucces}: IProps) => {
    const [canceledReason, setCanceledReason] = useState("")
    const navigate = useNavigate()
    
    const handleAvailableSubmit = () => {
        setIsLoading(true);
    
        const payload = {
            id,
            canceled_reason: canceledReason
        };
        
         DefaultAxios.post(`${process.env.REACT_APP_API_URL}/admin/closing-request/cancel`, payload)
             .then(() => {
            renderToastSuccess('Berhasil Reject Closing Request');
            onClose();
            onSucces()
            navigate("/closing-request")
             })
             .catch((err) => {
                 generalErrorHandler(err); 
             })
             .finally(() => {
                 setIsLoading(false); 
             });
    };
    

  return (
    <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => onClose()}
        >
            <DialogTitle>Isi Alasan</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    <Grid item xs={12}>
                        <TextArea
                            label="Canceled Reason"
                            name="canceled_reason"
                            value={canceledReason}
                            onChange={(e) => setCanceledReason(e.target.value)}
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonWithLoading onClick={handleAvailableSubmit} color="primary" isLoading={isLoading}>
                    Submit
                </ButtonWithLoading>
            </DialogActions>
        </Dialog>
  )
}

export default RejectRequestModal