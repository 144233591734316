import React, { useState, useContext } from 'react';
import {
    Switch,
    IconButton,
    TextField,
    MenuItem,
} from '@mui/material';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';

/**
 * Types
 */
import { IColumn } from '../../_components/_dataTable/DataTable';
import {
    converNumberFormat,
    generalErrorHandler,
    renderToastSuccess,
    renderWarningButton,
} from '../../_utils/Helper';
import { Check, Edit, Visibility, WhatsApp } from '@mui/icons-material';
import SwitchAvailableSellModal from '../_unit/_components/_modals/SwitchAvailableSellModal';
import DefaultAxios from '../../_utils/DefaultAxios';


const UnitSellList = () => {
    const permissions = useContext(PermissionContext)
    const API_URL = process.env.REACT_APP_API_URL + '/unit-sell';

    const [modalAvailableSellOpen, setModalAvailableSellOpen] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState<any>({});
    const [reDraw, setReDraw] = useState(0)
    const [selectedNotesRow, setSelectedNotesRow] = useState<null | any>(null)
    const [columnEditActive, setColumnEditActive] = useState("")

    const handleAvailableSellSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setSelectedUnit(unit)
        setModalAvailableSellOpen(true)
    }

    const handleIsBUSwitch = (unit: any, setRows: Function, rows: any[]) => {
        setSelectedUnit(unit)
        renderWarningButton('Apakah anda yakin ingin menganti status is BU ?')
            .then(res => {
                if (res.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/toggle-bu`, { id: unit.id })
                        .then(res => res.data)
                        .then(res => {
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +unit.id) element.is_bu = unit.is_bu ? 0 : 1
                                return element
                            })
                            setReDraw(prev => prev + 1)
                            setRows(newRows);
                        })
                        .catch(generalErrorHandler)
                        .finally(() => {
                            setIsLoading(false)
                        })

                }
            })
    }

    const handleChangeCertificate = (value: string, unit: any, rows: any[], setRows: any) => {
        setSelectedUnit(unit)
        renderWarningButton('Apakah anda yakin ingin menganti jenis Certificate ?')
            .then(res => {
                if (res.value) {
                    setIsLoading(true)
                    DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit/change-certificate`, {
                        id: unit.id,
                        certificate: value
                    })
                        .then(res => res.data)
                        .then(res => {
                            console.log(rows)
                            const newRows = rows.map((element: any) => {
                                if (+element.id === +unit.id) element.certificate = value
                                return element
                            })
                            setReDraw(prev => prev + 1)
                            setRows(newRows);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })

                }
            })
    }

    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setSelectedNotesRow((prev: any) => ({
            ...prev,
            admin_flipper_notes: value,
        }))
    }

    const saveNewNotes = (rows: any, setRows: any) => {
        if (!selectedNotesRow) return;

        const data = {
            id: selectedNotesRow.id,
            type: "unit",
            admin_flipper_notes: selectedNotesRow.admin_flipper_notes,
        }

        setIsLoading(true)

        DefaultAxios.post(`${process.env.REACT_APP_API_URL}/admin-flipper/notes`, data)
            .then(res => res.data)
            .then(() => {
                setSelectedNotesRow(null)

                renderToastSuccess('Berhasil mengubah notes')
                const newRows = rows.map((element: any) => {
                    if (+element.id === +data.id) element['notes'] = data.admin_flipper_notes;
                    return element
                })
                setReDraw(prev => prev + 1)
                setRows(newRows);
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSubmitSellPrice = (rows: any[], setRows: any) => {
        if (!selectedUnit) return;

        const data = {
            id: selectedUnit.id,
            [columnEditActive]: selectedUnit[columnEditActive]
        }

        setIsLoading(true)

        let axios;
        if (columnEditActive === 'selling_price') {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-sell/change-selling-price`, data)
        } else {
            axios = DefaultAxios.post(`${process.env.REACT_APP_API_URL}/unit-sell/change-basic-price`, data)
        }

        axios
            .then(res => res.data)
            .then(() => {
                setSelectedUnit(null)

                renderToastSuccess('Berhasil mengubah harga')
                const newRows = rows.map((element: any) => {
                    if (+element.id === +data.id) element[columnEditActive] = data[columnEditActive];
                    return element
                })
                setReDraw(prev => prev + 1)
                setRows(newRows);
                setColumnEditActive('')
            })
            .catch(generalErrorHandler)
            .finally(() => {
                setIsLoading(false)
            })
    }

    const columns: IColumn[] = [
        {
            name: 'code',
            label: 'Kode',
            filterable: true,
            type: 'string',
            render: (data: string) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{data}</span>
                        <IconButton
                            href={`${process.env.REACT_APP_MAIN_URL}/${data}`}
                            target='_blank'
                            color='primary'
                        >
                            <Visibility />
                        </IconButton>
                    </div>
                );
            },
        },
        {
            name: 'owner_name',
            label: 'Nama Owner',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'owner_phone',
            label: 'Nomor Owner',
            filterable: true,
            type: 'string',
            render: (data: string) => data ?
                <div>
                    <span>
                        {data}
                    </span>
                    <IconButton color="primary" href={`https://api.whatsapp.com/send?phone=${data}`} target="_blank" rel='noopener noreferrer'>
                        <WhatsApp />
                    </IconButton>
                </div>
                : '-',
        },
        {
            name: 'apartment_name',
            label: 'Apartment',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'unit_detail',
            label: 'Unit Detail',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'zone_wing',
            label: 'Zone/ Wings',
            filterable: true,
            type: 'string',
            render: (data: number) => data || '-',
        },
        {
            name: 'furnished',
            label: 'Furnish',
            filterable: true,
            type: 'string',
            options: [
                { key: '', value: 'All' },
                { key: 'full', value: 'Full Furnished' },
                { key: 'semi', value: 'Semi Furnished' },
                { key: 'non', value: 'Non Furnished' },
            ],
            render: (data: string) => {
                switch (data) {
                    case 'non':
                        return 'Non Furnished';
                    case 'semi':
                        return 'Semi Furnished';
                    case 'full':
                        return 'Full Furnished';
                    default:
                        return '';
                }
            }
        },
        {
            name: 'view_direction',
            label: 'View',
            filterable: true,
            type: 'string',
        },
        {
            name: 'unit_area',
            label: 'Unit Area',
            filterable: true,
            type: 'number',
            render: (data: number) => data || '-',
        },
        {
            name: 'certificate_label',
            label: 'Certificate',
            filterable: true,
            type: 'string',
            options: [
                { key: '', value: 'All' },
                { key: '0', value: 'Undefined' },
                { key: '1', value: 'SHM' },
                { key: '2', value: 'PPJB' },
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => (
                permissions['unit.change-certificate'] ? 
                     <TextField
                        value={row['certificate']}
                        onChange={(e) => handleChangeCertificate(e.target.value, row, rows, setRows )}
                        select
                        
                    >
                        {!row['certificate'] ? <MenuItem value='0' disabled>Undefined</MenuItem> : null }
                        <MenuItem value='1'>SHM</MenuItem>
                        <MenuItem value='2'>PPJB</MenuItem>
                    </TextField>
                : data || '-'
            )
        }

        // create function to createFooBarr exampe
        ,
        {
            name: 'area_name',
            label: 'Area',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'price_per_12_months',
            label: 'Harga Sewa Tahunan',
            filterable: true,
            type: 'number',
            render: (data: string) => data ? converNumberFormat(data) : '-',
        },
        {
            name: 'selling_price',
            label: 'Harga Jual',
            filterable: true,
            columnStyle: {minWidth: 180},
            type: 'number',
            render: (data: string, row: any, rows: any[], index: number, setRows: any) => {
                return (
                    (selectedUnit && selectedUnit.id === row.id && columnEditActive === 'selling_price') ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                value={selectedUnit.selling_price}
                                onChange={(e) => setSelectedUnit((prev: any) => ({ ...prev, selling_price: e.target.value }))}
                                disabled={isLoading}
                                size='small'
                                variant='standard'
                                style={{
                                    maxWidth: 'calc(100% - 30px)',
                                }}
                            />
                            <IconButton
                                onClick={() => handleSubmitSellPrice(rows, setRows)}
                                size='small'
                                disabled={isLoading}
                            >
                                <Check fontSize='small' color='success' />
                            </IconButton>
                        </div> :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {data ? converNumberFormat(data) :'-'}
                            </span>
                            {permissions['unit.sell-manage'] &&
                                <IconButton
                                    onClick={() => {
                                        setSelectedUnit(row)
                                        setColumnEditActive('selling_price')
                                    }}
                                    size='small'
                                    style={{ verticalAlign: 'top' }}
                                >
                                    <Edit fontSize='small' color='primary' />
                                </IconButton>
                            }
                        </div>
                )
            },
        },
        {
            name: 'basic_price_sell',
            label: 'Harga Basic',
            filterable: true,
            columnStyle: {minWidth: 180},
            type: 'number',
            render: (data: string, row: any, rows: any[], index: number, setRows: any) => {
                return (
                    (selectedUnit && selectedUnit.id === row.id && columnEditActive === 'basic_price_sell') ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                value={selectedUnit.basic_price_sell}
                                onChange={(e) => setSelectedUnit((prev: any) => ({ ...prev, basic_price_sell: e.target.value }))}
                                disabled={isLoading}
                                size='small'
                                variant='standard'
                                style={{
                                    maxWidth: 'calc(100% - 30px)',
                                }}
                            />
                            <IconButton
                                onClick={() => handleSubmitSellPrice(rows, setRows)}
                                size='small'
                                disabled={isLoading}
                            >
                                <Check fontSize='small' color='success' />
                            </IconButton>
                        </div> :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                }}
                            >
                                 {data ? converNumberFormat(data) :'-'}
                            </span>
                            {permissions['unit.sell-manage'] &&
                                <IconButton
                                    onClick={() => {
                                        setSelectedUnit(row)
                                        setColumnEditActive('basic_price_sell')
                                    }}
                                    size='small'
                                    style={{ verticalAlign: 'top' }}
                                >
                                    <Edit fontSize='small' color='primary' />
                                </IconButton>
                            }
                        </div>
                )
            },
        },
        {
            name: 'is_available_sell',
            label: 'Available Sell',
            defaultOption: '',
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return permissions['unit.switch-available']
                    ? <Switch
                        checked={data === 1}
                        onChange={() => handleAvailableSellSwitch(row, setRows, rows)}
                        color="secondary"
                    />
                    : (data === 1 ? 'Yes' : 'No')
            }
        },
        {
            name: 'is_bu',
            label: 'Is BU',
            filterable: true,
            defaultOption: '',
            options: [
                { key: '', value: 'All' },
                { key: 1, value: 'Yes' },
                { key: 0, value: 'No' },
            ],
            render: (data: number, row: any, name: string, options: any[], setRows: Function, rows: any[]) => {
                return (
                    permissions['unit.toggle-is-bu'] ?
                        <Switch
                            checked={data === 1}
                            onChange={() => handleIsBUSwitch(row, setRows, rows)}
                            color="secondary"
                        /> :
                        data ? 'Yes' : 'No'
                )
            }
        },
        {
            name: 'admin_flipper_notes',
            label: 'Remarks',
            filterable: true,
            columnStyle: {
                minWidth: 200,
            },
            render: (data: string, row: any, rows: any, index: number, setRows: any) => {
                return (
                    selectedNotesRow?.id === row.id ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                value={selectedNotesRow?.admin_flipper_notes || ''}
                                onChange={onNotesChange}
                                disabled={isLoading}
                                size='small'
                                variant='standard'
                                style={{
                                    maxWidth: 'calc(100% - 30px)',
                                }}
                                multiline
                            />
                            <IconButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    saveNewNotes(rows, setRows);
                                }}
                                size='small'
                                disabled={isLoading}
                            >
                                <Check fontSize='small' color='success' />
                            </IconButton>
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {data || '-'}
                            </span>
                            {permissions['enquiry-buy.manage'] &&
                                <IconButton
                                    onClick={() => setSelectedNotesRow(row)}
                                    size='small'
                                    style={{ verticalAlign: 'top' }}
                                >
                                    <Edit fontSize='small' color='primary' />
                                </IconButton>
                            }
                        </div>
                );
            }
        },
        {
            name: 'available_at',
            label: 'Tanggal Terakhir Available',
            filterable: true,
            type: 'date',
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd MMM yyyy') : '-'
            }
        },
        {
            name: 'created_at',
            label: 'Tanggal Dibuat',
            filterable: true,
            type: 'date',
            render: (data: any, row: any) => {
                return data ? format(new Date(data), 'dd MMM yyyy') : '-'
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <LoadingScreen open={isLoading} fullScreen />
            <SwitchAvailableSellModal
                id={selectedUnit ? selectedUnit.id : 0}
                isAvailable={selectedUnit ? selectedUnit.is_available_sell : 0}
                onClose={() => setModalAvailableSellOpen(false)}
                open={modalAvailableSellOpen}
                // callBackSubmitSuccess={() => {
                //     const newRows = tempDTRows.rows.map((element: any) => {
                //         if (+element.id === +selectedUnit.id) element['is_available_sell'] = selectedUnit.is_available_sell ? 0 : 1;
                //         return element
                //     })
                //     tempDTRows.setRows(newRows);
                // }}
                callBackSubmitSuccess={() => {
                    setReDraw(prev => prev + 1)
                }}
                setIsLoading={(value: boolean) => setIsLoading(value)}
            />

            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDraw}
            />
        </>
    );
}

export default UnitSellList;