import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from "@mui/material";
import Swal from "sweetalert2";

/* Utils */
import { generateStyle } from "../../_utils/DefaultStyle";
import {
  currencyToNumber,
  formatDate,
  generalErrorHandler,
  numberToCurrency,
  renderErrorButton,
  renderWarningButton,
} from "../../_utils/Helper";
import {
  IValidationAlias,
  IValidationErrors,
  IValidationRules,
  validateData,
} from "../../_utils/Validation";
import DefaultAxios from "../../_utils/DefaultAxios";

/* Components */
import AsyncAutoComplete, {
  IAutoCompleteOption,
} from "../../_components/_form/AsyncAutoComplete";
import LoadingScreen from "../../_components/LoadingScreen";
import CardImageStatement from "./components/CardImageStatement";
import { StatementFormState } from "../_statement/StatementList";

const useStyles = generateStyle(
  (theme: Theme) => ({
    submitButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
  }),
  "StatementAssigner"
);

export interface IItemsStatement {
  date: string;
  description: string;
  amount: string;
  id: number;
  is_other: 0 | 1;
  type: string;
  trailer: string;
  closing_id: string;
  closing_label: string;
  reason: string;
  transaction_type: string;
}

const initialState = {
  id: "",
  closing_id: "",
  closing_label: "",
  closing_name: "",
  keyword: "",
  amount: "",
};

const StatementAssigner = () => {
  const { Root, classes } = useStyles();
  const API_URL = process.env.REACT_APP_API_URL + "/bank-account-statement";
  const [isLoading, setIsLoading] = useState(false);
  const [inputState, setInputState] = useState(initialState);
  const [itemsStatement, setItemsStatement] = useState<IItemsStatement[]>([]);
  const [imageStatement, setImageStatement] = useState<
    { id: string; url: string }[]
  >([]);
  const [selectedImageId, setSelectedImageId] = useState<string | undefined>(
    undefined
  );
  const [error, setError] = useState<IValidationErrors>({});

  useEffect(() => {
    if (inputState.closing_id !== "") {
      fetchImageStatement(inputState.closing_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState.closing_id]);

  useEffect(() => {
    /* menghilangkan error nya jika salah satu sudah di isi */
    if (inputState.amount || inputState.keyword) {
      setError((prev) => ({
        ...prev,
        amount: '',
        keyword: '', 
      }));
    }
  }, [inputState.amount, inputState.keyword]);

  const fetchImageStatement = (id: string) => {
    setIsLoading(true);
    DefaultAxios.get(
      `${process.env.REACT_APP_API_URL}/new-closing/statement-assign/payment-file?id=${id}`
    )
      .then((res) => {
        setImageStatement(
          res.data.map((img: any, index: number) => ({
            id: index,
            url: img.filepath_url,
          }))
        );
      })
      .catch(generalErrorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAutocompleteClosing = (
    name: string,
    value: IAutoCompleteOption
  ) => {
    const errorMessage = "";

    setInputState((prevState) => ({
      ...prevState,
      closing_id: value.id.toString(),
      closing_name: value.label,
      closing_label: value.label,
    }));

    setItemsStatement((prevState) =>
      prevState.map((item) => ({
        ...item,
        closing_id: value.id.toString(),
        closing_label: value.label
      }))
    );

    if (!!value || (value as IAutoCompleteOption[]).length > 0) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [name]: errorMessage,
      }));
    }
  };

  const handleAutocompleteInputChanged = (e: any, name: string) => {
    const newValue = e?.target?.value || "";
    setInputState((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setItemsStatement((prevState) =>
      prevState.map((item) => ({
        ...item,
        closing_id: newValue,
      }))
    );

    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    let name = target.name;
    let value = target.value;
    const isNumber = ["amount"].includes(name);

    setInputState((prev) => ({
      ...prev,
      [name]: isNumber ? currencyToNumber(value) : value,
    }));

    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleChooseStatement = (item: StatementFormState) => {
    submitData(item);
    setSelectedImageId(undefined);
  };

  const submitData = (item: StatementFormState) => {
    renderWarningButton("Apakah anda yakin ingin assign statement ini ?")
      .then((res) => {
        if (res.value) {
          setIsLoading(true);
          DefaultAxios.put(`${API_URL}/${item.id}`, {
            ...item,
            closing_id: inputState.closing_id,
            closing_label: inputState.closing_label,
            is_other: false,
            type: "CR",
            description: "",
            reason: "",
            transaction_type: "",
          }).then((res) => {
            Swal.fire({
              title: "Statement Assigned",
              icon: "success",
              onAfterClose: () => {
                // setModalStatement(false);
              },
              timer: 1000,
            });
            setInputState({
              id: "",
              closing_id: "",
              closing_label: "",
              closing_name: "",
              keyword: "",
              amount: "",
            });
            // setItemsStatement((prevState) =>
            //   prevState.filter((i) => i.id !== item.id)
            // ); /* menghapus data statement berdasarkan id, jika data nya banyak */
            setItemsStatement([])
          });
        }
      })
      .catch((err) => {
        generalErrorHandler(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = () => {
    const { isValid, errors } = validateData(
      inputState,
      inputStateRules,
      inputStateAlias
    );

    if (!inputState.amount && !inputState.keyword) {
      errors.amount = "At least one of 'Nominal' or 'Keyword' must be filled.";
      errors.keyword = "At least one of 'Nominal' or 'Keyword' must be filled.";
    }

    if (!isValid || Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }
    setIsLoading(true);
    DefaultAxios.get(
      `${process.env.REACT_APP_API_URL}/new-closing/statement-assign/non-assign`,
      {
        params: {
          keyword: inputState.keyword || null,
          amount: inputState.amount || null,
        },
      }
    )
      .then((res) => res.data)
      .then((data) => {
        setItemsStatement(data);
        if (data.length <= 0) {
          renderErrorButton("Data statement tidak ditemukan");
        }
      })
      .catch(generalErrorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleImageClick = (id: string) => {
    setSelectedImageId(id);
  };

  return (
    <Root>
      <LoadingScreen fullScreen open={isLoading} />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AsyncAutoComplete
                label="Kode Closing"
                name="closing_id"
                initialQuery={inputState.closing_label}
                onChange={handleAutocompleteClosing}
                onInputChange={handleAutocompleteInputChanged}
                url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/closing`}
                placeholder="Closing"
                errorText={error.closing_id}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Keyword"
                multiline
                name="keyword"
                value={inputState.keyword}
                onChange={handleChange}
                error={!!error.keyword}
                helperText={error.keyword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nominal"
                multiline
                name="amount"
                value={numberToCurrency(inputState.amount, "Rp")}
                onChange={handleChange}
                error={!!error.amount}
                helperText={error.amount}
              />
            </Grid>
            <Grid item xs={12} className={classes.submitButton}>
              <Button variant="contained" color="primary" onClick={onSubmit}>
                Submit
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              {itemsStatement.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span>Tanggal</span>
                        </TableCell>
                        <TableCell /* align="right" */>
                          <span>Deskripsi</span>
                        </TableCell>
                        <TableCell /* align="right" */>
                          <span>Nominal</span>
                        </TableCell>
                        <TableCell /* align="right" */>
                          <span>Action</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
                        itemsStatement.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{formatDate(item.date)}</TableCell>
                            <TableCell /* align="right" */>
                              {item.trailer}
                            </TableCell>
                            <TableCell /* align="right" */>
                              {numberToCurrency(item.amount, "Rp")}
                            </TableCell>
                            <TableCell /* align="right" */>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleChooseStatement(item)}
                              >
                                Pilih
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} spacing={2}>
          <Grid container>
            <Grid item xs={12}>
              {inputState.closing_id && (
                <CardImageStatement
                  images={imageStatement}
                  selectedImage={selectedImageId}
                  onImageClick={handleImageClick}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

const inputStateAlias: IValidationAlias = {
  closing_id: "Closing",
  amount: "Nominal",
  keyword: "Keyword",
};

const inputStateRules: IValidationRules = {
  closing_id: "required",
  amount: "required_if:keyword=filled",
  keyword: "required_if:amount=filled",
};

export default StatementAssigner;
