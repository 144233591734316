import React, { useState, useEffect, useContext } from 'react'
import { Theme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Utils
 */
import { generateStyle } from '../../_utils/DefaultStyle';
import ClosingRequestModal from './_components/ClosingRequestModal';

/**
 * Components
 */
import DataTable from '../../_components/_dataTable/DataTable';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { format } from 'date-fns';

interface IProps {
    id?: string
}
interface IParams extends Record<string, string | undefined> {
    id?: string
}

const useStyles = generateStyle((theme: Theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },  
}), "ClosingRequestList")

const ClosingRequestList = (props: IProps) => {
    const { Root, classes } = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/admin/closing-request`;
    const permissions = useContext(PermissionContext)
    const navigate = useNavigate();
    const params = useParams<IParams>();
    const [modalCode, setModalCode] = useState("");
    const [reDrawDatatable, setReDrawDatatable] = useState(new Date().getTime());

    useEffect(() => {
        if (params.id) {
            setModalCode(params.id);
        } else {
            setModalCode("");
        }
    }, [params.id]);

    const columns = [
        {
            name: 'created_at',
            label: 'Tanggal Input',
            filterable: true,
            sortable: true,
            type: 'date',
            render: (data: string) => data ? format(new Date(data), 'dd MMM yyyy') : '-',
        },
        {
            name: 'start_date',
            label: 'Tanggal Check-In',
            filterable: true,
            sortable: true,
            type: 'date',
            render: (data: string) => {
                if (!data) return '-'; 
                const date = new Date(data);
                return isNaN(date.getTime()) ? '-' : format(date, 'dd MMM yyyy');
            },
        },
        {
            name: 'customer_name',
            label: 'Nama Tenant',
            filterable: true,
            type: 'string',
            render: (data: string, row: any) => {   
                return (
                    <span
                       className={classes.customerName}
                       style={{cursor: 'pointer', color: "#1976d2"}}
                        onClick={() => {
                            setModalCode(row.id);
                            window.history.pushState('', '', `/closing-request/${row.id}`);
                        }}
                    >
                        {data || '-'}
                    </span>
                );
            },
        },
        {
            name: 'requested_by_name',
            label: 'Requester',
            filterable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },
        {
            name: 'status',
            label: 'Status',
            filterable: true,
            type: 'number',
            options: [
                { key: '', value: 'All' },
                { key: 0, value: 'On Progress' },
                { key: 1, value: 'Submitted' },
                { key: 2, value: 'Canceled' },
            ],
            render: (data: number): string => {
                switch (data) {
                    case 0:
                        return "On Progress";
                    case 1:
                        return "Submitted";
                    case 2:
                        return "Canceled";
                    default:
                        return ""; 
                }
            }
        },
        {
            name: 'enquiry_code',
            label: 'Kode Enquiry',
            filterable: true,
            sortable: true,
            type: 'string',
            render: (data: string) => data || '-',
        },

    ]
    return (
        <Root>
            <DataTable
                url={API_URL}
                columns={columns}
                reDraw={reDrawDatatable}
            />
            {modalCode !== "" ?
                <ClosingRequestModal
                    code={modalCode}
                    open={modalCode !== ""}
                    permissions={permissions}
                    onClose={() => {
                        navigate(`/closing-request`)
                        setModalCode("")
                        setReDrawDatatable(prev => prev + 1)
                        window.history.pushState('', '', `/closing-request`)
                    }} /> :
                null
            }

        </Root>
    )
}

export default ClosingRequestList