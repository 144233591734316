import React, { useReducer, useEffect, useState, useRef, useContext } from 'react';
import {
    Grid,
    Switch,
    Typography,
    Collapse,
    MenuItem,
    InputAdornment,
    Button,
    FormControlLabel,
    IconButton,
    Paper,
    Checkbox,
    CardContent,
    Card,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    // TableRow,
    // TableCell,
    // Dialog
} from '@mui/material';
import ButtonRadioGroup from '../../_components/_formElements/ButtonRadioGroup';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import qs from 'qs';
import {
    addDays,
    subDays,
    addMonths,
    compareDesc,
    format
} from 'date-fns';
// import pdfPng from '../../Assets/Images/png/pdf.png';
// import docPng from '../../Assets/Images/png/pdf.png';
import CreateIcon from '@mui/icons-material/Create';

/**
 * Components
 */
import MuiDatePicker from '../../_components/MuiDatePicker';
import PaymentTypeAndTransactionAmountForm from './_components/Form/PaymentTypeAndTransactionForm';
import TerminForm, { ITermin } from './_components/TerminForm';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../_components/_form/AsyncAutoComplete';
import LoadingScreen from '../../_components/LoadingScreen';
import { IMTO } from './_components/MTOForm';
import ModalContainer from '../../_components/_modal/ModalContainer';
import AddAgentModal from './_components/Modal/AddAgentModal';

/**
 * Inputs
 */
import TextArea from '../../_components/_form/TextArea';
import TextFieldND2 from '../../_components/_formElements/_newDesign2/TextFieldND2';
import TextFieldND3 from '../../_components/_formElements/_newDesign2/TextFieldND3';
import VisuallyHiddenInput from '../../_components/_form/VisuallyHiddenInput';
import OptionSwitcherND2 from '../../_components/_formElements/_newDesign2/OptionSwitcherND2';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { convertIndonesiaPhoneNumber, currencyToNumber, dateFormat, generalErrorHandler, isMobile, numberToCurrency, prettyFloat } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';
import { Add, Check } from '@mui/icons-material';

/**
 * Data
 */
import nationalities from '../../_data/nationalities.json';
import { IAgentResponse } from '../_agent/components/AgentForm';
import ConfirmationModal, { useConfirm } from './_components/Modal/ConfirmationModal';

export interface IDefaultCalculation {
    default_commission_jendela_rent: number;
    default_commission_star_rent: number;
    finance_revenue_blibli: number;
    finance_cogs_blibli: number;
}

interface IState {
    [key: string]: string | number | Date | null | boolean | ITermin[] | IMTO[] | ITermin[] | File
    id: string;
    code: string;
    is_cobroke_other_agent: boolean;
    agent_as: 'client' | 'listing';
    enquiry_id: string;
    enquiry_label: string;
    enquiry_customer_name: string;
    enquiry_customer_phone: string;
    enquiry_customer_email: string;
    enquiry_customer_bank_type: string;
    enquiry_customer_bank_account_number: string;
    enquiry_customer_bank_behalf_name: string;
    listing_id: string;
    listing_type: number;
    listing_label: string;
    agent_id: string;
    agent_label: string;
    advisor_id: string;
    advisor_name: string;
    is_sell: 0 | 1,
    start_date: Date | null;
    end_date: Date | null;
    first_payment_date: Date | null;
    finished_payment_date: Date | null;
    duration: string;
    payment_type: string;
    termin_method: number;
    apartment_name: string;
    apartment_id: string;
    tower: string;
    bedroom: string;
    furnished: string;
    floor: string;
    unit_number: string;
    category: number;
    gmv_transaction: string;
    gmv_financing: string;
    basic_price: string;
    jendelapay_payment: string;
    revenue_type: 'nominal' | 'percentage';
    revenue_jendela: string;
    revenue_percentage: string;
    cobroke_fee_type: 'nominal' | 'percentage';
    cobroke_fee: string;
    cobroke_fee_percentage: string;
    holded_deposit_office: string;
    holded_deposit_owner: string;
    notes: string;
    is_termin: boolean;
    is_contract_custom_termin: boolean;
    is_digital_contract: boolean;
    is_english: boolean;
    // digital_contract_template: string;
    is_maintenance_included: boolean;
    is_include_maintenance: boolean;
    is_include_utility_billing: boolean;
    is_include_other_billing: boolean;
    is_doc_owner_custom: boolean,
    is_doc_customer_custom: boolean,
    owner_id: number
    owner_nationality: string;
    owner_name: string;
    owner_email: string;
    custom_owner_email: string
    custom_owner_deposit: string
    custom_owner_deposit_percentage: string
    owner_card_number: string;
    owner_address: string;
    customer_id: number
    customer_nationality: string;
    customer_name: string;
    customer_email: string;
    custom_customer_email: string
    custom_customer_deposit: string
    customer_card_number: string;
    customer_address: string;
    termins: ITermin[],
    payment_per_month_mto: IMTO[],
    contract_custom_termins: ITermin[],
    payment_to_financing_service: string;
    custom_owner_doc_file: File | null;
    custom_owner_doc_url: string;
    custom_customer_doc_file: File | null;
    custom_customer_doc_url: string;
    agent_cobroke_internal_id: string
    agent_cobroke_internal_name_label: string
    telesales_name: string
    telesales_id: null | string
    payment_file: null | File
}

export interface IErrorState {
    advisor_id: string;
    enquiry_id: string;
    apartment_id: string;
    payment_type: string;
    start_date: string;
    end_date: string;
    first_payment_date: string;
    finished_payment_date: string;
    duration: string;
    apartment_name: string;
    floor: string;
    tower: string;
    bedroom: string;
    furnished: string;
    unit_number: string;
    owner_nationality: string;
    owner_name: string;
    owner_email: string;
    custom_owner_email: string
    owner_card_number: string;
    owner_address: string;
    customer_nationality: string;
    customer_name: string;
    customer_email: string;
    custom_customer_email: string
    customer_card_number: string;
    customer_address: string;
    enquiry_customer_name?: string
    enquiry_customer_phone?: string
    enquiry_customer_email?: string
    termins: ITermin[]
    custom_owner_doc_file: string;
    custom_customer_doc_file: string;
    agent_cobroke_internal_id: string
}
export interface IAction {
    name: string,
    value: any,
    type: string
}

const API_URL = process.env.REACT_APP_API_URL;

export const useStyles = generateStyle(() => ({
    title: {
        fontSize: '22px',
        fontWeight: '600',
        borderBottom: '1px solid #0000004D'
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    section: {
        marginBottom: 40,
    },
    cobrokeSection: {
        background: '#fafafa',
        borderRadius: 12,
        padding: '24px 16px',
    },
    subtitle: {
        fontSize: '22px',
        fontWeight: '600',
        marginTop: 20,
    },
    digitalContractSubtitle: {
        fontSize: '18px'
    },
    infoText: {
        color: '#000000',
        fontSize: '14px',
        opacity: '40%'
    },
    infoTextBold: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 'bold',
        opacity: '40%'
    },
    switchContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    collapse: {
        // paddingLeft: '12px',
        // paddingRight: '12px',
        width: '100%',
        '& .MuiCollapse-wrapper': {
            paddingTop: '12px',
            paddingBottom: '12px'
        }
    },
    unitInfo: {
        backgroundColor: '#F7F7F7',
        marginLeft: '12px',
        marginRight: '12px'
    },
    extraInfo: {
        height: '100%',
        width: '300px',
        backgroundColor: '#fff',
        border: "1px solid #ddd",
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '14px 24px',
        alignSelf: 'self-start',
        transition: "all 200ms ease-in",
        '& > p': {
            fontSize: 14,
            margin: 0,
            textAlign: 'center',
            whiteSpace: 'nowrap'
        },
    },
    extraInfoFilled: {
        background: '#00A1DF',
        '& > p': {
            color: "#fff"
        }
    },
    depositContainer: {
        maxWidth: '461px',
        "& > div:nth-of-type(1)": {
            marginBottom: '12px'
        }
    },
    terminContainer: {
        // paddingTop: '12px',
        paddingBottom: '12px'
    },
    deleteTerminBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    typeContainer: {
        maxWidth: '100px'
    },
    submitContainer: {
        marginTop: '25px'
    },
    closingCode: {
        float: 'right',
        paddingTop: '10px'
    },
    marginBottom20px: {
        marginBottom: '20px'
    },
    detailsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}), "Closing_List"
);

const confirmationContent = <>
    <List>
        <ListItem>
            <ListItemIcon><Check /></ListItemIcon>
            <ListItemText>
                Data Enquiry Sudah Sesuai
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemIcon><Check /></ListItemIcon>
            <ListItemText>
                Pastikan Closing ini Closing Telesales/Bukan
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemIcon><Check /></ListItemIcon>
            <ListItemText>
                Pastikan Unit yang diinput sudah benar
            </ListItemText>
        </ListItem>
    </List>
</>

const ClosingForm = () => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const params: any = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const durationRef = useRef<HTMLInputElement>(null)
    const [confirmedAdd, setConfirmedAdd] = useState(false)
    const { confirm, modalProps } = useConfirm()

    const initialState: IState = {
        id: '',
        code: '',
        is_cobroke_other_agent: false,
        agent_as: 'client',
        enquiry_id: '',
        enquiry_label: '',
        enquiry_customer_name: '',
        enquiry_customer_phone: '',
        enquiry_customer_email: '',
        enquiry_customer_bank_type: '',
        enquiry_customer_bank_account_number: '',
        enquiry_customer_bank_behalf_name: '',
        listing_id: '',
        listing_type: 1,
        listing_label: '',
        agent_id: '',
        agent_label: '',
        advisor_id: '',
        advisor_name: '',
        is_sell: 0,
        start_date: new Date(),
        end_date: addDays(new Date(), 1),
        first_payment_date: null,
        finished_payment_date: null,
        duration: '',
        payment_type: 'cash',
        termin_method: 1,
        apartment_name: '',
        apartment_id: '',
        tower: '',
        bedroom: '',
        furnished: '',
        floor: '',
        unit_number: '',
        category: 0,
        gmv_transaction: "",
        gmv_financing: "",
        basic_price: "",
        jendelapay_payment: "",
        revenue_type: 'nominal',
        revenue_jendela: "",
        revenue_percentage: "",
        cobroke_fee_type: 'percentage',
        cobroke_fee: '',
        cobroke_fee_percentage: '',
        holded_deposit_office: "0",
        holded_deposit_owner: "0",
        notes: '',
        is_termin: false,
        is_contract_custom_termin: false,
        is_english: false,
        is_digital_contract: false,
        // digital_contract_template: 'default',
        is_maintenance_included: false,
        is_include_maintenance: false,
        is_include_utility_billing: false,
        is_include_other_billing: false,
        is_doc_owner_custom: false,
        is_doc_customer_custom: false,
        owner_id: 0,
        owner_nationality: 'Indonesian',
        owner_name: '',
        owner_email: '',
        custom_owner_email: '',
        custom_owner_deposit_percentage: '',
        custom_owner_deposit: '',
        owner_card_number: '',
        owner_address: '',
        customer_id: 0,
        customer_nationality: 'Indonesian',
        customer_name: '',
        customer_email: '',
        custom_customer_email: '',
        custom_customer_deposit: '',
        customer_card_number: '',
        customer_address: '',
        termins: [],
        payment_per_month_mto: [],
        contract_custom_termins: [],
        payment_to_financing_service: '',
        custom_owner_doc_file: null,
        custom_customer_doc_file: null,
        custom_owner_doc_url: '',
        custom_customer_doc_url: '',
        agent_cobroke_internal_id: '',
        agent_cobroke_internal_name_label: '',
        telesales_name: '',
        telesales_id: null,
        payment_file: null,
    };

    const initialDefaultCalculation: IDefaultCalculation = {
        default_commission_jendela_rent: 5,
        default_commission_star_rent: 5,
        finance_revenue_blibli: 5,
        finance_cogs_blibli: 5
    }

    const initialErrorState: IErrorState = {
        advisor_id: '',
        enquiry_id: '',
        apartment_id: '',
        enquiry_customer_name: '',
        enquiry_customer_phone: '',
        enquiry_customer_email: '',
        payment_type: '',
        start_date: '',
        end_date: '',
        first_payment_date: '',
        finished_payment_date: '',
        duration: '',
        apartment_name: '',
        floor: '',
        tower: '',
        bedroom: '',
        furnished: '',
        unit_number: '',
        owner_nationality: '',
        owner_name: '',
        owner_email: '',
        custom_owner_email: '',
        owner_card_number: '',
        owner_address: '',
        customer_nationality: '',
        customer_name: '',
        customer_email: '',
        custom_customer_email: '',
        customer_card_number: '',
        customer_address: '',
        termins: [],
        custom_owner_doc_file: '',
        custom_customer_doc_file: '',
        agent_cobroke_internal_id: '',
    }

    const currencyAttribute = [
        'gmv_transaction',
        'gmv_financing',
        'revenue_jendela',
        'holded_deposit_office',
        'holded_deposit_owner',
        'cobroke_fee',
        'basic_price',
        'payment_to_financing_service',
        'custom_owner_deposit',
        'custom_customer_deposit',
    ];

    const percentageAttribute = [
        'revenue_percentage',
        'cobroke_fee_percentage'
    ];

    // const digitalContractTemplates = [
    //     {
    //         key: 'default',
    //         value: 'Default'
    //     },
    //     {
    //         key: 'addendum',
    //         value: 'Addendum'
    //     }
    // ];

    /**
     * Input Reducer
     */
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {

            if (currencyAttribute.includes(action.name)) {
                return {
                    ...state,
                    [action.name]: action.value
                        .replace(/,/g, '')
                }
            } else if (percentageAttribute.includes(action.name)) {
                return {
                    ...state,
                    [action.name]: action.value.replace(/,/g, '')
                }
            } else if (action.name === "custom_owner_deposit_percentage") {
                let number = action.value.replace(/,/g, '');

                if (number > 100) {
                    number = 100;
                }

                return {
                    ...state,
                    [action.name]: number
                }
            } else if (action.name === "duration") {
                action.value = action.value.replace(/\D+/g, '')

                if (action.name === 'duration') {
                    let start_date = state.start_date

                    if (action.value !== "" && action.value !== "0") {
                        if (start_date) {
                            state.end_date = subDays(addMonths(start_date, Number(action.value)), 1);
                        }
                    } else {
                        state.end_date = addDays(start_date ? start_date : new Date(), 1);
                    }
                }

                if (state.termins.length > 0) {
                    if (window.confirm('Mengganti durasi akan menghapus termin yang sudah ada. Apakah anda yakin?')) {
                        state.termins = [];
                    } else {
                        return { ...state };
                    }
                }
            } else if (action.name === 'start_date') {
                state.duration = "";
                if (state.start_date && state.end_date) {
                    if (compareDesc(state.end_date, state.start_date) === 1) {
                        state.end_date = addDays(action.value, 1);
                    }
                }
            } else if (action.name === 'end_date') {
                state.duration = "";
            } else if (action.name === 'enquiry_customer_phone') {
                return {
                    ...state,
                    [action.name]: convertIndonesiaPhoneNumber(action.value)
                }
            }

            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    // Input State
    const errorReducer = (state: IErrorState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                ...initialErrorState
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [defaultCalculationState, setDefaultCalculationState] = useState<IDefaultCalculation>(initialDefaultCalculation);
    const [closingStatus, setClosingStatus] = useState(0);
    const [inputState, setInputState] = useReducer(inputReducer, initialState);
    const [errorState, setErrorState] = useReducer(errorReducer, initialErrorState);

    const [totalTransaction, settotalTransaction] = useState("");
    const [totalDeposit, setTotalDeposit] = useState<number>(0);
    const [totalPaymentWithFinancing, setTotalPaymentWithFinancing] = useState<string>("0");

    const firstFetchFinished = useRef(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isExtend, setIsExtend] = useState(false);
    const [isUnitSame, setIsUnitSame] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isTerminClosing, setIsTerminClosing] = useState(false);
    const [isUseDuration, setIsUseDuration] = useState(true);

    const [extendFormType, setExtendFormType] = useState(0);
    const [enquiryNeeded, setEnquiryNeeded] = useState(!isExtend);
    const [advisorNeeded, setAdvisorNeeded] = useState(false);

    const [fromAdvisorEnquiry, setFromAdvisorEnquiry] = useState(false);
    const [isForceExtend, setIsForceExtend] = useState(false)

    const [ownerDetailsForm, setOwnerDetailsForm] = useState({
        owner_name: '',
        owner_email: '',
        owner_card_number: '',
        owner_address: '',
        owner_nationality: '',
    })

    const [customerDetailsForm, setCustomerDetailsForm] = useState({
        customer_name: '',
        customer_email: '',
        customer_card_number: '',
        customer_address: '',
        customer_nationality: '',
    })

    const [isOwnerDetailsOpen, setIsOwnerDetailsOpen] = useState(false)
    const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState(false)

    const [addAgentModal, setAddAgentModal] = useState(false)

    useEffect(() => {
        const query_param = location.search.slice(1)
        if (query_param !== "" && typeof qs.parse(query_param).is_force !== "undefined" && qs.parse(query_param).is_force) {
            setIsForceExtend(true)
        }

        let timeout: any;

        setIsLoading(true)

        let param: any = {};
        if (params.id) {
            param.code = params.id;
        }

        const is_extend = location.pathname.includes('manage-extend');
        if (is_extend) {
            param.is_extend = true;
            setIsUseDuration(false);
        } else if (params.id) {
            param.is_edit = true;
            setIsUseDuration(false);
            setIsEdit(true);
        }

        const queryParams = new URLSearchParams(location.search);

        const enquiryId = queryParams.get('enquiryId');

        if (enquiryId) {
            param.enquiry_id = enquiryId;

            setFromAdvisorEnquiry(true);
        }

        DefaultAxios.get(`${API_URL}/new-closing/initial-form`, { params: param })
            .then(res => {
                let newState: IState = { ...inputState };

                /**
                 * If it's edit form + Not Manage Extend
                 */
                if (params.id && is_extend === false) {
                    const closing = res.data.closing;

                    setClosingStatus(closing.status);

                    if (closing.digital_contract_data) {
                        newState = { ...newState, ...closing.digital_contract_data, is_digital_contract: true };

                        if (typeof closing.digital_contract_data.contract_custom_termins === "object" && closing.digital_contract_data.contract_custom_termins.length > 0) {
                            let termin_arr: ITermin[] = [];
                            let termin_collection = closing.digital_contract_data.contract_custom_termins;
                            newState.is_contract_custom_termin = true;

                            for (let index = 0; index < termin_collection.length; index++) {
                                const termin: ITermin = termin_collection[index];
                                termin_arr.push({
                                    start_date: new Date(termin.start_date),
                                    end_date: new Date(termin.end_date),
                                    amount: termin.amount.toString(),
                                    basic_price: termin.basic_price.toString(),
                                })
                            }
                            newState.contract_custom_termins = termin_arr;
                        }

                        newState.is_doc_owner_custom = +closing.digital_contract_data.is_doc_owner_custom === 1 ? true : false;
                        newState.is_doc_customer_custom = +closing.digital_contract_data.is_doc_customer_custom === 1 ? true : false;
                    }

                    newState.id = closing.id;
                    newState.code = closing.code;
                    newState.listing_id = closing.unit_id;
                    newState.listing_type = 1; //hardcode
                    newState.listing_label = closing.listing_label;
                    newState.agent_id = closing.agent_id;
                    newState.agent_label = closing.agent_label;
                    newState.enquiry_id = closing.enquiry_id;
                    newState.enquiry_label = closing.enquiry_label;
                    newState.is_sell = closing.is_sell;
                    newState.start_date = closing.start_date === null ? new Date() : new Date(closing.start_date);
                    newState.end_date = closing.end_date === null ? new Date() : new Date(closing.end_date);
                    newState.first_payment_date = closing.first_payment_date === null ? null : new Date(closing.first_payment_date);
                    newState.finished_payment_date = closing.finished_payment_date === null ? null : new Date(closing.finished_payment_date);
                    newState.duration = closing.duration;
                    newState.agent_as = closing.cobroke_type === 2 ? 'listing' : 'client';
                    newState.advisor_id = closing.advisor_id;
                    newState.advisor_name = closing.advisor_name;
                    newState.is_cobroke_other_agent = Number(closing.cobroke_type) !== 0 ? true : false;
                    newState.payment_type = closing.payment_type;
                    if (closing.cash_amount) {
                        newState.gmv_transaction = closing.cash_amount.toString();
                    } else {
                        newState.gmv_transaction = closing.gmv_transaction ? closing.gmv_transaction.toString() : '';
                    }
                    newState.gmv_financing = closing.gmv_financing === null ? 0 : closing.gmv_financing.toString();
                    newState.payment_to_financing_service = closing.payment_to_financing_service !== null ? closing.payment_to_financing_service.toString() : "";
                    newState.basic_price = closing.basic_price !== null ? closing.basic_price.toString() : "";
                    newState.revenue_jendela = closing.revenue_jendela ? closing.revenue_jendela.toString() : "";
                    newState.cobroke_fee_type = "nominal";
                    newState.cobroke_fee = closing.cobroke_fee !== null ? closing.cobroke_fee.toString() : "";
                    newState.holded_deposit_owner = closing.holded_deposit_owner !== null ? closing.holded_deposit_owner.toString() : "0";
                    newState.holded_deposit_office = closing.holded_deposit_office !== null ? closing.holded_deposit_office.toString() : "0";
                    newState.notes = closing.notes;
                    newState.is_include_maintenance = closing.is_include_maintenance ? true : false;
                    newState.is_include_utility_billing = closing.is_include_utility_billing ? true : false;
                    newState.is_include_other_billing = closing.is_include_other_billing ? true : false;

                    // Cobroke Internal
                    newState.agent_cobroke_internal_id = closing.agent_cobroke_internal_id;
                    newState.agent_cobroke_internal_name_label = closing.agent_cobroke_internal_name_label;

                    /**
                     * Setting Up Termins
                     */
                    if (closing.group_termin_id) {
                        let termin_arr: ITermin[] = [];
                        newState.is_termin = true;
                        // let termin_collection = closing.termins;
                        // for (let index = 0; index < termin_collection.length; index++) {
                        //     const termin: ITermin = termin_collection[index];
                        //     termin_arr.push({
                        //         start_date: new Date(termin.start_date),
                        //         end_date: new Date(termin.end_date),
                        //         amount: termin.amount.toString(),
                        //         basic_price: termin.basic_price.toString(),
                        //     })
                        // }
                        newState.termins = termin_arr;
                    }

                    /**
                     * Setting Up Payment Per Month MTO
                     */
                    let mto_arr: IMTO[] = [];
                    if (closing.payment_per_month_mto !== null && closing.payment_per_month_mto.length) {

                        let mto_collection = closing.payment_per_month_mto;
                        for (let index = 0; index < mto_collection.length; index++) {
                            const mto: IMTO = mto_collection[index];
                            mto_arr.push({
                                date: new Date(mto.date),
                                transaction_amount: mto.transaction_amount.toString(),
                                basic_price: mto.basic_price.toString(),
                            })
                        }
                    }
                    newState.payment_per_month_mto = mto_arr;

                    setDefaultCalculationState({
                        default_commission_jendela_rent: res.data.closing_calc.default_commission_jendela_rent,
                        default_commission_star_rent: res.data.closing_calc.default_commission_star_rent,
                        finance_revenue_blibli: res.data.closing_calc.finance_revenue_blibli,
                        finance_cogs_blibli: res.data.closing_calc.finance_cogs_blibli
                    })

                    /**
                     * Check Notes
                     */
                    newState.notes = newState.notes ? newState.notes : "";

                    setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                    setTimeout(() => {
                        firstFetchFinished.current = true;
                    }, 150)
                    setIsLoading(false);
                    setIsTerminClosing(closing.is_termin_closing);
                    setIsExtend(location.pathname.includes('manage-extend'));
                } else {
                    if (typeof res.data.closing !== 'undefined') {

                        const closing = res.data.closing;

                        newState = { ...newState, ...closing };
                        newState.start_date = closing.start_date === null ? new Date() : new Date(closing.start_date);
                        newState.end_date = closing.end_date === null ? new Date() : new Date(closing.end_date);

                        if (closing.digital_contract_data && is_extend === false) {
                            newState = { ...newState, ...closing.digital_contract_data, is_digital_contract: true };
                        }

                        /**
                         * Setting Cobroke Type
                         */
                        setExtendFormType(closing.cobroke_type);
                        if (closing.cobroke_type === 2) {
                            setIsUnitSame(true);
                            newState.is_cobroke_other_agent = true;
                            newState.agent_as = "listing";
                        } else if (closing.cobroke_type === 1) {
                            newState.agent_as = "client";
                        } else if (closing.cobroke_type === 0) {
                            newState.agent_as = "client";
                        }

                        /**
                         * Setting Default Data
                         */
                        if (is_extend) {
                            newState.basic_price = (Number(closing.gmv_transaction) - Number(closing.revenue_jendela)).toString();
                            newState.holded_deposit_office = closing.holded_deposit_office ? closing.holded_deposit_office.toString() : "";
                            newState.holded_deposit_owner = closing.holded_deposit_owner ? closing.holded_deposit_owner.toString() : "";
                        }

                        /**
                         * Setting Up Payment Per Month MTO
                         */
                        let mto_arr: IMTO[] = [];
                        if (closing.payment_per_month_mto !== null && closing.payment_per_month_mto.length) {


                            let mto_collection = closing.payment_per_month_mto;
                            for (let index = 0; index < mto_collection.length; index++) {
                                const mto: IMTO = mto_collection[index];
                                mto_arr.push({
                                    date: new Date(mto.date),
                                    transaction_amount: mto.transaction_amount.toString(),
                                    basic_price: mto.basic_price.toString(),
                                })
                            }
                        }
                        newState.payment_per_month_mto = mto_arr;
                    }

                    if (typeof res.data.enquiry !== 'undefined') {
                        newState.enquiry_id = res.data.enquiry.id;
                        newState.enquiry_label = res.data.enquiry.label;
                        newState.enquiry_customer_name = res.data.enquiry.customer_name;
                        newState.enquiry_customer_phone = convertIndonesiaPhoneNumber(res.data.enquiry.customer_phone);
                        newState.enquiry_customer_email = res.data.enquiry.customer_email ? res.data.enquiry.customer_email : "";
                    }

                    newState.revenue_percentage = res.data.closing_calc.default_commission_jendela_rent;
                    newState.cobroke_fee_percentage = res.data.closing_calc.default_cobroke_fee;

                    setDefaultCalculationState({
                        default_commission_jendela_rent: res.data.closing_calc.default_commission_jendela_rent,
                        default_commission_star_rent: res.data.closing_calc.default_commission_star_rent,
                        finance_revenue_blibli: res.data.closing_calc.finance_revenue_blibli,
                        finance_cogs_blibli: res.data.closing_calc.finance_cogs_blibli
                    })
                    setIsTerminClosing(typeof res.data.disable_termin !== 'undefined' ? res.data.disable_termin : false);
                    setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
                    setTimeout(() => {
                        firstFetchFinished.current = true;
                    }, 150)
                    setIsLoading(false);
                    setIsExtend(location.pathname.includes('manage-extend'));
                }
            })

        return () => {
            clearTimeout(timeout);
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!firstFetchFinished) {
            const oldState = { ...inputState };
            oldState.listing_id = '';
            oldState.listing_label = '';
            oldState.category = 0;

            setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [inputState.is_cobroke_other_agent]);

    /**
     * GMV Transaction to show at Form
     */
    useEffect(() => {
        let gmv_transct = "";

        if (['mix-cash-blibli', 'mix-cash-modalku'].includes(inputState.payment_type)) {
            if (inputState.gmv_transaction !== "" && inputState.payment_to_financing_service !== "") {
                gmv_transct = numberToCurrency((Number(inputState.gmv_transaction) + Number(inputState.payment_to_financing_service)));
            }
        } else {
            if (inputState.gmv_transaction !== "") {
                gmv_transct = numberToCurrency(Number(inputState.gmv_transaction));
            }
        }

        settotalTransaction(gmv_transct);
    }, [inputState.gmv_transaction, inputState.payment_to_financing_service, inputState.payment_type])

    /**
     * On Change GMV Transaction, Basic Price, and Commission related data
     */
    useEffect(() => {
        let revenue_percentage = Number(inputState.revenue_percentage);
        let gmv_transaction = 0;

        /**
         * Modalku still have no fixed logic to calculate
         */
        if (['installment-modalku', 'mix-cash-modalku'].includes(inputState.payment_type)) {
            setTotalPaymentWithFinancing('Harap hubungi tim Finance atau Modalku');
        }

        if (['mix-cash-blibli', 'mix-cash-modalku'].includes(inputState.payment_type) && (inputState.gmv_transaction !== "0" && inputState.gmv_transaction !== "" && inputState.payment_to_financing_service !== "0" && inputState.payment_to_financing_service !== "")) {
            if (inputState.payment_type === 'mix-cash-blibli') {
                const additional_payment = (Number(inputState.payment_to_financing_service) * defaultCalculationState.finance_revenue_blibli / 100);
                setTotalPaymentWithFinancing(numberToCurrency(Number(inputState.payment_to_financing_service) + additional_payment));
            }
            gmv_transaction = Number(inputState.gmv_transaction) + Number(inputState.payment_to_financing_service);
        } else if (inputState.payment_type === 'jendelapay') {
            gmv_transaction = Number(inputState.gmv_transaction);
            setTotalPaymentWithFinancing(numberToCurrency(gmv_transaction));
        } else if (inputState.gmv_transaction !== "0" && inputState.gmv_transaction !== "") {
            if (inputState.payment_type === 'installment-blibli') {
                const additional_payment = (Number(inputState.gmv_transaction) * defaultCalculationState.finance_revenue_blibli / 100);
                setTotalPaymentWithFinancing(numberToCurrency(Number(inputState.gmv_transaction) + additional_payment));
            }
            gmv_transaction = Number(inputState.gmv_transaction);
        } else {
            return;
        }

        /**
         * Calcuate Basic Price
         */
        let basic_price = Number(inputState.basic_price)

        if (gmv_transaction === 0) {
            setInputState({ name: 'basic_price', value: "", type: 'SET_ITEM' });
        } else if (gmv_transaction < basic_price) {
            setInputState({ name: 'basic_price', value: numberToCurrency(gmv_transaction), type: 'SET_ITEM' });
        }

        /**
         * Calculate Commission
         */
        if (inputState.payment_type === 'jendelapay') {
            let calculated_commission = 0;
            if (gmv_transaction > 0 && basic_price > 0) {
                const revenue_financing = 0.09 * gmv_transaction;
                calculated_commission = gmv_transaction - basic_price - revenue_financing;
                const jendelapay_payment = Math.round((gmv_transaction - basic_price) - calculated_commission);

                if (calculated_commission > 0) {
                    let percentage = (calculated_commission / gmv_transaction) * 100;
                    setInputState({ name: 'jendelapay_payment', value: jendelapay_payment.toString(), type: 'SET_ITEM' });
                    setInputState({ name: 'revenue_jendela', value: calculated_commission.toString(), type: 'SET_ITEM' });
                    setInputState({ name: 'revenue_percentage', value: percentage.toString(), type: 'SET_ITEM' });
                } else {
                    setInputState({ name: 'jendelapay_payment', value: "", type: 'SET_ITEM' });
                    setInputState({ name: 'revenue_jendela', value: "", type: 'SET_ITEM' });
                    setInputState({ name: 'revenue_percentage', value: "0", type: 'SET_ITEM' });
                }
            }
        } else {
            let calculated_commission = gmv_transaction - basic_price;
            if (inputState.revenue_type === "nominal") {
                let percentage = (calculated_commission / gmv_transaction) * 100;

                if (percentage > 100) {
                    percentage = 100;
                    setInputState({ name: 'revenue_jendela', value: gmv_transaction.toString(), type: 'SET_ITEM' });
                } else {
                    setInputState({ name: 'revenue_jendela', value: calculated_commission.toString(), type: 'SET_ITEM' });
                }

                setInputState({ name: 'revenue_percentage', value: percentage.toString(), type: 'SET_ITEM' });
            } else {
                let newCommission = revenue_percentage * gmv_transaction / 100;

                if (revenue_percentage > 100) {
                    setInputState({ name: 'revenue_jendela', value: gmv_transaction.toString(), type: 'SET_ITEM' });
                    setInputState({ name: 'revenue_percentage', value: "100", type: 'SET_ITEM' });
                } else {
                    setInputState({ name: 'revenue_jendela', value: newCommission.toString(), type: 'SET_ITEM' });
                }
            }
        }
    }, [inputState.gmv_transaction, inputState.basic_price, inputState.revenue_jendela, inputState.revenue_percentage, inputState.payment_to_financing_service, inputState.payment_type, inputState.revenue_type, defaultCalculationState.finance_revenue_blibli]);

    /**
     * Cobroke Fee
     */
    useEffect(() => {
        if (inputState.revenue_jendela !== "0" && inputState.revenue_jendela !== "" && inputState.is_cobroke_other_agent) {
            let commission = Number(inputState.revenue_jendela);
            let cobroke_fee = Number(inputState.cobroke_fee);
            let cobroke_fee_percentage = Number(inputState.cobroke_fee_percentage);

            if (inputState.cobroke_fee_type === "nominal") {
                let percentage = (cobroke_fee / commission) * 100;

                if (percentage > 100) {
                    percentage = 100;
                    setInputState({ name: 'cobroke_fee', value: commission.toString(), type: 'SET_ITEM' });
                }

                setInputState({ name: 'cobroke_fee_percentage', value: percentage.toString(), type: 'SET_ITEM' });
            } else {
                let newCommission = cobroke_fee_percentage * commission / 100;

                if (cobroke_fee_percentage > 100) {
                    setInputState({ name: 'cobroke_fee', value: commission.toString(), type: 'SET_ITEM' });
                    setInputState({ name: 'cobroke_fee_percentage', value: "100", type: 'SET_ITEM' });
                } else {
                    setInputState({ name: 'cobroke_fee', value: newCommission.toString(), type: 'SET_ITEM' });
                }

                setInputState({ name: 'cobroke_fee', value: newCommission.toString(), type: 'SET_ITEM' });
            }
        }
    }, [inputState.revenue_jendela, inputState.cobroke_fee, inputState.cobroke_fee_percentage, inputState.cobroke_fee_type, inputState.is_cobroke_other_agent])

    /**
     * Calculate Total Deposit
     */
    useEffect(() => {
        setTotalDeposit(Number(inputState.holded_deposit_office) + Number(inputState.holded_deposit_owner));
    }, [inputState.holded_deposit_office, inputState.holded_deposit_owner])

    /**
     * Refresh Termin if Termin toggle changed
     */
    useEffect(() => {
        if (inputState.termins.length > 0 && firstFetchFinished.current) {
            setInputState({ name: 'termins', value: [], type: 'SET_ITEM' });
        }
        // eslint-disable-next-line
    }, [inputState.is_termin])


    /**
     * Change Start Date or Duration
     */
    useEffect(() => {
        if (inputState.termins.length > 0 && firstFetchFinished.current) {
            setInputState({ name: 'termins', value: [], type: 'SET_ITEM' });
        }

        if (inputState.start_date === null || inputState.duration === "") {
            setInputState({ name: 'is_termin', value: false, type: 'SET_ITEM' });
        }
        // eslint-disable-next-line
    }, [inputState.start_date, inputState.duration])

    useEffect(() => {
        if (+inputState.is_sell === 1 && firstFetchFinished.current) {
            setInputState({ name: 'payment_type', value: 'cash', type: 'SET_ITEM' });
        }
        // eslint-disable-next-line
    }, [inputState.is_sell]);

    useEffect(() => {
        if (firstFetchFinished.current) {
            setInputState({ name: 'cobroke_fee', value: '', type: 'SET_ITEM' });
        }
        // eslint-disable-next-line
    }, [inputState.cobroke_fee_type]);

    useEffect(() => {
        if (!inputState.is_digital_contract) {
            const defaultDigitalContractData = {
                is_maintenance_included: false,
                // digital_contract_template: 'default',
                owner_nationality: 'Indonesian',
                owner_name: '',
                owner_email: '',
                owner_card_number: '',
                owner_address: '',
                customer_nationality: 'Indonesian',
                customer_name: '',
                customer_email: '',
                customer_card_number: '',
                customer_address: '',
            };

            const newState = { ...inputState, ...defaultDigitalContractData };

            setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
        }
        // eslint-disable-next-line
    }, [inputState.is_digital_contract]);

    useEffect(() => {
        /**
         * Enquiry Needed only if: Non Extend + Either Non-cobroke OR Cobroke as Client
         */
        const is_cobroke = inputState.is_cobroke_other_agent;
        const agent_as = inputState.agent_as;
        setEnquiryNeeded(!isExtend && (!is_cobroke || (is_cobroke && agent_as === 'client')))
    }, [isExtend, inputState.is_cobroke_other_agent, inputState.agent_as])

    useEffect(() => {
        const is_cobroke = inputState.is_cobroke_other_agent;
        const agent_as = inputState.agent_as

        /**
         * Advisor Needed if:
         *  - If it's extend & moving unit & either noncobroke
         *  - If it's not extend and ADMIN:
         *     - If it's non-cobroke
         *     - If it's cobroke listing
         */
        if (isExtend && !isUnitSame) {
            setAdvisorNeeded(true);
        } else if (permissions['closing.admin'] && (is_cobroke && agent_as === 'listing')) {
            setAdvisorNeeded(true);
        } else {
            setAdvisorNeeded(false);
        }
    }, [isExtend, isUnitSame, inputState.is_cobroke_other_agent, permissions, inputState.agent_as])

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        let value: any = target.value;

        if (target.name === "cobroke_fee" || target.name === "cobroke_fee_percentage") {
            // hanya bisa di isi angka
            value = value.replace(/[^0-9]/g, '');
            
            if (target.name === "cobroke_fee_percentage") {
                const numericValue = parseInt(value);
                value = isNaN(numericValue) ? '' : Math.min(numericValue, 100).toString();
            }
        }

        if (target.type === 'checkbox') {
            value = target.checked;
        }

        setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
    }

    const onChange = (name: string, value: any) => {
        setInputState({ name, value: value, type: 'SET_ITEM' });
    }

    const handleChangedModal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        if (name in ownerDetailsForm) {
            setOwnerDetailsForm(prev => ({
                ...prev,
                [name]: value,
            }))
        } else {
            setCustomerDetailsForm(prev => ({
                ...prev,
                [name]: value,
            }))
        }
    }

    // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const target = e.target;
    //     let name = target.name;
    //     let value: any = target.value;

    //     if (target.files && target.files[0]) {
    //         value = target.files[0];
    //     } else {
    //         value = null;
    //     }

    //     setInputState({ name, value, type: 'SET_ITEM' });
    // }

    const handleChangeClick = (name: string, value: string) => {
        setInputState({ name, value, type: 'SET_ITEM' });
    };

    const handleUnitChanged = (name: string, value: boolean) => {
        if (value === true) {
            setInputState({ name: "is_cobroke_other_agent", value: false, type: 'SET_ITEM' });
        }

        setIsUnitSame(value);
    };

    const handleCobrokeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_cobroke_other_agent = event.target.checked;
        oldState.agent_id = '';
        oldState.agent_label = '';
        oldState.listing_id = '';

        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleDocOwnerSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_doc_owner_custom = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleDocCustomerSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_doc_customer_custom = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleDigitalContractSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_digital_contract = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleCustomDigitalContractSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_contract_custom_termin = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    const handleTerminSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const oldState = { ...inputState };
        oldState.is_termin = event.target.checked;
        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    };

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        if (name === "start_date" && inputState.termins.length > 0) {
            if (!window.confirm('Mengganti tanggal akan me-reset termin. Apakah anda yakin?')) {
                return true;
            }
        }

        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const handleAutocomplete = (name: string, value: IAutoCompleteOption) => {
        const oldState: IState = { ...inputState };

        switch (name) {
            case 'listing_id':
                oldState.listing_id = value.id.toString();

                oldState.category = +value.category;

                if (value.category === 1) {
                    oldState.revenue_percentage = defaultCalculationState.default_commission_star_rent.toString();
                } else {
                    oldState.revenue_percentage = defaultCalculationState.default_commission_jendela_rent.toString();
                }

                // oldState.revenue_type = 'percentage';
                break
            case 'enquiry_id':
                oldState.enquiry_id = value.id.toString();

                oldState.enquiry_customer_name = value.customer_name;
                oldState.enquiry_customer_phone = convertIndonesiaPhoneNumber(value.customer_phone);
                oldState.enquiry_customer_email = value.customer_email ? value.customer_email : "";
                oldState.telesales_name = value.telesales_name ? value.telesales_name : '';
                oldState.telesales_id = value.telesales_id ? value.telesales_id : null;
                break
            default:
                oldState[name] = value.id.toString();
                break
        }

        setInputState({ name: '', value: oldState, type: 'REPLACE_STATE' });
    }

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const checkTerminValidation = (error?: IErrorState, valid?: boolean) => {
        let isValid = valid !== undefined ? valid : true
        const newError = error || { ...initialErrorState };

        if (inputState.is_termin && inputState.termins.length > 0) {
            inputState.termins.forEach((termin, index) => {
                if (!termin.amount) {
                    newError.termins[index] = {
                        ...newError.termins[index],
                        amount: 'Termin Amount wajib diisi'
                    }
                    isValid = false;
                }

                // if (!termin.duration) {
                //     newError.termins[index] = {
                //         ...newError.termins[index],
                //         duration: 'Termin Duration wajib diisi'
                //     }
                //     isValid = false;
                // }
            })
        }

        if (!error) {
            setErrorState({ name: 'termins', value: newError.termins, type: 'SET_ITEM' });
        }

        return { terminValid: isValid }
    }

    const checkValidation = () => {
        let isValid = true;
        const newError = { ...initialErrorState };

        /**
         * Special for Extend Termin
         */
        if (isTerminClosing === false) {
            // if (!inputState.enquiry_id && enquiryNeeded) {
            //     newError.enquiry_id = 'Enquiry wajib diisi';
            //     isValid = false;
            // }

            if (!inputState.payment_type) {
                newError.payment_type = 'Payment type wajib diisi';
                isValid = false;
            }

            if (inputState.is_sell === 0) {
                if (!inputState.start_date) {
                    newError.start_date = 'Start Date wajib diisi apabila peruntukan sewa';
                    isValid = false;
                }

                if (!inputState.end_date) {
                    newError.end_date = 'End Date wajib diisi apabila peruntukan sewa';
                    isValid = false;
                }
            }

            if (!inputState.advisor_id && advisorNeeded) {
                newError.advisor_id = 'Advisor wajib diisi';
                isValid = false;
            }

            const { terminValid } = checkTerminValidation(newError, isValid)

            isValid = terminValid
        }

        setErrorState({ name: '', value: newError, type: 'REPLACE_STATE' });

        return isValid;
    }

    const handleSubmit = async () => {
        if (!params.id && !confirmedAdd) {
            const result = await confirm('PASTIKAN DATA CLOSING YANG DIINPUT SUDAH SESUAI', confirmationContent)

            if (result) {
                setConfirmedAdd(true)
                return
            }
        }

        if (!checkValidation()) {
            return;
        }

        const data: any = { ...inputState };

        for (let key in data) {
            if (currencyAttribute.includes(key)) {
                data[key] = Number(data[key]) > 0 ? Number(data[key]) : null;
            }
        }

        /**
         * Termin
         */
        if (data.is_termin === true) {
            data.termins.map((value: string, key: number) => {
                data.termins[key].amount = data.termins[key].amount.replace(/,/g, '');
                data.termins[key].basic_price = data.termins[key].basic_price.replace(/,/g, '');
                return true;
            })
        }

        /**
         * Custom Termin
         */
        if (data.is_contract_custom_termin === true) {
            data.contract_custom_termins.map((value: string, key: number) => {
                data.contract_custom_termins[key].amount = data.contract_custom_termins[key].amount.replace(/,/g, '');
                data.contract_custom_termins[key].basic_price = data.contract_custom_termins[key].basic_price.replace(/,/g, '');
                return true;
            })
        }

        /**
         * Payment per month mto
         */
        if (data.payment_type === 'monthly-owner' && data.payment_per_month_mto.length) {
            data.payment_per_month_mto = data.payment_per_month_mto.map((mto: any) => {
                mto.date = dateFormat(mto.date, 'YYYY-MM-DD');
                return mto;
            });
        }

        let url = '';

        if (isTerminClosing && !isEdit) {
            url = `${API_URL}/new-closing/extend-termin`;
        } else if (isExtend) {
            url = `${API_URL}/new-closing/manage-extend`;
            data['is_unit_same'] = isUnitSame;

            if (isForceExtend) {
                data['is_force_extend'] = 1;
            }
        } else if (params.id) {
            url = `${API_URL}/new-closing/${params.id}`;
            data['_method'] = 'PATCH';
        } else {
            url = `${API_URL}/new-closing`;
        }

        const fd = new FormData();

        // TODO : Check Form Data Conversion
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null) {
                if (['custom_owner_doc_file', 'custom_customer_doc_file', 'payment_file'].includes(key)) {
                    fd.append(key, value as File);
                } else if (typeof value == "boolean") {
                    var newValue = value === true ? '1' : '0'
                    fd.append(key, newValue as string);
                } else if (value instanceof Date) {
                    fd.append(key, format(new Date(value), 'yyyy-MM-dd'));
                } else if (value instanceof Array) {
                    value.forEach((data, index) => {
                        if (typeof data === 'object') {
                            for (const property in data) {
                                let value = data[property]
                                if (value instanceof Date) {
                                    value = format(new Date(value), 'yyyy-MM-dd')
                                }
                                fd.append(`${key}[${index}][${property}]`, value ? String(value) : "")
                            }
                        } else {
                            fd.append(`${key}[${index}]`, data ? String(data) : "")
                        }
                    })
                } else {
                    fd.append(key, value !== null ? String(value) : "");
                }
            }
        })

        setIsLoading(true)
        DefaultAxios.post(url, fd)
            // DefaultAxios.post(url, data)
            .then(res => {
                Swal.fire({
                    title: "Submit berhasil",
                    icon: 'success',
                    timer: 1000
                })
                    .then(res => {
                        navigate('/closing');
                    })
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const getManageExtendLabel = () => {
        switch (extendFormType) {
            case 0:
                return "Non-Cobroke";
            case 1:
                return "Cobroke as Client";
            case 2:
                return "Cobroke as Listing";
            case 3:
                return "Cobroke Wings";
            case 4:
                return "Cobroke Pintu";
        }
    }

    const fetchDocusignInformation = () => {
        if (!inputState.id) {
            Swal.fire({
                title: "Tombol ini hanya berfungi saat Edit",
                text: "Harap submit Closing lalu Edit ulang Closing yang disubmit untuk menggunakan fitur ini",
                icon: 'warning'
            });
            return;
        }

        if (!inputState.listing_id) {
            Swal.fire({
                title: "Silahkan isi listing terlebih dahulu",
                icon: 'warning'
            });
            return;
        }

        setIsLoading(true)
        DefaultAxios
            .get(`${API_URL}/new-closing/docusign-information`, { params: { closing_id: inputState.id, listing_id: inputState.listing_id } })
            .then(res => {
                const newState: any = { ...inputState };

                for (let [key, value] of Object.entries(res.data)) {
                    if (value !== '') {
                        newState[key] = value;
                    }
                }

                setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    // const [modalImage, setModalImage] = useState(false);
    // const [modalImageUrl, setModalImageUrl] = useState('');
    // const handleCloseImg = () => setModalImage(false);
    // const handleOpenImg = (url: string) => {
    //     setModalImageUrl(url)
    //     setModalImage(true)
    // };

    // const handlePreviewFile = (src: string, isImage: boolean) => {
    //     if (isImage) {
    //         handleOpenImg(src);
    //     } else {
    //         window.open(src);
    //     }
    // }

    // const renderFiles = (url: string, name: string) => {
    //     let src = '';
    //     let isImage = false;
    //     let filepath = url;

    //     let explode = filepath.split('?');
    //     const match = /(\.\w+$)/g.exec(explode[0]);

    //     if (match) {
    //         if (match[1] === '.pdf' || match[1] === '.PDF') {
    //             src = pdfPng;
    //         } else if (match[1] === '.doc' || match[1] === '.docx') {
    //             src = docPng;
    //         } else {
    //             src = filepath;
    //             isImage = true;
    //         }
    //     }

    //     return (
    //         <Grid item xs={12}>
    //             <TableRow>
    //                 <TableCell>
    //                     <img onClick={() => handlePreviewFile(filepath, isImage)} height="75" width="75" style={{ marginRight: 8, cursor: "pointer" }} src={src} alt={url} />
    //                 </TableCell>
    //                 <TableCell>
    //                     <button
    //                         style={{ wordBreak: 'break-word', cursor: "pointer", color: "#009be5" }}
    //                         onClick={() => handlePreviewFile(url, isImage)}
    //                     >
    //                         {name === 'owner' ? 'Owner Document file' : 'Customer Document file'}
    //                     </button>
    //                 </TableCell>
    //             </TableRow>
    //         </Grid>
    //     )
    // }

    useEffect(() => {
        if (inputState.is_digital_contract) {
            fetchDocusignInformation()
        }

        // eslint-disable-next-line
    }, [inputState.is_digital_contract])

    useEffect(() => {
        if (isOwnerDetailsOpen) {
            setOwnerDetailsForm({
                owner_name: inputState.owner_name,
                owner_email: inputState.owner_email,
                owner_card_number: inputState.owner_card_number,
                owner_address: inputState.owner_address,
                owner_nationality: inputState.owner_nationality,
            })
        }
    }, [isOwnerDetailsOpen, inputState])

    useEffect(() => {
        if (isCustomerDetailsOpen) {
            setCustomerDetailsForm({
                customer_name: inputState.customer_name,
                customer_email: inputState.customer_email,
                customer_card_number: inputState.customer_card_number,
                customer_address: inputState.customer_address,
                customer_nationality: inputState.customer_nationality,
            })
        }
    }, [isCustomerDetailsOpen, inputState])

    const submitOwnerDetail = () => {
        setIsLoading(true)

        DefaultAxios.post(`${API_URL}/new-closing/docusign-information`, {
            type: 'landlord',
            type_id: inputState.owner_id,
            name: ownerDetailsForm.owner_name,
            email: ownerDetailsForm.owner_email,
            ktp_number: ownerDetailsForm.owner_card_number,
            address: ownerDetailsForm.owner_address,
            nationality: ownerDetailsForm.owner_nationality,
        })
            .then(() => {
                fetchDocusignInformation()
                setIsOwnerDetailsOpen(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    const submitCustomerDetail = () => {
        setIsLoading(true)

        DefaultAxios.post(`${API_URL}/new-closing/docusign-information`, {
            type: 'customer',
            type_id: inputState.customer_id,
            name: customerDetailsForm.customer_name,
            email: customerDetailsForm.customer_email,
            ktp_number: customerDetailsForm.customer_card_number,
            address: customerDetailsForm.customer_address,
            nationality: customerDetailsForm.customer_nationality,
        })
            .then(() => {
                fetchDocusignInformation()
                setIsCustomerDetailsOpen(false)
            })
            .catch(err => {
                generalErrorHandler(err)
                setIsLoading(false)
            })
    }

    return (
        <Root>
            {/* <Dialog
                open={modalImage}
                onClose={handleCloseImg}
                closeAfterTransition
                // TransitionComponent={Transition}
                fullWidth
                maxWidth="sm"
            >
                <Card style={{ padding: 0 }} variant="outlined">
                    <CardContent style={{ padding: 0 }} >
                        <img src={modalImageUrl} alt="" width="100%" />
                    </CardContent>
                </Card>
            </Dialog> */}

            <ConfirmationModal
                {...modalProps}
            />

            <AddAgentModal
                open={addAgentModal}
                onClose={(data?: IAgentResponse) => {
                    if (data) {
                        setInputState({
                            type: 'REPLACE_STATE',
                            value: { ...inputState, agent_id: data.id, agent_label: `${data.name}${data.email ? ` - ${data.email}` : ''}${data.phone ? ` - ${data.phone}` : ''}` },
                            name: ''
                        })
                    }
                    setAddAgentModal(false)
                }}
            />

            <ModalContainer
                isOpen={isOwnerDetailsOpen}
                handleClose={() => setIsOwnerDetailsOpen(false)}
                title="Owner Detail"
                isNotFullScreen={!isMobile()}
                PaperProps={{
                    style: {
                        minWidth: isMobile() ? undefined : 600,
                    }
                }}
            >
                <Root>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                variant="outlined"
                                label="Owner Name"
                                name="owner_name"
                                value={ownerDetailsForm.owner_name}
                                onChange={handleChangedModal}
                                error={!!errorState.owner_name}
                                helperText={errorState.owner_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                variant="outlined"
                                label="Owner Email"
                                name="owner_email"
                                value={ownerDetailsForm.owner_email}
                                onChange={handleChangedModal}
                                error={!!errorState.owner_email}
                                helperText={errorState.owner_email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Owner ID Card number"
                                name="owner_card_number"
                                value={ownerDetailsForm.owner_card_number}
                                onChange={handleChangedModal}
                                error={!!errorState.owner_card_number}
                                helperText={errorState.owner_card_number}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                variant="outlined"
                                label="Owner Address"
                                name="owner_address"
                                value={ownerDetailsForm.owner_address}
                                onChange={handleChangedModal}
                                error={!!errorState.owner_address}
                                helperText={errorState.owner_address}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                select
                                variant="outlined"
                                label="Owner Nationality"
                                name="owner_nationality"
                                value={ownerDetailsForm.owner_nationality}
                                onChange={handleChangedModal}
                                error={!!errorState.owner_nationality}
                                helperText={errorState.owner_nationality}
                            >
                                {
                                    nationalities.map(nationality => <MenuItem key={nationality} value={nationality}>{nationality}</MenuItem>)
                                }
                            </TextFieldND2>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitOwnerDetail}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Root>
            </ModalContainer>

            <ModalContainer
                isOpen={isCustomerDetailsOpen}
                handleClose={() => setIsCustomerDetailsOpen(false)}
                title="Customer Detail"
                isNotFullScreen={!isMobile()}
                PaperProps={{
                    style: {
                        minWidth: isMobile() ? undefined : 600,
                    }
                }}
            >
                <Root>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                variant="outlined"
                                label="Customer Name"
                                name="customer_name"
                                value={customerDetailsForm.customer_name}
                                onChange={handleChangedModal}
                                error={!!errorState.customer_name}
                                helperText={errorState.customer_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                variant="outlined"
                                label="Customer Email"
                                name="customer_email"
                                value={customerDetailsForm.customer_email}
                                onChange={handleChangedModal}
                                error={!!errorState.customer_email}
                                helperText={errorState.customer_email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Customer ID Card number"
                                name="customer_card_number"
                                value={customerDetailsForm.customer_card_number}
                                onChange={handleChangedModal}
                                error={!!errorState.customer_card_number}
                                helperText={errorState.customer_card_number}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                variant="outlined"
                                label="Customer Address"
                                name="customer_address"
                                value={customerDetailsForm.customer_address}
                                onChange={handleChangedModal}
                                error={!!errorState.customer_address}
                                helperText={errorState.customer_address}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldND2
                                fullWidth
                                select
                                variant="outlined"
                                label="Customer Nationality"
                                name="customer_nationality"
                                value={customerDetailsForm.customer_nationality}
                                onChange={handleChangedModal}
                                error={!!errorState.customer_nationality}
                                helperText={errorState.customer_nationality}
                            >
                                {
                                    nationalities.map(nationality => <MenuItem key={nationality} value={nationality}>{nationality}</MenuItem>)
                                }
                            </TextFieldND2>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitCustomerDetail}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Root>
            </ModalContainer>


            <LoadingScreen open={isLoading} fullScreen />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {
                            isEdit ?
                                `Edit Closing - ${inputState.code}`
                                :
                                isTerminClosing
                                    ? `Extend Termin`
                                    :
                                    isExtend
                                        ? `Extend (${getManageExtendLabel()})`
                                        : "Add Closing"
                        }
                        {
                            isExtend
                                ? <Typography component="span" className={classes.closingCode}>
                                    {inputState.code}
                                </Typography>
                                : null
                        }
                    </Typography>

                    {isEdit &&
                        <Typography variant="subtitle2">
                            {
                                inputState.is_cobroke_other_agent ?
                                    inputState.agent_as === "listing" ?
                                        "Cobroke Sebagai Agent Listing" :
                                        "Cobroke Sebagai Agent Client"
                                    :
                                    "Non-cobroke"

                            }
                        </Typography>
                    }

                </Grid>
                {
                    extendFormType !== 2 && isExtend && !isTerminClosing && !isForceExtend
                        ? <Grid item xs={12}>
                            <Typography className={classes.subtitle}>
                                Apakah Client Melanjutkan Di Unit Yang Sama?
                            </Typography>
                            <ButtonRadioGroup
                                value={isUnitSame}
                                name="isUnitSame"
                                options={[
                                    [true, "Unit Yang Sama"],
                                    [false, "Pindah Unit"]
                                ]}
                                containerStyle={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                                onClick={handleUnitChanged}
                            />
                        </Grid>
                        : null
                }

                <Grid item xs={12}>
                    <Collapse in={isExtend ? !isUnitSame : true} className={classes.collapse}>
                        {(isExtend && extendFormType === 2) === false && isEdit === false &&
                            <>
                                <Grid item xs={12} md={4} className={classes.switchContainer}>
                                    <Typography>
                                        <strong>
                                            Co-broke
                                        </strong>
                                        <br />
                                        <Typography component="span" className={classes.infoText}>Aktifkan apabila transaksi ini transaksi cobroke</Typography>
                                    </Typography>
                                    <Switch
                                        color="primary"
                                        value={true}
                                        checked={inputState.is_cobroke_other_agent}
                                        onChange={handleCobrokeSwitch}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                </Grid>

                                {[0, 1].includes(extendFormType) && isExtend === false &&
                                    <Collapse in={inputState.is_cobroke_other_agent} className={classes.collapse} style={{ padding: 0 }}>
                                        <Paper className={classes.cobrokeSection} elevation={0}>
                                            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                Saya sebagai
                                            </Typography>
                                            <ButtonRadioGroup
                                                value={inputState.agent_as}
                                                name="agent_as"
                                                options={
                                                    fromAdvisorEnquiry
                                                        ? [
                                                            ["client", "Agent Client"]
                                                        ]
                                                        : [
                                                            ["client", "Agent Client"],
                                                            ["listing", "Agent Listing"]
                                                        ]
                                                }
                                                onClick={handleChangeClick}
                                                containerStyle={{ display: 'flex', flexDirection: 'row' }}
                                            />
                                            <p style={{ margin: 0 }}>
                                                {inputState.agent_as === 'client' ? <><span className={classes.infoTextBold}>Client berasal dari pihak Jendela360 </span><span className={classes.infoText}>sedangkan owner properti berasal dari luar pihak Jendela360</span></>
                                                    : <><span className={classes.infoTextBold}>Owner properti berasal dari pihak Jendela360 </span><span className={classes.infoText}>sedangkan client berasal dari luar pihak Jendela360</span></>}
                                            </p>
                                        </Paper>
                                    </Collapse>
                                }
                            </>
                        }
                    </Collapse>
                </Grid>


                {[0, 1].includes(extendFormType) && isExtend === false && enquiryNeeded &&
                    <Grid item xs={12}>
                        <Typography className={classes.subtitle}>
                            Informasi Enquiry
                        </Typography>
                    </Grid>
                }

                {(enquiryNeeded) &&
                    <>
                        <Grid item xs={6}>
                            <AsyncAutoComplete
                                label="Enquiry"
                                name="enquiry_id"
                                initialQuery={inputState.enquiry_label}
                                onChange={handleAutocomplete}
                                onInputChange={handleAutocompleteInputChanged}
                                url={`${process.env.REACT_APP_API_URL}/admin/autocomplete/enquiry/closing`}
                                placeholder="Cari Enquiry dengan ketik nama client"
                                disabled={(isExtend && isUnitSame) || fromAdvisorEnquiry}
                                errorText={errorState.enquiry_id}
                                newDesign={2}
                            />
                        </Grid>
                        {
                            inputState.telesales_name !== '' ?
                                <Grid item xs={6}>
                                    <TextFieldND2
                                        fullWidth
                                        label="Telesales"
                                        name="telesales_name"
                                        value={inputState.telesales_name}
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>
                                : null
                        }
                    </>
                }

                {
                    (!isExtend || (isExtend && extendFormType !== 2)) && inputState.is_cobroke_other_agent &&
                    <>
                        {
                            inputState.agent_as === 'listing' &&
                            <Grid item xs={12}>
                                <Typography className={classes.subtitle}>
                                    Informasi Agent
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <Grid container display="flex" alignItems="flex-end" gap={2}>
                                <Grid item xs>
                                    <AsyncAutoComplete
                                        label="Agent"
                                        name="agent_id"
                                        initialQuery={inputState.agent_label}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/agent`}
                                        placeholder="Cari dengan ketik nama atau nomor handphone agent"
                                        newDesign={2}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button style={{ whiteSpace: "nowrap" }} fullWidth color="primary" variant='outlined' startIcon={<Add />} onClick={() => setAddAgentModal(true)}>
                                        Add Agent
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

                <Grid item xs={(inputState.is_cobroke_other_agent && inputState.agent_as === "client") ? 12 : 6}>
                    <AsyncAutoComplete
                        label="Cobroke PM Internal"
                        name="agent_cobroke_internal_id"
                        initialQuery={inputState.agent_cobroke_internal_name_label}
                        onChange={handleAutocomplete}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/user/advisor-jendela`}
                        placeholder="Isi bila ada User Cobroke PM Internal"
                        errorText={errorState.agent_cobroke_internal_id}
                        newDesign={2}
                    />
                </Grid>

                {(inputState.is_cobroke_other_agent && inputState.agent_as === 'client' && !isEdit) &&
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Collapse in={inputState.is_cobroke_other_agent && inputState.agent_as === "client"} className={classes.collapse}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AsyncAutoComplete
                                        label="Nama Apartemen"
                                        name="apartment_id"
                                        initialQuery={inputState.apartment_name}
                                        onChange={handleAutocomplete}
                                        onInputChange={handleAutocompleteInputChanged}
                                        url={`${process.env.REACT_APP_API_URL}/autocomplete/apartment`}
                                        placeholder="Apartment Name"
                                        errorText={errorState.apartment_id}
                                        newDesign={2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OptionSwitcherND2
                                        label='Bedroom'
                                        name='bedroom'
                                        value={inputState.bedroom}
                                        onChange={onChange}
                                        options={['Studio', '1 Bedroom', '2 Bedrooms', '3 Bedrooms', '4 Bedrooms', '5 Bedrooms', '6 Bedrooms', '7 Bedrooms', '8 Bedrooms', '9 Bedrooms', '10 Bedrooms'].map((bedroom, index) => ({
                                            label: bedroom,
                                            value: index,
                                        }))}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <OptionSwitcherND2
                                        label="Furnished"
                                        name="furnished"
                                        value={inputState.furnished}
                                        onChange={onChange}
                                        options={[
                                            { label: "Non Furnished", value: 'non' },
                                            { label: "Semi Furnished", value: 'semi' },
                                            { label: "Fully Furnished", value: 'full' },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextFieldND2
                                                fullWidth
                                                label="Tower"
                                                name="tower"
                                                value={inputState.tower}
                                                onChange={handleChanged}
                                                variant="outlined"
                                                error={!!errorState.tower}
                                                helperText={errorState.tower}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <TextFieldND2
                                                fullWidth
                                                label="Lantai"
                                                name="floor"
                                                value={inputState.floor}
                                                onChange={handleChanged}
                                                variant="outlined"
                                                error={!!errorState.floor}
                                                helperText={errorState.floor}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <TextFieldND2
                                                fullWidth
                                                label="Nomor"
                                                name="unit_number"
                                                value={inputState.unit_number}
                                                onChange={handleChanged}
                                                variant="outlined"
                                                error={!!errorState.unit_number}
                                                helperText={errorState.unit_number}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>
                }

                {(enquiryNeeded && inputState.enquiry_id !== "" && isEdit === false) &&
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.subtitle}>
                                Informasi Tenant
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldND2
                                label="Tenant Name (isi sesuai KTP)"
                                name="enquiry_customer_name"
                                value={inputState.enquiry_customer_name}
                                onChange={handleChanged}
                            />
                        </Grid>


                        <Grid item xs={12} md={12}>
                            <TextFieldND2
                                label="Tenant Phone"
                                name="enquiry_customer_phone"
                                value={inputState.enquiry_customer_phone}
                                onChange={handleChanged}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Tenant Bank Account (BCA / Mandiri / dll.)</InputLabel>
                                        <OutlinedInput
                                            name="enquiry_customer_bank_type"
                                            value={inputState.enquiry_customer_bank_type}
                                            onChange={handleChanged}
                                            labelWidth={60}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Tenant Bank Account Number</InputLabel>
                                        <OutlinedInput
                                            name="enquiry_customer_bank_account_number"
                                            value={inputState.enquiry_customer_bank_account_number}
                                            onChange={handleChanged}
                                            labelWidth={60}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="standard-adornment-amount" variant="outlined" classes={{ shrink: classes.label }}>Tenant Bank Account Name</InputLabel>
                                        <OutlinedInput
                                            name="enquiry_customer_bank_behalf_name"
                                            value={inputState.enquiry_customer_bank_behalf_name}
                                            onChange={handleChanged}
                                            labelWidth={60}
                                        />
                                    </FormControl>
                                </Grid> */}
                        <div className={classes.marginBottom20px}>&nbsp;</div>
                    </>
                }

                {
                    (advisorNeeded || inputState.telesales_name !== '' || !inputState.is_cobroke_other_agent) ?
                        <>
                            {/* <Grid item xs={12}>
                                <Typography className={classes.subtitle}>
                                    Informasi Property Manager
                                </Typography>
                            </Grid> */}
                            {
                                (advisorNeeded || inputState.telesales_name !== '') ?
                                    <Grid item xs={12}>
                                        <AsyncAutoComplete
                                            label="Property Manager"
                                            name="advisor_id"
                                            initialQuery={inputState.advisor_name}
                                            onChange={handleAutocomplete}
                                            onInputChange={handleAutocompleteInputChanged}
                                            url={`${process.env.REACT_APP_API_URL}/autocomplete/user/advisor`}
                                            placeholder="Cari dengan ketik nama property manager"
                                            errorText={errorState.advisor_id}
                                            newDesign={2}
                                        />
                                    </Grid>
                                    : null
                            }
                        </>
                        : null
                }

                <Grid item xs={12}>
                    <Typography className={classes.subtitle}>
                        Detail Transaksi
                    </Typography>
                </Grid>

                {isTerminClosing &&
                    <Grid item xs={12}>
                        <TextFieldND2
                            fullWidth
                            variant="outlined"
                            label="Customer Name"
                            value={inputState.enquiry_label}
                            disabled
                        />
                    </Grid>
                }

                {(extendFormType !== 1 || (extendFormType === 1 && !isUnitSame)) &&
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Collapse in={!inputState.is_cobroke_other_agent || (inputState.is_cobroke_other_agent && inputState.agent_as === 'listing')} className={classes.collapse}>
                            <Grid item xs={12}>
                                <AsyncAutoComplete
                                    label="Listing"
                                    name="listing_id"
                                    initialQuery={inputState.listing_label}
                                    onChange={handleAutocomplete}
                                    onInputChange={handleAutocompleteInputChanged}
                                    url={`${process.env.REACT_APP_API_URL}/autocomplete/listing/available`}
                                    placeholder="Cari Listing dengan ketik kode unit"
                                    disabled={(isExtend && isUnitSame)}
                                    newDesign={2}
                                />
                            </Grid>
                        </Collapse>
                        {
                            inputState.listing_id
                                ? <Grid item xs={12} className={classes.unitInfo}>
                                    Ini adalah unit <strong>{inputState.category === 1 ? 'Starlisting' : 'Reguler'}</strong>
                                </Grid>
                                : null
                        }
                    </Grid>
                }

                {/* Hide temporary */}
                {/* <Grid item xs={12}>
                    <ButtonRadioND2
                        label="Peruntukan"
                        name="is_sell"
                        value={inputState.is_sell}
                        options={[
                            { label: 'Sewa', value: 0 },
                            { label: 'Jual', value: 1 },
                        ]}
                        onChange={onChange}
                        disabled={isExtend && isUnitSame}
                    />
                </Grid> */}

                <PaymentTypeAndTransactionAmountForm
                    payment_type={inputState.payment_type}
                    gmv_transaction={inputState.gmv_transaction}
                    basic_price={inputState.basic_price}
                    payment_to_financing_service={inputState.payment_to_financing_service}
                    totalTransaction={totalTransaction}
                    totalPaymentWithFinancing={totalPaymentWithFinancing}
                    handleChanged={handleChanged}
                    errorState={errorState}
                    payment_per_month_mto={inputState.payment_per_month_mto}
                    setParentMtoState={(mto: IMTO) => {
                        setInputState({ name: 'payment_per_month_mto', value: mto, type: 'SET_ITEM' });
                    }}
                />

                <Grid item xs={12} md={4}>
                    <TextFieldND3
                        fullWidth
                        variant="outlined"
                        label="Basic Price"
                        name="basic_price"
                        value={(inputState.basic_price !== "0" && inputState.basic_price !== "") ? numberToCurrency(Number(inputState.basic_price)) : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextFieldND3
                        fullWidth
                        variant="outlined"
                        label="Revenue"
                        value={(totalTransaction === '' || inputState.basic_price === '') ? '' : numberToCurrency(currencyToNumber(totalTransaction) - currencyToNumber(inputState.basic_price))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                        }}
                        disabled
                    />
                </Grid>

                {inputState.payment_type === 'jendelapay' &&
                    <Grid item xs={12}>
                        <TextFieldND3
                            fullWidth
                            variant="outlined"
                            label="GMV Financing"
                            name="gmv_financing"
                            value={(typeof inputState.gmv_financing === 'string' && inputState.gmv_financing !== '' && inputState.gmv_financing !== '0')
                                ? numberToCurrency(currencyToNumber(inputState?.gmv_financing))
                                : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                            }}
                            placeholder="GMV Financing"
                        />
                    </Grid>}

                {(isTerminClosing === false) &&
                    <>
                        <Grid item xs={12} md={6}>
                            <TextFieldND3
                                fullWidth
                                variant="outlined"
                                label="Deposit Ditahan Jendela360"
                                name="holded_deposit_office"
                                value={(inputState.holded_deposit_office !== "0" && inputState.holded_deposit_office !== "") ? numberToCurrency(Number(inputState.holded_deposit_office)) : ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                                placeholder="Deposit Ditahan Jendela360"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldND3
                                fullWidth
                                variant="outlined"
                                label="Deposit Ditahan Owner"
                                name="holded_deposit_owner"
                                value={(inputState.holded_deposit_owner !== "0" && inputState.holded_deposit_owner !== "") ? numberToCurrency(Number(inputState.holded_deposit_owner)) : ""}
                                onChange={handleChanged}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                                }}
                                placeholder="Deposit Ditahan Owner"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={`${classes.extraInfo} ${totalDeposit ? `${classes.extraInfoFilled}` : ''}`}>
                                <p>
                                    Total Deposit : &nbsp;
                                    <strong>
                                        {numberToCurrency(totalDeposit, 'Rp. ')}
                                    </strong>
                                </p>
                            </div>
                        </Grid>
                    </>
                }

                <Grid item xs={12}>
                    <FormControlLabel
                        label="Include Maintenance"
                        control={
                            <Checkbox
                                checked={inputState.is_include_maintenance}
                                onChange={handleChanged}
                                name="is_include_maintenance"
                                color="primary"
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Collapse in={inputState.is_cobroke_other_agent} className={classes.collapse}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.subtitle}>
                                    Cobroke Fee
                                </Typography>
                            </Grid>
                            <Grid item xs className={classes.typeContainer}>
                                <TextFieldND3
                                    select
                                    fullWidth
                                    variant="outlined"
                                    name="cobroke_fee_type"
                                    value={inputState.cobroke_fee_type}
                                    onChange={handleChanged}
                                    InputProps={{
                                        style: { background: "#00A1DF", color: "#fff" }
                                    }}
                                >
                                    <MenuItem value="nominal">Rp</MenuItem>
                                    <MenuItem value="percentage">%</MenuItem>
                                </TextFieldND3>
                            </Grid>

                            <Grid item xs md={6}>
                                <TextFieldND3
                                    fullWidth
                                    variant="outlined"
                                    // label="Cobroke Fee"
                                    name={inputState.cobroke_fee_type === "nominal" ? "cobroke_fee" : "cobroke_fee_percentage"}
                                    value={inputState.cobroke_fee_type === "nominal" ? numberToCurrency(Number(inputState.cobroke_fee)) : inputState.cobroke_fee_percentage}
                                    onChange={handleChanged}
                                />
                            </Grid>

                            <Grid item xs={12} md>
                                <div
                                    className={`${classes.extraInfo} ${((inputState.cobroke_fee_percentage && inputState.cobroke_fee_type === "percentage") ||
                                        (inputState.cobroke_fee && inputState.cobroke_fee_type === "nominal")) ? `${classes.extraInfoFilled}` : ""
                                        }`}
                                    style={{ width: '100%' }}
                                >
                                    <p>
                                        Equal to  <strong>
                                            {inputState.cobroke_fee_type === 'nominal' ? `${prettyFloat(inputState.cobroke_fee_percentage)}%` : `Rp ${numberToCurrency(Number(inputState.cobroke_fee))}`}
                                        </strong>
                                        {inputState.cobroke_fee_type === 'nominal' ? ' of transaction' : ''}
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>

                {isEdit === false &&
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.subtitle}>
                                Periode Sewa
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label="Gunakan Durasi"
                                control={
                                    <Checkbox
                                        color="primary"
                                        value={true}
                                        checked={isUseDuration}
                                        onChange={() => {
                                            setIsUseDuration(!isUseDuration);
                                        }}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                }
                            />
                        </Grid>
                    </>
                }

                <Grid item xs={12}>
                    <Collapse in={(+inputState.is_sell === 0)} className={classes.collapse}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={isUseDuration ? 4 : 6}>
                                <MuiDatePicker
                                    label={inputState.is_termin && !isExtend ? "Termin 1" : "Tanggal Check-In"}
                                    value={inputState.start_date}
                                    onChange={(date: any) => handleDateChanged('start_date', date)}
                                    error={!!errorState.start_date}
                                    helperText={errorState.start_date}
                                    disabled={isTerminClosing}
                                    newDesign3
                                />
                            </Grid>
                            <Grid item xs={12} md={isUseDuration ? 4 : 6}>
                                <MuiDatePicker
                                    label={'End Date'}
                                    value={inputState.end_date}
                                    onChange={(date: any) => handleDateChanged('end_date', date)}
                                    error={!!errorState.end_date}
                                    helperText={errorState.end_date}
                                    disabled={isUseDuration}
                                    minDate={inputState.start_date ? addDays(inputState.start_date, 1) : undefined}
                                    newDesign3
                                />
                            </Grid>

                            {isUseDuration &&
                                <Grid item xs={12} md={4}>
                                    <TextFieldND3
                                        ref={durationRef}
                                        fullWidth
                                        variant="outlined"
                                        label="Duration"
                                        name="duration"
                                        value={inputState.duration}
                                        onChange={handleChanged}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">Bulan</InputAdornment>,
                                        }}
                                        error={!!errorState.duration}
                                        helperText={errorState.duration}
                                        isGrey
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Collapse>
                </Grid>


                {
                    (closingStatus === 2 || closingStatus === 4) &&
                    <Grid item xs={12}>
                        <Collapse in={true} className={classes.collapse}>
                            <Grid container spacing={2}>
                                {(closingStatus === 2 || closingStatus === 4) &&
                                    <Grid item xs={12} md={6}>
                                        <MuiDatePicker
                                            label={'Tanggal Pembayaran Pertama'}
                                            value={inputState.first_payment_date}
                                            onChange={(date: any) => handleDateChanged('first_payment_date', date)}
                                            disabled={isTerminClosing}
                                        />
                                    </Grid>
                                }
                                {(closingStatus === 4) &&
                                    <Grid item xs={12} md={6}>
                                        <MuiDatePicker
                                            label={'Tanggal Pelunasan'}
                                            value={inputState.finished_payment_date}
                                            onChange={(date: any) => handleDateChanged('finished_payment_date', date)}
                                            error={!!errorState.end_date}
                                            helperText={errorState.end_date}
                                            disabled={isUseDuration}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Collapse>
                    </Grid>
                }


                {(+inputState.is_sell !== 1 && !isTerminClosing) && !isForceExtend &&
                    <>
                        <Grid item xs={4} className={classes.switchContainer}>
                            <Typography className={classes.subtitle}>
                                Termin
                            </Typography>
                            <Switch
                                color="primary"
                                value={true}
                                checked={inputState.is_termin}
                                onChange={handleTerminSwitch}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                                disabled={(inputState.end_date === null || inputState.start_date === null)}
                            />
                        </Grid>
                    </>
                }

                {
                    isEdit ?
                        null :
                        <>

                            {/* {inputState.duration === "" || inputState.start_date === null ?
                            <Grid item xs={12} style={{ paddingTop: "0px" }}>
                                <Typography variant="subtitle2">
                                    Note: Harap isi tanggal check-in dan durasi terlebih dahulu
                                </Typography>
                            </Grid>
                            :
                            null
                        } */}

                            {(+inputState.is_sell !== 1 && !isTerminClosing) && (inputState.is_termin === true) &&
                                <>
                                    <TerminForm
                                        defaultAmount={inputState.gmv_transaction}
                                        defaultBasicPrice={inputState.basic_price}
                                        termins={inputState.termins}
                                        endDate={inputState.end_date}
                                        setParentTerminState={(termin: ITermin) => {
                                            setInputState({ name: 'termins', value: termin, type: 'SET_ITEM' });
                                        }}
                                        hideNote
                                    />
                                </>
                            }

                            <Grid item xs={12}>
                                <Typography className={classes.subtitle}>
                                    Bukti Pembayaran
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={classes.switchContainer}>
                                <Card variant='outlined' sx={{ width: '100%' }}>
                                    <CardContent >
                                        <Button component="label" color='inherit' variant="contained">
                                            Upload Bukti Pembayaran
                                            <VisuallyHiddenInput
                                                type="file"
                                                multiple={false}
                                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                                onChange={(e) => {
                                                    if (e.target.files?.length) {
                                                        setInputState({ name: "payment_file", type: "SET_ITEM", value: e.target.files[0] })
                                                    }
                                                }}
                                            />
                                        </Button>
                                        {
                                            inputState.payment_file instanceof File &&
                                            <Typography variant='body1' sx={{ ml: 1, display: 'inline' }}>
                                                {inputState.payment_file.name}
                                            </Typography>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                }

                <Grid item xs={12} className={classes.switchContainer}>
                    <Typography className={classes.subtitle}>
                        Keterangan Closing
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextArea
                        label={isExtend ? "Keterangan Extend" : "Keterangan Closing"}
                        name="notes"
                        value={inputState.notes}
                        onChange={handleChanged}
                    />
                </Grid>

                {/* <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={inputState.is_include_utility_billing}
                                    onChange={handleChanged}
                                    name="is_include_utility_billing"
                                    color="primary"
                                />
                            }
                            label="Include Utility Billing"
                        />
                    </Grid>

                    <Grid item xs={12}> 
                    <FormControlLabel
                        control={
                            <Switch
                                checked={inputState.is_include_other_billing}
                                onChange={handleChanged}
                                name="is_include_other_billing"
                                color="primary"
                            />
                        }
                        label="Include Other Billing"
                    />
                </Grid>*/}

                {(!isTerminClosing && !inputState.is_cobroke_other_agent) && !isForceExtend && !isExtend && params.id &&
                    <>
                        <Grid item xs={12} style={{ marginTop: '50px' }}>
                            <hr />
                        </Grid>

                        <Grid item xs={12} className={classes.switchContainer}>
                            <Typography className={classes.subtitle}>
                                Digital Contract
                            </Typography>
                            <Switch
                                color="primary"
                                value={true}
                                checked={inputState.is_digital_contract}
                                onChange={handleDigitalContractSwitch}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </Grid>

                        <Grid item xs={12} style={{ paddingTop: 12 }}>
                            <Collapse in={inputState.is_digital_contract} className={classes.collapse}>
                                <Grid container spacing={3}>
                                    {/* <Grid item xs={12} className={classes.switchContainer}>
                                            <Typography className={classes.digitalContractSubtitle}>
                                                Include Maintenance
                                            </Typography>
                                            <Switch
                                                color="primary"
                                                value={true}
                                                name="is_maintenance_included"
                                                checked={inputState.is_maintenance_included}
                                                onChange={handleChanged}
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                        </Grid> */}

                                    {/* {
                                            isExtend &&
                                            <Grid item xs={12}>
                                                <TextFieldND2
                                                    select
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Digital Contract Template"
                                                    name="digital_contract_template"
                                                    value={inputState.digital_contract_template}
                                                    onChange={handleChanged}
                                                >
                                                    {digitalContractTemplates.map(item => <MenuItem value={item.key} key={item.key}>{item.value}</MenuItem>)}
                                                </TextFieldND2>
                                            </Grid>
                                        } */}

                                    <Grid item xs={12} className={classes.switchContainer}>
                                        <Typography className={classes.subtitle}>
                                            English Contract
                                        </Typography>
                                        <Switch
                                            color="primary"
                                            name="is_english"
                                            value={true}
                                            checked={inputState.is_english}
                                            onChange={handleChanged}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={fetchDocusignInformation}
                                                >
                                                    Pull Customer and Owner information
                                                </Button>
                                            </Grid> */}

                                    {(+inputState.is_sell !== 1 && !isTerminClosing) && (inputState.is_termin === true) &&
                                        <>
                                            <Grid item xs={12} className={classes.switchContainer}>
                                                <Typography className={classes.subtitle}>
                                                    Pembayaran Termin Custom
                                                </Typography>
                                                <Switch
                                                    color="primary"
                                                    value={true}
                                                    checked={inputState.is_contract_custom_termin}
                                                    onChange={handleCustomDigitalContractSwitch}
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                />
                                            </Grid>

                                            {inputState.is_contract_custom_termin &&
                                                <TerminForm
                                                    defaultAmount={inputState.gmv_transaction}
                                                    defaultBasicPrice={inputState.basic_price}
                                                    hideNote={true}
                                                    startFromOne={true}
                                                    termins={inputState.contract_custom_termins}
                                                    endDate={subDays(new Date(), 30)}
                                                    setParentTerminState={(termin: ITermin) => {
                                                        setInputState({ name: 'contract_custom_termins', value: termin, type: 'SET_ITEM' });
                                                    }}
                                                />
                                            }
                                        </>
                                    }

                                    <Grid item xs={12} className={classes.switchContainer}>
                                        <Typography className={classes.subtitle}>
                                            Custom Document Owner
                                        </Typography>
                                        <Switch
                                            color="primary"
                                            value={true}
                                            checked={inputState.is_doc_owner_custom}
                                            onChange={handleDocOwnerSwitch}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    </Grid>

                                    {
                                        inputState.is_doc_owner_custom &&
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                                Silahkan Upload file PDF di Menu Upload File dan upload file tipe <br /> "Custom Contract Owner"
                                            </Typography>
                                        </Grid>

                                        /* {
                                            inputState.custom_owner_doc_url &&
                                                renderFiles(inputState.custom_owner_doc_url, 'owner')
                                        }
                                        <Grid item xs={12}>
                                            <input type="file" name="custom_owner_doc_file" onChange={handleFileChange} />
                                            {
                                                !!errorState.custom_owner_doc_file &&
                                                <>
                                                    <br />
                                                    <span style={{ color: 'red' }}>{errorState.custom_owner_doc_file}</span>
                                                </>
                                            }
                                        </Grid> */
                                    }

                                    <Grid item xs={12}>
                                        <div className={classes.detailsContainer}>
                                            <Typography className={classes.digitalContractSubtitle}>
                                                Owner Details
                                            </Typography>
                                            <IconButton color="primary" onClick={() => setIsOwnerDetailsOpen(true)}>
                                                <CreateIcon />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        Nama: {inputState.owner_name || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        Email: {inputState.owner_email || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        KTP: {inputState.owner_card_number || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        Address: {inputState.owner_address || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        Nationality: {inputState.owner_nationality || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldND2
                                            fullWidth
                                            variant="outlined"
                                            label="Custom Email"
                                            name="custom_owner_email"
                                            value={inputState.custom_owner_email}
                                            onChange={handleChanged}
                                            error={!!errorState.custom_owner_email}
                                            helperText={errorState.custom_owner_email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldND2
                                            fullWidth
                                            variant="outlined"
                                            label="Custom Deposit"
                                            name="custom_owner_deposit"
                                            value={inputState.custom_owner_deposit ? numberToCurrency(Number(inputState.custom_owner_deposit)) : ""}
                                            onChange={handleChanged}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <TextFieldND2
                                            fullWidth
                                            variant="outlined"
                                            label="Deposit Percentage Owner"
                                            name="custom_owner_deposit_percentage"
                                            value={inputState.custom_owner_deposit_percentage ? inputState.custom_owner_deposit_percentage : ""}
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className={classes.switchContainer}>
                                        <Typography className={classes.subtitle}>
                                            Custom Document Customer
                                        </Typography>
                                        <Switch
                                            color="primary"
                                            value={true}
                                            checked={inputState.is_doc_customer_custom}
                                            onChange={handleDocCustomerSwitch}
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    </Grid>

                                    {
                                        inputState.is_doc_customer_custom &&

                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                                Silahkan Upload file PDF di Menu Upload File dan upload file tipe  <br /> "Custom Contract Tenant"
                                            </Typography>
                                        </Grid>
                                        // <>
                                        //     {
                                        //         inputState.custom_customer_doc_url &&
                                        //         renderFiles(inputState.custom_customer_doc_url, 'customer')
                                        //     }
                                        //     <Grid item xs={12}>
                                        //         <input type="file" name="custom_customer_doc_file" onChange={handleFileChange} />
                                        //         {
                                        //             !!errorState.custom_customer_doc_file &&
                                        //             <>
                                        //                 <br />
                                        //                 <span style={{ color: 'red' }}>{errorState.custom_customer_doc_file}</span>
                                        //             </>
                                        //         }
                                        //     </Grid>
                                        // </>
                                    }

                                    <Grid item xs={12}>
                                        <div className={classes.detailsContainer}>
                                            <Typography className={classes.digitalContractSubtitle}>
                                                Customer Details
                                            </Typography>
                                            <IconButton color="primary" onClick={() => setIsCustomerDetailsOpen(true)}>
                                                <CreateIcon />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        Nama: {inputState.customer_name || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        Email: {inputState.customer_email || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        KTP: {inputState.customer_card_number || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        Address: {inputState.customer_address || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        Nationality: {inputState.customer_nationality || '-'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldND2
                                            fullWidth
                                            variant="outlined"
                                            label="Custom Email"
                                            name="custom_customer_email"
                                            value={inputState.custom_customer_email}
                                            onChange={handleChanged}
                                            error={!!errorState.custom_customer_email}
                                            helperText={errorState.custom_customer_email}
                                        />
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </>
                }

                <Grid item xs={12} className={classes.submitContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root >
    );
}

export default ClosingForm;