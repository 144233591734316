import React, { useState, useEffect } from "react";
import { SnackbarProvider } from 'notistack'
import { Notifications } from 'react-push-notification'
import * as Sentry from "@sentry/react";
/** Override name of routes for get react sentry route */
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes as ReactRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

/**
 * Components
 */
import PrivateRoute from './_components/PrivateRoute';
import MenuBar from './_components/MenuBar';

/**
 * Utils
 */
import DefaultAxios from "./_utils/DefaultAxios";
import moment from 'moment';
import { PermissionContext } from "../src/_contexts/PermissionContext";

/**
 * CSS
 */
import './App.css';


/****************** Pages *****************/

/**
 * Login
 */
import SignInSide from './_pages/SignInSide';

/**
 * Notification
 */
import Notification from "./_pages/_notification/Notification";

/**
 * Dashboard Root
 */
import DashboardRoot from './_pages/DashboardRoot';

/**
 * My Unit
 */
// import MyUnitList from "./_pages/_myUnit/MyUnitList"

/**
 * Dashboard Captain
 */
import DashboardUnitAdvisorCaptain from "./_pages/_dashboardUnitAdvisorCaptain/DashboardUnitAdvisorCaptain"

/**
 * Dashboard Kunci CS
 */
import DashboardKunciCS from './_pages/_dashboardKunciCS/DashboardKunciCS';

/**
 * Enquiry
 */
import EnquiryAddForm from "./_pages/_enquiry/EnquiryAddForm";
import EnquiryList from './_pages/_enquiry/EnquiryList';

/**
 * Enquiry Chart
 */
import EnquiryChart from './_pages/_enquiry/EnquiryChart';

/**
 * Listing Inventory
 */
import ListingInventoryList from "./_pages/_listingInventory/ListingInventoryList";
import UnitList from "./_pages/_listingInventory/UnitList";

/**
 * Demand Supply Chart
 */
import DemandSupply from "./_pages/_demandSupply/DemandSupply";
import DemandSupplyTV from "./_pages/_demandSupply/DemandSupplyTV";
import HandoverUnitList from "./_pages/_handoverUnit/HandoverUnitList";
// import DetailListingInventory from "./_pages/_listingInventory/DetailListingInventory";
import ManageUnit from './_pages/_manageUnit/ManageUnit';

/**
 * Listing CMS (for Agent)
 */
import ListingForm from './_pages/_listing/ListingForm';
import ListingPreviewEdit from './_pages/_listing/ListingPreviewEdit';
import ListingList from "./_pages/_listingList/ListingList";

/**
 * Listing Admin CMS
 */
import ListingListAdmin from './_pages/_listingAdmin/ListingListAdmin';
import ListingFormAdmin from './_pages/_listingAdmin/ListingFormAdmin';
import LandingOtherListingForm from "./_pages/_landingOtherListing/LandingOtherListingForm";
import ListingLocationArticleForm from "./_pages/_listingLocationArticle/ListingLocationArticleForm";

/**
 * Agent
 */
import AgentList from "./_pages/_agent/AgentList";
import AgentForm from "./_pages/_agent/components/AgentForm";
import AgentOfficeList from "./_pages/_agent/AgentOfficeList";
import AgentListTwo from "./_pages/_agent/AgentListTwo";

/**
 * Activity
 */
import ActivityList from "./_pages/_activity/ActivityList";
import ActivityForm from "./_pages/_activity/ActivityForm";
import ActivityFormMobile from "./_pages/_activity/ActivityFormMobile";
import ActivitySchedule from "./_pages/_activity/ActivitySchedule";

/**
 * Landlord Clean Data Form
 */
import LandlordCleanDataForm from "./_pages/_landlord/LandlordCleanDataForm";

/**
 * Landlord
 */
import LandlordList from "./_pages/_landlord/LandlordList";
import LandlordForm from "./_pages/_landlord/LandlordForm";

/**
 * Customer
 */
import CustomerList from "./_pages/_customer/CustomerList";
import CustomerForm from "./_pages/_customer/CustomerForm";

/**
 * Unit
 */
import ListUnit from "./_pages/_unit/ListUnit";
import UnitForm from "./_pages/_unit/UnitForm";
// import UnitModalDetail from "./_pages/_unit/UnitModalDetail";

/**
 * Credit Memo
 */
import CreditMemoList from "./_pages/_creditMemo/CreditMemoList";
import CreditMemoForm from "./_pages/_creditMemo/CreditMemoForm";

/**
 * Rent Manage
 */
import RentList from "./_pages/_rent/RentList";

/**
 * Role Manage
 */
import RoleList from "./_pages/_role/RoleList";

/**
 * User Manage
 */
import UserList from './_pages/_user/UserList';

/**
 * Finance Request List
 */
import FinanceRequestList from './_pages/_financeRequest/FinanceRequestList';

/**
 * Closing
 */
import ClosingForm from "./_pages/_closing/ClosingForm";
import ClosingReferralForm from "./_pages/_closingReferral/ClosingReferralForm";
import ClosingList from "./_pages/_closing/ClosingList";
// import ClosingListInventory from "./_pages/_closing/_components/ClosingListInventory";
// import ClosingFormEdit from "./_pages/_closing/ClosingFormEdit";

/**
 * Manage Extend
 */
import ManageExtendList from "./_pages/_manageExtend/ManageExtendList";

/**
 * Project Developer
 */
import ProjectDeveloperModalDetail from "./_pages/_developerProperty/ProjectDeveloperModalDetail";
import PropertyForm from "./_pages/_developerProperty/PropertyForm";
import ProjectDeveloperForm from "./_pages/_projectDeveloper/ProjectDeveloperForm";
import ProjectDeveloperList from "./_pages/_projectDeveloper/ProjectDeveloperList";
import ProjectDeveloperCategoryList from "./_pages/_projectDeveloperCategory/ProjectDeveloperCategoryList";

/**
 * Apartment
 */
import ApartmentList from './_pages/_apartment/ApartmentList';

/**
 * Apartment Note
 */
import ApartmentNotesList from "./_pages/_apartmentNotes/ApartmentNotesList";

/**
 * Apartment Review
 */
import ApartmentReviewList from "./_pages/_apartmentReview/ApartmentReviewList";

/**
 * Apartment Advisor
 */
// import ApartmentAdvisorList from "./_pages/_apartmentAdvisor/ApartmentAdvisorList";

/**
 * Unit Advisor
 */
import UnitAdvisorList from "./_pages/_unitAdvisor/UnitAdvisorList";

/**
 * Showing Manage
 */
import ManageShowing from './_pages/_manageShowing/ManageShowing';

/**
 * Dashboard Kunci
 */

/**
 * Stay 360
 */
import Stay360Booking from "./_pages/_calendarBooking/CalendarBooking";
import Stay360Property from "./_pages/_stay360Property/Stay360Property";
import Stay360Unit from "./_pages/_stay360Unit/Stay360Unit";
import Stay360BookingForm from "./_pages/_stay360Booking/Stay360BookingForm";
import Stay360BookingDetailModal from "./_pages/_stay360Booking/Stay360BookingDetailModal";
import Stay360BookingReport from "./_pages/_stay360Booking/Stay360BookingReport";
import Stay360PmsHome from "./_pages/_stay360Pms/Stay360PmsHome";
import Stay360PmsList from "./_pages/_stay360Pms/Stay360PmsList";

/**
 * Enquiry Sell Placement
 */
import EnquirySellPlacement from "./_pages/_enquirySellPlacement/EnquirySellPlacement";

/**
 * Profile Setting
 */
import ProfileSetting from "./_pages/_profileSetting/ProfileSetting";

/**
 * Ticketing System
 */
import TicketSystemList from './_pages/_ticketSystem/TicketSystemList';
import TicketSystemForm from './_pages/_ticketSystem/TicketSystemForm';

/**
 * Force User Login
 */
import ForceLogin from "./_pages/_user/ForceLogin";
import ApartmentForm from "./_pages/_apartment/ApartmentForm";

/**
 * Log Login
 */
import LoginLogList from "./_pages/_loginLog/LoginLogList";

/**
 * Reimbursement Recap Daily
 */
import ReimbursementRecapDailyList from "./_pages/_reimbursementRecapDaily/ReimbursementRecapDailyList";

/**
 * Province
 */
import ProvinceList from "./_pages/_province/ProvinceList"

/**
 * District
 */
import DistrictList from "./_pages/_district/DistrictList"
import DistrictForm from "./_pages/_district/DistrictForm"

/**
 * Unit Download Image
 */
import UnitDownloadImage from "./_pages/_unitDownloadImage/UnitDownloadImage";

/**
 * Unit Marketplace Log
 */
import UnitMarketplaceLogList from "./_pages/_unitMarketplaceLog/UnitMarketplaceLogList";

/**
 * Blast Template
 */
import BlastTemplateList from "./_pages/_blastTemplate/BlastTemplateList";

/**
 * Clean Scrap Form
 */
import CleanScrapForm from "./_pages/_cleanScrapForm/CleanScrapForm";

/**
 * Unit Special
 */
import UnitSpecialList from "./_pages/_unitSpecial/UnitSpecialList";

/**
 * Advisor Member
 */
// import AdvisorMemberList from "./_pages/_advisorMember/AdvisorMemberList";

/**
 * Advisor Group
 */
import AdvisorGroupList from "./_pages/_advisorGroup/AdvisorGroupList";
import ClosingChart from "./_pages/_closing/ClosingChart";
import AdvisorPlacement from "./_pages/_advisorPlacement/AdvisorPlacement";

/**
 * Career
 */
import CareerList from "./_pages/_career/CareerList";
import CareerForm from "./_pages/_career/CareerForm";
import AreaList from "./_pages/_area/AreaList";
import AreaForm from "./_pages/_area/AreaForm";

/**
 * Pay Simulator
 */
import PaySimulator from "./_pages/_paySimulator/PaySimulator";

/**
 * Unit Key Manage
 */
import UnitKeyManageList from "./_pages/_unitKeyManage/UnitKeyManageList";
import UnitKeyBorrowForm from "./_pages/_unitKeyBorrow/UnitKeyBorrowForm";
import UnitKeyHistoryList from "./_pages/_unitKeyHistory/UnitKeyHistoryList";
import UnitKeyRequest from './_pages/_unitKeyRequest/UnitKeyRequest';

/**
 * Unit Key Admin
 */
import UnitKeyBorrowRequest from './_pages/_unitKeyBorrowRequest/UnitKeyBorrowRequest';
import UnitKeyLogsList from "./_pages/_unitKeyLogs/UnitKeyLogsList"

/**
 * Advisor Performance
 */
// import AdvisorPerformance from './_pages/_advisorPerformance/AdvisorPerformance';

/**
 * Map File Form
 */
import MapFileForm from "./_pages/_mapFileForm/MapFileForm";
import RentSubmissionChart from "./_pages/_rentSubmissionChart/RentSubmissionChart";
import AssetUploadList from "./_pages/_assetUpload/AssetUploadList";
import EntrustedKeyReport from "./_pages/_entrustedKeyReport/EntrustedKeyReport";
import DashboardPMLead from "./_pages/_dashboardPM/DashboardPMLead";
import DashboardPM from "./_pages/_dashboardPM/DashboardPM";
import BookingList from "./_pages/_booking/BookingList";
import BookingForm from "./_pages/_booking/BookingForm";

/**
 * Account
 */
import AccountList from './_pages/_account/AccountList';

/**
 * Holiday
 */
import HolidayList from "./_pages/_holiday/HolidayList";
import QualityControlUnitList from "./_pages/_qcUnit/QualityControlUnitList";
import InventoryRoomModal from "./_pages/_qcUnit/_components/InventoryRoomModal";
import TransportReimbursementList from "./_pages/_transportReimbursement/TransportReimbursementList";
import StatementList from "./_pages/_statement/StatementList";
import VerifyTransactionList from "./_pages/_verifyTransaction/VerifyTransactionList";

/**
 * Bank Others
 */
import BankOthersList from "./_pages/_bankOthers/BankOthersList";
import BankOthersForm from "./_pages/_bankOthers/BankOthersForm";

/**
 * Closing Transaction Export
 */
import ClosingTransactionExport from "./_pages/_closingTransactionExport/ClosingTransactionExport";

/**
 * Top Apartment Closing
 */
import TopApartmentClosing from "./_pages/_topApartmentClosing/TopApartmentClosing";
import { Theme } from "@mui/material";

/**
 * Bank Name Verifier
 */
import BankVerifierForm from "./_pages/_bankNameVerifier/BankVerifierForm";

/**
 * JHS Order
 */
import JhsOrderForm from "./_pages/_jhsOrder/JhsOrderForm";
import JhsOrderList from "./_pages/_jhsOrder/JhsOrderList";

/**
 * Billing Collection
 */
import BillingCollectionList from "./_pages/_billingCollection/BillingCollectionList";

/**
 * Download Report TR
 */
import DownloadSupportSupervisor from './_pages/_downloadReportSupportSupervisor/DownloadReportSupportSupervisor';

/**
 * Download Report TR
 */

/**
 * Download Report General
 */
import DownloadReportGeneral from "./_pages/_downloadReportGeneral/DownloadReportGeneral";

/**
 * Edit
 */
import EditListingSubmission from "./_pages/_rent/EditListingSubmission";
import ActivityShowingList from "./_pages/_activity/ActivityShowingList";

/** 
 * PIC Landlord
 */
import PICList from "./_pages/_picLandlord/PICList";
import PICForm from "./_pages/_picLandlord/PICForm";
import BroadcastQiscus from "./_pages/_broadcastQiscus/BroadcastQiscus";

/**
 * Bank Vendor
 */
import BankVendorList from "./_pages/_bankVendor/BankVendorList";

/**
 * Unit Priority Homepage
 */
import PriorityUnitHomepageList from "./_pages/_priorityUnitHomepage/PriorityUnitHomepageList";

/**
 * Rent Submission Apartment
 */
import ListingSubmissionApartmentList from "./_pages/_listingSubmissionApartment/ListingSubmissionApartmentList";

/**
 * Edit Listing Submission Apartment
 */
// import ListingSubmissionApartmentEdit from "./_pages/_listingSubmissionApartment/ListingSubmissionApartmentEdit";

/**
 * Merge Customer
 */
import CustomerMerge from "./_pages/_customerMerge/CustomerMerge";

/**
 * Target Scoring Dashboard
 */
import TargetScoringDashboard from "./_pages/_targetScoringDashboard/TargetScoringDashboard";

/**
 * Dashboard PM Captain
 */
import DashboardPMCaptain from "./_pages/_targetScoringDashboard/DashboardPMCaptain";

/**
 * List Unit Delete
 */
import UnitDeleteList from "./_pages/_unitRequestedDelete/UnitDeleteList";

/**
 * Apartment Download Image
 */
import ApartmentDownloadImage from "./_pages/_apartmentDownloadImage/ApartmentDownloadImage";

/**
 * Product Knowledge List
 */
import ProductKnowledgeList from "./_pages/_productKnowledge/ProductKnowledgeList";

/**
 * Funnel Report
 */
import FunnelReport from "./_pages/_reportScreen/FunnelReport";

/**
 * Funnel Report
 */
import FunnelReportListing from "./_pages/_reportScreen/FunnelReportListing";

/**
 * Enquiry Breakdown
 */
import EnquiryBreakdown from "./_pages/_reportScreen/EnquiryBreakdown";

/**
 * JHS Schedule
 */
import JHSSchedule from "./_pages/_jhsSchedule/JHSSchedule";

/**
 * Unit Management List
 */
import UnitManagementList from "./_pages/_unitManagement/UnitManagementList";

/**
 * Telesales Area List
 */
import TelesalesAreaList from "./_pages/_telesalesArea/TelesalesAreaList";
import ShowingSchedule from "./_pages/_showingSchedule/ShowingSchedule";
/**
 * Apartment performance
 */
import ApartmentPerformanceList from "./_pages/_apartmentPerformance/ApartmentPerformanceList";

/**
 * Listing Ticketlist
 */
import ListingTicketList from "./_pages/_listingTicket/ListingTicketList";
import WhatsappChatChecker from "./_pages/_whatsappChatChecker/WhatsappChatChecker";

import { generateStyle } from "./_utils/DefaultStyle";
import DownloadReportTR from "./_pages/_downloadReportTR/DownloadReportTR";
import JhsOrderModalDetail from "./_pages/_jhsOrder/JhsOrderModalDetail";
import BulkUpdateExtendPIC from "./_pages/_bulkUpdateExtendPIC/BulkUpdateExtendPIC";
import UnitSellList from "./_pages/_unitSell/UnitSellList";
import SellAdvisorAreaList from "./_pages/_sellAdvisorArea/SellAdvisorAreaList";
import GeneralPerformance from "./_pages/_generalPerformance/GeneralPerformance";
import UnitPhotoChecklist from "./_pages/_unitPhotoChecklist/UnitPhotoChecklist";
import ScanKTP from "./_pages/_scanKTP/ScanKTP";
import UnitReviewList from "./_pages/_unitReview/UnitReviewList";
import ArticleLandingPage from "./_pages/_addArticleLandingPage/ArticleLandingPage";
import ArticleLandingPageForm from "./_pages/_addArticleLandingPage/ArticleLandingPageForm";
import AvailableApartmentUnit from "./_pages/_availableApartmentUnit/AvailableApartmentUnit";
import ListUnitEntrustedKey from "./_pages/_listUnitEntrustedKey/ListUnitEntrustedKey";
import LeadListingFacebook from "./_pages/_leadListingFacebook/LeadListingFacebook";
import EnquiryBuyList from "./_pages/_enquiryBuy/EnquiryBuyList";
import TopSellerList from "./_pages/_topSeller/TopSellerList";
import ClosingRequestList from "./_pages/_closingRequest/ClosingRequestList";
import ClosingRequestForm from "./_pages/_closingRequest/_components/ClosingRequestForm";
import ClosingSellList from "./_pages/_closingAdminFlipper/ClosingSellList";
import ListingSubmissionSellList from "./_pages/_listingSubmissionAdminFlipper.tsx/ListingSubmissionSellList";
import StatementAssigner from "./_pages/_statementAssigner/StatementAssigner";

const useStyles = generateStyle((theme: Theme) =>
({
  success: {
    backgroundColor: '#033a11'
  },
  error: {
    backgroundColor: '#c62828'
  }
}), "App"
)

const App = () => {
  if (process.env.REACT_APP_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_PUBLIC_SENTRY_DSN,
        environment: process.env.REACT_APP_PUBLIC_ENV,
        release: process.env.REACT_APP_PUBLIC_SENTRY_RELEASE,
        autoSessionTracking: true,
        tracesSampleRate: 0.25,
        tracePropagationTargets: ["localhost", /^https:\/\/helloworld\.jendela360\.com\/api/],
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
    });
    if (localStorage.getItem('user_id')) {
      Sentry.setUser({
          id: localStorage.getItem('user_id') ?? undefined
      })
    }
  }

  const Routes = process.env.REACT_APP_PUBLIC_SENTRY_DSN ? Sentry.withSentryReactRouterV6Routing(ReactRoutes) : ReactRoutes;

  const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('permissions') || "{}"));

  const { Root, classes } = useStyles()

  useEffect(() => {
    if (localStorage.getItem("jwt_token") !== null) {
      if (localStorage.getItem("permissions") === null || moment().unix() > Number(localStorage.getItem('reset_permission_timer'))) {
        fetchPermissions();
      }
    }
  }, [permissions]);

  const fetchPermissions = () => {
    DefaultAxios
      .get(`${process.env.REACT_APP_API_URL}/permissions`)
      .then(res => {
        setPermissions(res.data || {});
        localStorage.setItem('permissions', JSON.stringify(res.data || {}));
        localStorage.setItem('reset_permission_timer', String(moment().add(15, 'minutes').unix()));
      });
  }

  return (
    <PermissionContext.Provider value={permissions}>
      <Notifications />
      <Root>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          disableWindowBlurListener
          hideIconVariant
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error
          }}
          onEntered={() => {
            window.navigator.vibrate([200, 100, 200])
          }}
        >
          <Router>
            <Routes>
              <Route path="/login" element={<SignInSide></SignInSide>} />
              <Route path="/advisor-placement" element={<PrivateRoute component={() => <MenuBar title="Dashboard"><AdvisorPlacement /></MenuBar>} key={"/advisor-placement"} />} />
              <Route path="/" element={<PrivateRoute component={() => <DashboardRoot />} key={"/"} />} />

              {/* Force Login */}
              <Route path="/user/force" element={<PrivateRoute component={() => <ForceLogin />} key={"/user/force"} />} />

              {/* Notification */}
              <Route path="/notification" element={<PrivateRoute component={() => <MenuBar title="Notification"><Notification /></MenuBar>} key={"/notification"} />} />

              {/* Enquiry Chart */}
              <Route path="/enquiry/chart" element={<PrivateRoute component={() => <MenuBar title="Enquiry List"><EnquiryChart /></MenuBar>} key={"/enquiry-chart"} />} />

              <Route path="/enquiry/add" element={<PrivateRoute component={() => <MenuBar title="Enquiry Form"><EnquiryAddForm /></MenuBar>} key={"/enquiry/add"} />} />
              <Route path="/enquiry/create" element={<PrivateRoute component={() => <MenuBar title="Enquiry Form"><EnquiryAddForm /></MenuBar>} key={"/enquiry/add"} />} />
              <Route path="/enquiry" element={<PrivateRoute component={() => <MenuBar title="Enquiry List"><EnquiryList /></MenuBar>} key={"/enquiry"} />} />
              <Route path="/enquiry/:enquiry_id" element={<PrivateRoute component={() => <MenuBar title="Enquiry List"><EnquiryList /></MenuBar>} key={"/enquiry"} />} />

              {/* Enquiry Buy */}
              <Route path="/enquiry-buy" element={<PrivateRoute component={() => <MenuBar title="Enquiry Buy"><EnquiryBuyList /></MenuBar>} key={"/enquiry-buy"} />} />
              <Route path="/enquiry-buy/:enquiry_id" element={<PrivateRoute component={() => <MenuBar title="Enquiry Buy"><EnquiryBuyList /></MenuBar>} key={"/enquiry-buy"} />} />


              <Route path="/apartment/add" element={<PrivateRoute component={() => <MenuBar title="Add Apartment"><ApartmentForm /></MenuBar>} key="/" />} />
              <Route path="/apartment/:id" element={<PrivateRoute component={() => <MenuBar title="Apartment Detail"><ApartmentList /></MenuBar>} key="/apartment/:id" />} />
              <Route path="/apartment" element={<PrivateRoute component={() => <MenuBar title="Apartment"><ApartmentList /></MenuBar>} key="/" />} />
              <Route path="/apartment/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Edit Apartment"><ApartmentForm /></MenuBar>} key="/" />} />
              <Route path="/apartment/edit/:id/:type" element={<PrivateRoute component={() => <MenuBar title="Edit Apartment"><ApartmentForm /></MenuBar>} key="/" />} />

              {/* Apartment Notes */}
              <Route path="/apartment-notes" element={<PrivateRoute component={() => <MenuBar title="Apartment Notes"><ApartmentNotesList /></MenuBar>} key="/apartment-notes" />} />
              <Route path="/apartment-notes/:note_id" element={<PrivateRoute component={() => <MenuBar title="Apartment Notes"><ApartmentNotesList /></MenuBar>} key="/apartment-notes/:note_id" />} />

              {/* Apartment Review */}
              <Route path="/apartment-review" element={<PrivateRoute component={() => <MenuBar title="Apartment Review"><ApartmentReviewList /></MenuBar>} key="/apartment-review" />} />
              <Route path="/apartment-review/:review_id" element={<PrivateRoute component={() => <MenuBar title="Apartment Review"><ApartmentReviewList /></MenuBar>} key="/apartment-review/:review_id" />} />

              {/* Apartment Advisor */}
              {/* <Route path="/apartment-advisor" element={<PrivateRoute component={() => <MenuBar title="Apartment Advisor"><ApartmentAdvisorList /></MenuBar>} key="/apartment-advisor" />} /> */}

              {/* Listing Inventory */}
              <Route path="/listing-inventory" element={<PrivateRoute component={() => <MenuBar title="Listing Inventory"><ListingInventoryList /></MenuBar>} key="/listing-inventory" />} />
              <Route path="/unit-list" element={<PrivateRoute component={() => <MenuBar title="List Unit"><UnitList /></MenuBar>} key="/unit-list" />} />
              {/* <Route path="/unit-list/:id" element={() => <PrivateRoute component={() => <MenuBar title="Detail Listing Inventory"><DetailListingInventory /></MenuBar>} key="/"} /> */}

              {/* Demand Supply */}
              <Route path="/demand-supply" element={<PrivateRoute component={() => <MenuBar title="Demand Supply"><DemandSupply /></MenuBar>} key="/demand-supply" />} />
              <Route path="/demand-supply-tv" element={<PrivateRoute component={() => <DemandSupplyTV />} key="/demand-supply-tv" />} />

              {/* Handover Unit */}
              <Route path="/handover-unit" element={<PrivateRoute component={() => <MenuBar title="Handover Unit"><HandoverUnitList /></MenuBar>} key="/handover-unit" />} />

              {/* Manage Unit */}
              <Route path="/unit-manage" element={<PrivateRoute component={() => <MenuBar title="Manage Unit"><ManageUnit /></MenuBar>} key="/unit-manage" />} />

              {/* Manage Role */}
              <Route path="/role" element={<PrivateRoute component={() => <MenuBar title="Manage Role"><RoleList /></MenuBar>} key="/role" />} />

              {/* Manage User */}
              <Route path="/user-list" element={<PrivateRoute component={() => <MenuBar title="Manage User"><UserList /></MenuBar>} key="/user-list" />} />
              <Route path="/user-list/:user_id" element={<PrivateRoute component={() => <MenuBar title="User Detail"><UserList /></MenuBar>} key="/user-list/:user_id" />} />
              <Route path="/user/add" element={<PrivateRoute component={() => <MenuBar title="Add User"><UserList /></MenuBar>} key="/user/add" />} />

              {/* Manage Rent */}
              <Route path="/listing-submission" element={<PrivateRoute component={() => <MenuBar title="Listing Submission"><RentList /></MenuBar>} key="/listing-submission" />} />
              <Route path="/listing-submission/:rent_id" element={<PrivateRoute component={() => <MenuBar title="Listing Submission"><RentList /></MenuBar>} key="/listing-submission/:rent_id" />} />
              <Route path="/listing-submission/edit/:rent_id" element={<PrivateRoute component={() => <MenuBar title="Listing Submission - Edit"><EditListingSubmission /></MenuBar>} key="/listing-submission/edit/:rend_id" />} />

              {/* Listing */}
              <Route path="/listing/list" element={<PrivateRoute component={() => <MenuBar title="Listing"><ListingList /></MenuBar>} key="/" />} />
              <Route path="/listing/add" element={<PrivateRoute component={() => <ListingForm />} key="/" />} />
              <Route path="/listing/preview-edit/:id" element={<PrivateRoute component={() => <MenuBar title="Edit Listing"><ListingPreviewEdit /></MenuBar>} key="/" />} />

              {/* Listing for Admin */}
              <Route path="/listingAdmin/list" element={<PrivateRoute component={() => <MenuBar title="Other Listing"><ListingListAdmin /></MenuBar>} key="/" />} />
              <Route path="/listingAdmin/list/:listing_id" element={<PrivateRoute component={() => <MenuBar title="Other Listing"><ListingListAdmin /></MenuBar>} key="/" />} />
              <Route path="/listingAdmin/add" element={<PrivateRoute component={() => <ListingFormAdmin />} key="/" />} />
              <Route path="/listingAdmin/edit/:id" element={<PrivateRoute component={() => <ListingFormAdmin />} key="/" />} />
              <Route path="/listingAdmin/edit/:id/:type" element={<PrivateRoute component={() => <ListingFormAdmin />} key="/" />} />

              {/* Landing Other Listing */}
              <Route path="/landing-listing-page" element={<PrivateRoute component={() => <MenuBar title="Listing Page"><LandingOtherListingForm /></MenuBar>} key="/" />} />

              {/* Listing Location Article */}
              <Route path="/listing-location-article" element={<PrivateRoute component={() => <MenuBar title="Listing Page"><ListingLocationArticleForm /></MenuBar>} key="/" />} />

              {/* Add Artcile Landing Page */}
              <Route path="/article-landing-page" element={<PrivateRoute component={() => <MenuBar title="Article Landing Page"><ArticleLandingPage /></MenuBar>} key="/" />} />
              <Route path="/article-landing-page/add" element={<PrivateRoute component={() => <MenuBar title="Add Article Landing Page"><ArticleLandingPageForm /></MenuBar>} key="/" />} />
              <Route path="/article-landing-page/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Edit Article Landing Page"><ArticleLandingPageForm /></MenuBar>} key="/" />} />
              <Route path="/article-landing-page/edit/:id/:type" element={<PrivateRoute component={() => <MenuBar title="Edit Article Landing Page"><ArticleLandingPageForm /></MenuBar>} key="/" />} />

              {/* Ticketing System */}
              <Route path="/ticket/list" element={<PrivateRoute component={() => <MenuBar title="Ticket List"><TicketSystemList /></MenuBar>} key="/" />} />
              <Route path="/ticket/list/:ticket_id" element={<PrivateRoute component={() => <MenuBar title="Ticket"><TicketSystemList /></MenuBar>} key="/" />} />
              <Route path="/ticket/edit/:ticket_id" element={<PrivateRoute component={() => <MenuBar title="Ticket"><TicketSystemForm /></MenuBar>} key="/" />} />
              <Route path="/ticket/add" element={<PrivateRoute component={() => <MenuBar title="Add Ticket"><TicketSystemForm /></MenuBar>} key="/" />} />
              <Route path="/ticket/add/:closing_code" element={<PrivateRoute component={() => <MenuBar title="Add Ticket"><TicketSystemForm /></MenuBar>} key="/" />} />

              {/* Manage Keys */}
              <Route path="/manage-keys" element={<PrivateRoute component={() => <DashboardKunciCS />} key="/" />} />

              {/* Agent Office */}
              <Route path="/agent-office" element={<PrivateRoute component={() => <MenuBar title="Agent Office"><AgentOfficeList /></MenuBar>} key="/" />} />
              <Route path="/agent-office/:office_id" element={<PrivateRoute component={() => <MenuBar title="Agent Office"><AgentOfficeList /></MenuBar>} key="/" />} />

              {/* Agent */}
              <Route path="/agent" element={<PrivateRoute component={() => <MenuBar title="Agent"><AgentList /></MenuBar>} key="/" />} />
              <Route path="/agent/add" element={<PrivateRoute component={() => <MenuBar title="Agent Form"><AgentForm /></MenuBar>} key="/" />} />
              <Route path="/agent/edit/:agent_id" element={<PrivateRoute component={() => <MenuBar title="Agent Form"><AgentForm /></MenuBar>} key="/" />} />
              <Route path="/agent/:agent_id" element={<PrivateRoute component={() => <MenuBar title="Agent"><AgentList /></MenuBar>} key="/" />} />
              <Route path="/agent-list" element={<PrivateRoute component={() => <MenuBar title="Agent List"><AgentListTwo /></MenuBar>} key="/agent-list" />} />
              <Route path="/agent-list/:agent_id" element={<PrivateRoute component={() => <MenuBar title="Agent List"><AgentListTwo /></MenuBar>} key="/agent-list/:agent_id" />} />

              {/* Activity */}
              <Route path="/activity/add" element={<PrivateRoute component={() => <MenuBar title="Activity"><ActivityForm /></MenuBar>} key="/" />} />
              <Route path="/activity/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Activity"><ActivityForm /></MenuBar>} key="/" />} />
              <Route path="/activity/:id" element={<PrivateRoute component={() => <MenuBar title="Activity"><ActivityList /></MenuBar>} key="/" />} />
              <Route path="/activity" element={<PrivateRoute component={() => <MenuBar title="Activity"><ActivityList /></MenuBar>} key="/" />} />
              <Route path="/activity-showing" element={<PrivateRoute component={() => <MenuBar title="Activity Showing"><ActivityShowingList /></MenuBar>} key="/" />} />
              <Route path="/activity-showing/:id" element={<PrivateRoute component={() => <MenuBar title="Activity Showing"><ActivityShowingList /></MenuBar>} key="/" />} />
              <Route path="/activity/:code/inventory" element={<PrivateRoute component={() => <InventoryRoomModal />} key="/activity/:id/inventory" />} />
              <Route path="/activity/add/mobile" element={<PrivateRoute component={() => <MenuBar title="Activity Add Mobile"><ActivityFormMobile /></MenuBar>} key="/" />} />

              {/* Landlord Clean Data Form */}
              <Route path="/landlord/clean-data" element={<PrivateRoute component={function () { return <LandlordCleanDataForm /> }} key={"/"} />} />

              {/* Landlord */}
              <Route path="/landlord/" element={<PrivateRoute component={function () { return <MenuBar title="Landlord"><LandlordList /></MenuBar> }} key={"/"} />} />
              <Route path="/landlord/add" element={<PrivateRoute component={function () { return <MenuBar title="Landlord Form"><LandlordForm /></MenuBar> }} key={"/"} />} />
              <Route path="/landlord/edit/:landlord_id" element={<PrivateRoute component={function () { return <MenuBar title="Landlord Form"><LandlordForm /></MenuBar> }} key={"/"} />} />
              <Route path="/landlord/:landlord_id" element={<PrivateRoute component={function () { return <MenuBar title="Landlord"><LandlordList /></MenuBar> }} key={"/"} />} />


              {/* Customer */}
              <Route path="/customer" element={<PrivateRoute component={() => <MenuBar title="Customer"><CustomerList /></MenuBar>} key="/" />} />
              <Route path="/customer/add" element={<PrivateRoute component={() => <MenuBar title="Customer Form"><CustomerForm /></MenuBar>} key="/" />} />
              <Route path="/customer/edit/:customer_id" element={<PrivateRoute component={() => <MenuBar title="Customer Form"><CustomerForm /></MenuBar>} key="/" />} />
              <Route path="/customer/:customer_id" element={<PrivateRoute component={() => <MenuBar title="Customer"><CustomerList /></MenuBar>} key="/" />} />

              {/* Manage Extend */}
              <Route path="/manage-extend" element={<PrivateRoute component={() => <MenuBar title="Manage Extend"><ManageExtendList /></MenuBar>} key="/manage-extend" />} />
              <Route path="/manage-extend/:id" element={<PrivateRoute component={() => <MenuBar title="Manage Extend"><ClosingForm /></MenuBar>} key="/manage-extend/:id" />} />

              {/* Finance Request List */}
              <Route path="/finance-request" element={<PrivateRoute component={() => <MenuBar title="Finance Request List"><FinanceRequestList></FinanceRequestList></MenuBar>} key="/finance-request" />} />

              {/* Closing */}
              <Route path="/closing/list-termin" element={<PrivateRoute component={() => <MenuBar title="Closing"><ClosingForm /></MenuBar>} key="/closing/add" />} />
              <Route path="/closing/add" element={<PrivateRoute component={() => <MenuBar title="Closing"><ClosingForm /></MenuBar>} key="/closing/add" />} />
              <Route path="/closing/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Closing"><ClosingForm /></MenuBar>} key="/closing/edit/:id" />} />
              <Route path="/closing/chart" element={<PrivateRoute component={() => <MenuBar title="Closing Chart"><ClosingChart /></MenuBar>} key="/closing/chart" />} />
              <Route path="/closing/chart-monthly" element={<PrivateRoute component={() => <MenuBar title="Closing Chart Monthly"><ClosingChart monthly /></MenuBar>} key="/closing/chart-monthly" />} />
              {/* <PrivateRoute exact path="/closing/:code/inventory" component={function () { return <ClosingListInventory /> }} key="/closing/:code/inventory" /> */}
              <Route path="/closing/:code" element={<PrivateRoute component={() => <MenuBar title="Closing"><ClosingList /></MenuBar>} key="/closing/:code" />} />
              <Route path="/closing/" element={<PrivateRoute component={() => <MenuBar title="Closing"><ClosingList /></MenuBar>} key="/closing/:code" />} />
              <Route path="/closing-wings/add" element={<PrivateRoute component={() => <MenuBar title="Closing Referral"><ClosingReferralForm type="wings" /></MenuBar>} key="/closing-wings/add" />} />
              {/* <PrivateRoute exact path="/closing/:code/inventory" component={function () { return <InventoryRoomModal /> }} key="/closing/:code/inventory" /> */}

              {/* Unit */}
              <Route path="/unit/add" element={<PrivateRoute component={() => <MenuBar title="Unit Form"><UnitForm /></MenuBar>} key="/unit/add" />} />
              <Route path="/unit/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Unit Form"><UnitForm /></MenuBar>} key="/unit/edit/:id/:type?" />} />
              <Route path="/unit/:id" element={<PrivateRoute component={() => <MenuBar title="Unit Detail"><ListUnit /></MenuBar>} key="/unit:/id" />} />
              <Route path="/unit" element={<PrivateRoute component={() => <MenuBar title="Unit List"><ListUnit /></MenuBar>} key="/unit" />} />
              <Route path="/unit-special" element={<PrivateRoute component={() => <MenuBar title="Unit Special"><UnitSpecialList /></MenuBar>} key="/unit-special" />} />
              {/* Rent Submission Chart */}
              <Route path="/listing-submission-chart" element={<PrivateRoute component={() => <MenuBar title="Rent Submission Chart"><RentSubmissionChart /></MenuBar>} key="/listing-submission-chart" />} />
              {/* Project Developer */}
              <Route path="/developer/project/:project_id" element={<PrivateRoute component={() => <ProjectDeveloperModalDetail />} key="/developer/project/:project_id" />} />
              <Route path="/developer/:project_id/property/add" element={<PrivateRoute component={() => <MenuBar title="Project Developer Form"><PropertyForm /></MenuBar>} key="/developer/:project_id/property/add" />} />
              <Route path="/developer/:project_id/property/edit/:property_id" element={<PrivateRoute component={() => <MenuBar title="Project Developer Form"><PropertyForm /></MenuBar>} key="/developer/:project_id/property/edit/:property_id" />} />
              <Route path="/developer/:project_id/property/edit/:property_id/:type?" element={<PrivateRoute component={() => <MenuBar title="Project Developer Form"><PropertyForm /></MenuBar>} key="/developer/:project_id/property/edit/:property_id/:type?" />} />

              <Route path="/developer/category/:project_id" element={<PrivateRoute component={() => <MenuBar title="Project Developer Category"><ProjectDeveloperCategoryList /></MenuBar>} key="/developer/category/:project_id" />} />

              <Route path="/project-developer" element={<PrivateRoute component={() => <MenuBar title="Project Developer"><ProjectDeveloperList /></MenuBar>} key="/project-developer" />} />
              <Route path="/project-developer/add" element={<PrivateRoute component={() => <MenuBar title="Project Developer Form"><ProjectDeveloperForm /></MenuBar>} key="/project-developer/add" />} />
              <Route path="/project-developer/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Project Developer Form"><ProjectDeveloperForm /></MenuBar>} key="/project-developer/edit/:id/:type?" />} />

              {/* Credit Memo */}
              <Route path="/credit-memo" element={<PrivateRoute component={() => <MenuBar title="Credit Memo List"><CreditMemoList /></MenuBar>} key="/credit-memo" />} />
              <Route path="/credit-memo/add" element={<PrivateRoute component={() => <MenuBar title="Credit Memo Form"><CreditMemoForm /></MenuBar>} key="/credit-memo/add" />} />

              {/* Advisor Enquiry */}
              <Route path="/my-enquiry" element={<PrivateRoute component={() => <MenuBar title="My Enquiry List" searchable search_placeholder="" component_name="AdvisorEnquiryList" />} key="/" />} />
              <Route path="/my-enquiry/:code" element={<PrivateRoute component={() => <MenuBar title="My Enquiry List" searchable search_placeholder="" component_name="AdvisorEnquiryList" />} key="/" />} />

              {/* Dashboard Unit Advisor */}
              {/* <PrivateRoute exact path="/my-unit" component={function () { return <MenuBar title="My Unit" searchable search_placeholder="" component_name="MyUnitList" /> }} key={"/"} /> */}

              {/* Unit Key Manage */}
              <Route path="/unit-key-manage" element={<PrivateRoute component={() => <MenuBar title="Unit Key Manage"><UnitKeyManageList /></MenuBar>} key="/" />} />
              <Route path="/unit-key-manage/borrow" element={<PrivateRoute component={() => <MenuBar title="Borrow Key"><UnitKeyBorrowForm /></MenuBar>} key="/" />} />
              <Route path="/unit-key-manage/history" element={<PrivateRoute component={() => <MenuBar title="History Key"><UnitKeyHistoryList /></MenuBar>} key="/" />} />
              <Route path="/unit-key-manage/request" element={<PrivateRoute component={() => <MenuBar title="Request Transfer"><UnitKeyRequest /></MenuBar>} key="/" />} />

              {/* Unit Key Admin */}
              <Route path="/unit-key-logs" element={<PrivateRoute component={() => <MenuBar title="Unit Key Logs"><UnitKeyLogsList /></MenuBar>} key="/unit-key-logs" />} />
              <Route path="/unit-key/borrow-request" element={<PrivateRoute component={() => <MenuBar title="Unit Key Logs"><UnitKeyBorrowRequest /></MenuBar>} key="/unit-key-borrow" />} />

              {/* Advisor Unit Portofolio */}
              <Route path="/assigned-unit-list/:id" element={<PrivateRoute component={() => <MenuBar title="Advisor Captain Portofolio"><DashboardUnitAdvisorCaptain /></MenuBar>} key="/assigned-unit-list:/id" />} />
              <Route path="/assigned-unit-list" element={<PrivateRoute component={() => <MenuBar title="Advisor Captain Portofolio"><DashboardUnitAdvisorCaptain /></MenuBar>} key="/assigned-unit-list" />} />

              {/* Unit Advisor */}
              <Route path="/unit-search-agent" element={<PrivateRoute component={() => <MenuBar title="Unit Advisor List"><UnitAdvisorList /></MenuBar>} key="/" />} />

              {/* Showing Manage */}
              <Route path="/manage-showing" element={<PrivateRoute component={() => <MenuBar title="Manage Showing"><ManageShowing /></MenuBar>} key="/" />} />

              {/* Career */}
              <Route path="/career" element={<PrivateRoute component={() => <MenuBar title="Career"><CareerList /></MenuBar>} key="/career" />} />
              <Route path="/career/add" element={<PrivateRoute component={() => <MenuBar title="Career"><CareerForm /></MenuBar>} key="/career/add" />} />
              <Route path="/career/:career_id" element={<PrivateRoute component={() => <MenuBar title="Career"><CareerForm /></MenuBar>} key="/career/:career_id" />} />

              {/* Enquiry Sell Placement */}
              <Route path="/enquiry-sell-placement" element={<PrivateRoute component={() => <MenuBar title="Enquiry Sell Placement"><EnquirySellPlacement /></MenuBar>} key="/enquiry-sell-placement" />} />

              {/* Stay 360 */}
              <Route path="/calendar-booking" element={<PrivateRoute component={() => <MenuBar title="Calendar Booking"><Stay360Booking /></MenuBar>} key="/calendar-booking" />} />
              <Route path="/stay360-property" element={<PrivateRoute component={() => <MenuBar title="Stay360 Property"><Stay360Property /></MenuBar>} key="/stay360-property" />} />
              <Route path="/stay360-unit" element={<PrivateRoute component={() => <MenuBar title="Stay360 Unit"><Stay360Unit /></MenuBar>} key="/stay360-unit" />} />
              <Route path="/stay360-booking/report" element={<PrivateRoute component={() => <MenuBar title="Stay360 Booking Report"><Stay360BookingReport /></MenuBar>} key="/stay360-booking/report" />} />
              <Route path="/stay360-booking/add" element={<PrivateRoute component={() => <MenuBar title="Stay360 Booking Form"><Stay360BookingForm /></MenuBar>} key="/stay360-booking/add" />} />
              <Route path="/stay360-booking/:id" element={<PrivateRoute component={() => <Stay360BookingDetailModal />} key="/stay360-booking/:id" />} />
              <Route path="/stay360-booking/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Stay360 Booking Form"><Stay360BookingForm /></MenuBar>} key="/stay360-booking/edit/:id" />} />
              <Route path="/stay360-cico" element={<PrivateRoute component={() => <MenuBar title="Stay360 CICO"><Stay360PmsHome /></MenuBar>} key="/stay360-cico" />} />
              <Route path="/stay360-cico/list" element={<PrivateRoute component={() => <MenuBar title="Stay360 CICO"><Stay360PmsList /></MenuBar>} key="/stay360-cico/list" />} />
              <Route path="/stay360-cico/list/:is_apartment/:location_id/:label" element={<PrivateRoute component={() => <MenuBar title="Stay360 CICO"><Stay360PmsList /></MenuBar>} key="/" />} />

              {/* Transport Reimbursement */}
              <Route path="/transport-reimbursement" element={<PrivateRoute component={() => <MenuBar title="Transport Reimbursement"><TransportReimbursementList /></MenuBar>} key="/transport-reimbursement" />} />

              {/* Reimbursement Recap Daily */}
              <Route path="/reimbursement-recap-daily" element={<PrivateRoute component={() => <MenuBar title="Reimbursement Recap Daily"><ReimbursementRecapDailyList /></MenuBar>} key="/reimbursement-recap-daily" />} />

              {/* Profile Setting */}
              <Route path="/profile-setting" element={<PrivateRoute component={() => <MenuBar title="Profile Setting"><ProfileSetting /></MenuBar>} key="/" />} />

              {/* Blast Template */}
              <Route path="/campaign-email" element={<PrivateRoute component={() => <MenuBar title="Blast Template"><BlastTemplateList /></MenuBar>} key="/blast-template" />} />

              {/* Log Login */}
              <Route path="/login-log" element={<PrivateRoute component={() => <MenuBar title="Log Login"><LoginLogList /></MenuBar>} key="/login-log" />} />

              {/* Province */}
              <Route path="/province" element={<PrivateRoute component={() => <MenuBar title="Province"><ProvinceList /></MenuBar>} key="/province" />} />
              <Route path="/province/:province_id" element={<PrivateRoute component={() => <MenuBar title="Province"><ProvinceList /></MenuBar>} key="/province/:province_id" />} />

              {/* District */}
              <Route path="/district/add" element={<PrivateRoute component={() => <MenuBar title="District"><DistrictForm /></MenuBar>} key="/district" />} />
              <Route path="/district" element={<PrivateRoute component={() => <MenuBar title="District"><DistrictList /></MenuBar>} key="/district" />} />
              <Route path="/district/:district_id" element={<PrivateRoute component={() => <MenuBar title="District"><DistrictForm /></MenuBar>} key="/district/:district_id" />} />

              {/* Unit Key Logs */}
              <Route path="/unit-key-logs" element={<PrivateRoute component={() => <MenuBar title="Unit Key Logs"><UnitKeyLogsList /></MenuBar>} key="/unit-key-logs" />} />

              {/* Unit Download Image */}
              <Route path="/unit-download-image" element={<PrivateRoute component={() => <MenuBar title="Download Unit Image"><UnitDownloadImage /></MenuBar>} key="/unit-download-image" />} />

              {/* Apartment Download Image */}
              <Route path="/apartment-download-image" element={<PrivateRoute component={() => <MenuBar title="Download Apartment Image"><ApartmentDownloadImage /></MenuBar>} key="/apartment-download-image" />} />

              {/* Unit Marketplace Log */}
              <Route path="/unit-marketplace-log" element={<PrivateRoute component={() => <MenuBar title="Unit Marketplace Log"><UnitMarketplaceLogList /></MenuBar>} key="/unit-marketplace-log" />} />

              {/* Apartment Advisor */}
              {/* <PrivateRoute exact path="/apartment-advisor" component={function () { return <MenuBar title="Apartment Advisor"><ApartmentAdvisorList /></MenuBar> }} key="/apartment-advisor" /> */}

              {/* Customer */}
              <Route path="/customer/" element={<PrivateRoute component={function () { return <MenuBar title="Customer"><CustomerList /></MenuBar> }} key={"/"} />} />
              <Route path="/customer/add" element={<PrivateRoute component={function () { return <MenuBar title="Customer Form"><CustomerForm /></MenuBar> }} key={"/"} />} />
              <Route path="/customer/edit/:customer_id" element={<PrivateRoute component={function () { return <MenuBar title="Customer Form"><CustomerForm /></MenuBar> }} key={"/"} />} />
              <Route path="/customer/:customer_id" element={<PrivateRoute component={function () { return <MenuBar title="Customer"><CustomerList /></MenuBar> }} key={"/"} />} />

              {/* Clean Scrap Form */}
              <Route path="/clean-scrap-form" element={<PrivateRoute component={() => <MenuBar title="Clean Scrap Form"><CleanScrapForm /></MenuBar>} key="/clean-scrap-form" />} />

              {/* Advisor Member */}
              {/* <Route path="/advisor-manage-member" element={<PrivateRoute component={() => <MenuBar title="Advisor Member"><AdvisorMemberList /></MenuBar>} key="/advisor-manage-member" />} /> */}

              {/* Advisor Group */}
              <Route path="/advisor-group" element={<PrivateRoute component={() => <MenuBar title="Advisor Group"><AdvisorGroupList /></MenuBar>} key="/advisor-group" />} />

              {/* Area */}
              <Route path="/area" element={<PrivateRoute component={() => <MenuBar title="Area List"><AreaList /></MenuBar>} key="/area" />} />
              <Route path="/area/add" element={<PrivateRoute component={() => <MenuBar title="Area Form"><AreaForm /></MenuBar>} key="/area/add" />} />
              <Route path="/area/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Area Form"><AreaForm /></MenuBar>} key="/area/edit/:id" />} />

              {/* Pay Simulator */}
              <Route path="/pay-simulator" element={<PrivateRoute component={() => <MenuBar title="Pay Simulator"><PaySimulator /></MenuBar>} key="/pay-simulator" />} />

              {/* Map File Form */}
              <Route path="/map-file-form" element={<PrivateRoute component={() => <MenuBar title="Map File"><MapFileForm /></MenuBar>} key="/map-file-form" />} />

              {/* Asset Upload */}
              <Route path="/asset-upload" element={<PrivateRoute component={() => <MenuBar title="Map File"><AssetUploadList /></MenuBar>} key="/asset-upload" />} />

              {/* Entrusted Key Report */}
              <Route path="/entrusted-key-report/:id" element={<PrivateRoute component={() => <MenuBar title="Unit Detail"><EntrustedKeyReport /></MenuBar>} key="/entrusted-key-report/:id" />} />
              <Route path="/entrusted-key-report" element={<PrivateRoute component={() => <MenuBar title="Entrusted Key Report"><EntrustedKeyReport /></MenuBar>} key="/entrusted-key-report" />} />

              {/* Dashboard PM Lead */}
              <Route path="/dashboard-pm" element={<PrivateRoute component={() => <DashboardPM />} key="/dashboard-pm" />} />
              <Route path="/dashboard-pm/:id" element={<PrivateRoute component={() => <DashboardPM />} key="/dashboard-pm-id" />} />
              <Route path="/dashboard-pm-lead" element={<PrivateRoute component={() => <DashboardPMLead />} key="/dashboard-pm-lead" />} />

              {/* Advisor Performance */}
              {/* <Route path="/advisor-performance" element={<PrivateRoute component={() => <MenuBar title="Advisor Performance"><AdvisorPerformance /></MenuBar>} key="/advisor-performance" />} /> */}

              {/* Account */}
              <Route path="/account" element={<PrivateRoute component={() => <MenuBar title="Account"><AccountList /></MenuBar>} key="/account" />} />
              <Route path="/account/:account_id" element={<PrivateRoute component={() => <MenuBar title="Account"><AccountList /></MenuBar>} key="/account" />} />

              {/* Booking */}
              <Route path="/booking" element={<PrivateRoute component={() => <MenuBar title="Booking"><BookingList /></MenuBar>} key="/booking" />} />
              <Route path="/booking/:id" element={<PrivateRoute component={() => <MenuBar title="Booking Detail"><BookingList /></MenuBar>} key="/booking/:id" />} />
              <Route path="/booking/edit/:id" element={<PrivateRoute component={() => <MenuBar title="Edit Booking"><BookingForm /></MenuBar>} key="/booking/:id" />} />

              {/* Holiday */}
              <Route path="/holiday" element={<PrivateRoute component={() => <MenuBar title="Holiday"><HolidayList /></MenuBar>} key="/holiday" />} />

              {/* Bank Verifier */}
              <Route path="/bank-name-verifier" element={<PrivateRoute component={() => <MenuBar title="Bank Name Verifier"><BankVerifierForm /></MenuBar>} key="/bank-name-verifier" />} />

              {/* Download Report Support Supervisor */}
              <Route path="/download-report-support-supervisor" element={<PrivateRoute component={function () { return <MenuBar title="Download Report TR"><DownloadSupportSupervisor /></MenuBar> }} key="/download-support-supervisor" />} />

              {/* Download Report TR */}
              <Route path="/download-report-tr" element={<PrivateRoute component={() => <MenuBar title="Download Report TR"><DownloadReportTR /></MenuBar>} key="/download-report-tr" />} />

              {/* Download Report General */}
              <Route path="/download-report" element={<PrivateRoute component={() => <MenuBar title="Download Report General"><DownloadReportGeneral /></MenuBar>} key="/download-report-general" />} />

              {/* Quality Control Unit List */}
              <Route path="/qc-unit-list" element={<PrivateRoute component={() => <MenuBar title="QC Unit List"><QualityControlUnitList /></MenuBar>} key="/qc-unit-list" />} />
              <Route path="/qc-unit-list/inventory/:code" element={<PrivateRoute component={() => <InventoryRoomModal />} key="/qc-unit-list/inventory/:code" />} />

              {/* Bank Others */}
              <Route path="/bank-others" element={<PrivateRoute component={() => <MenuBar title="Bank Others"><BankOthersList /></MenuBar>} key="/bank-others" />} />
              <Route path="/bank-others/add" element={<PrivateRoute component={() => <MenuBar title="Bank Others Form"><BankOthersForm /></MenuBar>} key="/bank-others/add" />} />
              <Route path="/bank-others/edit/:bank_id" element={<PrivateRoute component={() => <MenuBar title="Bank Others Form"><BankOthersForm /></MenuBar>} key="/bank-others/edit" />} />

              {/* Deposit Stuff */}
              <Route path="/statement-deposit" element={<PrivateRoute component={() => <MenuBar title="Statement List"><StatementList isDeposit={true} /></MenuBar>} key="/statement" />} />
              <Route path="/verify-transaction-deposit/:id" element={<PrivateRoute component={() => <MenuBar title="Verify Transaction List"><VerifyTransactionList isDeposit={true} /></MenuBar>} key="/verify-closing" />} />
              <Route path="/verify-transaction-deposit" element={<PrivateRoute component={() => <MenuBar title="Verify Transaction List"><VerifyTransactionList isDeposit={true} /></MenuBar>} key="/verify-closing" />} />
              <Route path="/closing-transaction-export-deposit" element={<PrivateRoute component={() => <MenuBar title="Closing Transaction Export"><ClosingTransactionExport isDeposit={true} /></MenuBar>} key="/closing-transaction-export" />} />

              {/* Escrow Stuff */}
              <Route path="/statement" element={<PrivateRoute component={() => <MenuBar title="Statement List"><StatementList /></MenuBar>} key="/statement" />} />
              <Route path="/verify-transaction/:id" element={<PrivateRoute component={() => <MenuBar title="Verify Transaction List"><VerifyTransactionList /></MenuBar>} key="/verify-closing" />} />
              <Route path="/verify-transaction" element={<PrivateRoute component={() => <MenuBar title="Verify Transaction List"><VerifyTransactionList /></MenuBar>} key="/verify-closing" />} />
              <Route path="/closing-transaction-export" element={<PrivateRoute component={() => <MenuBar title="Closing Transaction Export"><ClosingTransactionExport /></MenuBar>} key="/closing-transaction-export" />} />

              {/* Top Apartment Closing */}
              <Route path="/top-apartment-closing" element={<PrivateRoute component={() => <MenuBar title="Top Apartment Closing"><TopApartmentClosing /></MenuBar>} key="/top-apartment-closing" />} />

              {/* JHS Order */}
              <Route path="/jhs-order/add" element={<PrivateRoute component={() => <MenuBar title="JHS Order Form"><JhsOrderForm /></MenuBar>} key="/top-apartment-closing" />} />
              <Route path="/jhs-order" element={<PrivateRoute component={() => <MenuBar title="JHS Order List"><JhsOrderList /></MenuBar>} key="/jhs-order" />} />
              <Route path="/jhs-order/:id" element={<PrivateRoute component={() => <MenuBar title="JHS Order List"><JhsOrderModalDetail /></MenuBar>} key="/jhs-order" />} />
              <Route path="/jhs-order/edit/:id" element={<PrivateRoute component={() => <MenuBar title="JHS Order Form"><JhsOrderForm /></MenuBar>} key="/top-apartment-closing" />} />

              {/* Billing Collection */}
              <Route path="/billing-collection" element={<PrivateRoute component={() => <MenuBar title="Billing Collection"><BillingCollectionList /></MenuBar>} key="/top-apartment-closing" />} />

              {/* PIC Landlord */}
              <Route path="/pic-landlord" element={<PrivateRoute component={() => <MenuBar title="PIC Landlord List"><PICList /></MenuBar>} key="/pic-landlord" />} />
              <Route path="/pic-landlord/add" element={<PrivateRoute component={() => <MenuBar title="PIC Landlord"><PICForm /></MenuBar>} key="/pic-landlord" />} />
              <Route path="/pic-landlord/edit/:id" element={<PrivateRoute component={() => <MenuBar title="PIC Landlord"><PICForm /></MenuBar>} key="/pic-landlord" />} />
              <Route path="/pic-landlord/:id" element={<PrivateRoute component={() => <MenuBar title="PIC Landlord List"><PICList /></MenuBar>} key="/pic-landlord" />} />

              {/* FA Team Schedule */}
              <Route path="/activity-schedule" element={<PrivateRoute component={() => <MenuBar title="FA Schedule"><ActivitySchedule /></MenuBar>} key="/fa-schedule" />} />

              {/* Bulk Broadcast */}
              <Route path="/broadcast-qiscus" element={<PrivateRoute component={() => <MenuBar title="Broadcast Qiscus"><BroadcastQiscus /></MenuBar>} key="/broadcast-qiscus" />} />

              {/* Priority Unit Homepage */}
              <Route path="/homepage-sl" element={<PrivateRoute component={() => <MenuBar title="Homepage SL Order"><PriorityUnitHomepageList /></MenuBar>} key="/homepage-sl" />} />

              {/* Bank Vendor */}
              <Route path="/bank-vendor" element={<PrivateRoute component={() => <MenuBar title="Bank Vendor"><BankVendorList /></MenuBar>} key="/bank-vendor" />} />

              {/* Manage Rent */}
              <Route path="/listing-submission-apartment" element={<PrivateRoute component={() => <MenuBar title="Listing Submission Apartment"><ListingSubmissionApartmentList /></MenuBar>} key="/listing-submission" />} />
              <Route path="/listing-submission-apartment/:submission_code" element={<PrivateRoute component={() => <MenuBar title="Listing Submission Apartment"><ListingSubmissionApartmentList /></MenuBar>} key="/listing-submission/:rent_id" />} />
              <Route path="/listing-submission-apartment/edit/:id/:type?" element={<PrivateRoute component={() => <MenuBar title="Listing Submission Apartment - Edit"><UnitForm isListingSubmissionApartment /></MenuBar>} key="/listing-submission/edit/:rend_id" />} />

              {/* Customer Merge */}
              <Route path="/customer-merge" element={<PrivateRoute component={() => <MenuBar title="Merge Tenant Data"><CustomerMerge /></MenuBar>} key="/customer-merge" />} />

              {/* Target Scoring / My Performance */}
              <Route path="/my-performance" element={<PrivateRoute component={() => <TargetScoringDashboard />} key="/my-performance" />} />

              {/* Performance List (Captain) / Advisor Performance New */}
              <Route path="/advisor-performance" element={<PrivateRoute component={() => <DashboardPMCaptain />} key="/advisor-performance" />} />
              <Route path="/advisor-performance/:id" element={<PrivateRoute component={() => <TargetScoringDashboard />} key="/advisor-performance-detail" />} />

              {/* Unit Requested Delete */}
              <Route path="/unit-delete" element={<PrivateRoute component={() => <MenuBar title="Unit Requested Delete List" ><UnitDeleteList /></MenuBar>} key="/unit-delete" />} />

              {/* Product Knowledge */}
              <Route path="/product-knowledge" element={<PrivateRoute component={() => <MenuBar title="Product Knowledge"><ProductKnowledgeList /></MenuBar>} key="/product-knowledge" />} />

              {/* Funnel Report */}
              <Route path="/funnel-report" element={<PrivateRoute component={() => <MenuBar title="Funnel Report" ><FunnelReport /></MenuBar>} key="/funnel-report" />} />

              {/* Funnel Report Listing */}
              <Route path="/funnel-report-listing" element={<PrivateRoute component={() => <MenuBar title="Funnel Report Listing" ><FunnelReportListing /></MenuBar>} key="/funnel-report-listing" />} />

              {/* Enquiry Breakdown */}
              <Route path="/enquiry-breakdown" element={<PrivateRoute component={() => <MenuBar title="Enquiry Breakdown" ><EnquiryBreakdown /></MenuBar>} key="/enquiry-breakdown" />} />

              {/* JHS Schedule */}
              <Route path="/jhs-schedule" element={<PrivateRoute component={() => <MenuBar title="JHS Schedule" ><JHSSchedule /></MenuBar>} key="/jhs-schedule" />} />

              {/* Unit Management */}
              <Route path="/unit-management" element={<PrivateRoute component={function () { return <MenuBar title="Unit Management" ><UnitManagementList /></MenuBar> }} key={"/unit-management"} />} />

              {/* Telesales Area */}
              <Route path="/telesales-area" element={<PrivateRoute component={function () { return <MenuBar title="Telesales Area" ><TelesalesAreaList /></MenuBar> }} key={"/telesales-area"} />} />


              {/* Showing Schedule */}
              <Route path="/showing-schedule" element={<PrivateRoute component={function () { return <MenuBar title="Showing Schedule" ><ShowingSchedule /></MenuBar> }} key={"/showing-schedule"} />} />

              {/* Apartment Performance List */}
              <Route path="/apartment-performance" element={<PrivateRoute component={function () { return <MenuBar title="Apartment Performance" ><ApartmentPerformanceList /></MenuBar> }} key={"/apartment-performance"} />} />

              {/* Listing Ticket */}
              <Route path="/listing-ticket" element={<PrivateRoute component={function () { return <MenuBar title="Listing Ticket" ><ListingTicketList /></MenuBar> }} key={"/listing-ticket"} />} />

              {/* Whatsapp Chat Checker */}
              <Route path="/whatsapp-checker" element={<PrivateRoute component={function () { return <MenuBar title="Whatsapp Chat Checker" ><WhatsappChatChecker /></MenuBar> }} key={"/whatsapp-chat-checker"} />} />

              {/* Bulk Update Extend PIC */}
              <Route path="/bulk-update-pic" element={<PrivateRoute component={function () { return <MenuBar title="Bulk Update Extend PIC" ><BulkUpdateExtendPIC /></MenuBar> }} key={"/bulk-update-extend-pic"} />} />

              {/* Unit Sell */}
              <Route path="/unit-sell" element={<PrivateRoute component={function () { return <MenuBar title="Unit (Sell)" ><UnitSellList /></MenuBar> }} key={"/unit-sell"} />} />

              {/* Telesales Area */}
              <Route path="/sell-advisor-area" element={<PrivateRoute component={function () { return <MenuBar title="Sell Advisor Area" ><SellAdvisorAreaList /></MenuBar> }} key={"/sell-advisor-area"} />} />

              {/* General performance */}
              <Route path="/general-performance" element={<PrivateRoute component={function () { return <MenuBar title="General Performance" ><GeneralPerformance /></MenuBar> }} key={"/general-performance"} />} />

              {/* Unit Photo Checklist */}
              <Route path="/unit-photo-checklist" element={<PrivateRoute component={function () { return <MenuBar title="Unit Photo Checklist" ><UnitPhotoChecklist /></MenuBar> }} key={"/unit-photo-checklist"} />} />

              {/* Scan KTP */}
              <Route path="/scan-ktp" element={<PrivateRoute component={function () { return <MenuBar title="Scan KTP" ><ScanKTP /></MenuBar> }} key={"/scan-ktp"} />} />

              {/* Unit Review */}
              <Route path="/unit-review" element={<PrivateRoute component={function () { return <MenuBar title="Unit Review" ><UnitReviewList /></MenuBar> }} key={"/unit-review"} />} />

              {/* Available Apartment Unit*/}
              <Route path="/list-unit-available" element={<PrivateRoute component={function () { return <MenuBar title="List Unit Available" ><AvailableApartmentUnit /></MenuBar> }} key={"/list-unit-available"} />} />

              {/* List Entrusted Key */}
              <Route path="/list-entrusted-key" element={<PrivateRoute component={function () { return <MenuBar title="List Unit Titip Kunci" ><ListUnitEntrustedKey /></MenuBar> }} key={"/list-entrusted-key"} />} />

              {/* Lead Listing Facebook */}
              <Route path="/lead-listing-fb" element={<PrivateRoute component={function () { return <MenuBar title="Lead Listing FB Instant Form" ><LeadListingFacebook /></MenuBar> }} key={"/lead-listing-fb"} />} />

              {/* Ranking Agent/Advisor */}
              <Route path="/ranking-agent" element={<PrivateRoute component={function () { return <MenuBar title="Ranking Agent" ><TopSellerList /></MenuBar> }} key={"/ranking-agent"} />} />

              {/* Closing request */}
              <Route path="/closing-request" element={<PrivateRoute component={function () { return <MenuBar title="Closing Request" ><ClosingRequestList /></MenuBar> }} key={"/closing-request"} />} />
              <Route path="/closing-request/:id" element={<PrivateRoute component={function () { return <MenuBar title="Closing Request" ><ClosingRequestList /></MenuBar> }} key={"/closing-request/:id"} />} />
              <Route path="/closing-request/verify/:id" element={<PrivateRoute component={() => <MenuBar title="Closing Request"><ClosingRequestForm /></MenuBar>} key="/closing-request/verify/:id" />} />
              
              {/* Listing Closing Admin Flipper */}
              <Route path="/closing-sell" element={<PrivateRoute component={function () { return <MenuBar title="Closing (Sell)" ><ClosingSellList /></MenuBar> }} key={"/closing-sell"} />} />
              <Route path="/closing-sell/:code" element={<PrivateRoute component={() => <MenuBar title="Closing Sell"><ClosingSellList /></MenuBar>} key="/closing-sell/:code" />} />

              {/* Listing Submission Admin Flipper */}
              <Route path="/listing-submission-sell" element={<PrivateRoute component={function () { return <MenuBar title="Listing Submission (Sell)" ><ListingSubmissionSellList /></MenuBar> }} key={"/listing-submission-sell"} />} />
              <Route path="/listing-submission-sell/:submission_code" element={<PrivateRoute component={() => <MenuBar title="Listing Submission (Sell)"><ListingSubmissionSellList /></MenuBar>} key="/listing-submission-sell/:submission_code" />} />

               {/* Statement Assigner System */}
               <Route path="/statement-assigner" element={<PrivateRoute component={function () { return <MenuBar title="Statement Assigner" ><StatementAssigner /></MenuBar> }} key={"/statement-assigner"} />} />

              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes >
          </Router >
        </SnackbarProvider >
      </Root >
    </PermissionContext.Provider >
  )
}

export default App;