import React, { useState } from 'react';
import { Grid, Button, Typography, Theme, CardContent, Card, InputAdornment } from '@mui/material';
import { addDays } from 'date-fns';
import TextFieldND3 from '../../../_components/_formElements/_newDesign2/TextFieldND3';

/**
 * Interfaces
 */
import { useStyles } from '../ClosingForm';
import { numberToCurrency } from '../../../_utils/Helper';
import MuiDatePicker from '../../../_components/MuiDatePicker';
import { generateStyle } from '../../../_utils/DefaultStyle';
import { Add, DeleteOutline } from '@mui/icons-material';

interface IProps {
    defaultAmount?: string;
    defaultBasicPrice?: string;
    termins: ITermin[];
    endDate: Date | null;
    startFromOne?: boolean;
    hideNote?: boolean;
    setParentTerminState: Function;
    error?: boolean;
    helperText?: string;
    isClosingRequest?: boolean
}

export interface ITermin {
    start_date: Date;
    end_date: Date;
    amount: string;
    basic_price: string;
}

interface IErrorState {
    start_date: string;
    end_date: string;
    amount: string;
    basic_price: string;
}

const TerminForm = ({
    defaultAmount,
    defaultBasicPrice,
    termins,
    endDate,
    startFromOne,
    hideNote,
    error,
    helperText,
    setParentTerminState,
}: IProps) => {
    const { Root, classes } = useStyles();
    const { Root: LocalRoot, classes: localClasses } = useLocalStyles()
    const [errorState, setErrorState] = useState<IErrorState[]>([]);

    const handleAddTermin = () => {
        if (checkTerminValidation()) {
            let newDate;

            if (termins.length > 0) {
                const lastTermin = termins[termins.length - 1];
                newDate = addDays(lastTermin.end_date, 1);
                defaultAmount = lastTermin.amount
                defaultBasicPrice = lastTermin.basic_price
            } else {
                newDate = endDate !== null ? addDays(new Date(endDate), 1) : addDays(new Date(), 1);
            }

            const oldTermins = [...termins];
            oldTermins.push({
                start_date: newDate,
                end_date: addDays(newDate, 1),
                amount: defaultAmount ? numberToCurrency(defaultAmount) : '',
                basic_price: defaultBasicPrice ? numberToCurrency(defaultBasicPrice) : '',
                // duration: '',
            });
            setParentTerminState(oldTermins);
        }
    }

    const handleDeleteTermin = (key: number) => {
        const oldTermins = termins.slice();
        oldTermins.splice(key, 1);
        setParentTerminState(oldTermins);
    }

    const checkTerminValidation = () => {
        let thisTerminIsValid = true;
        const listofErrors: IErrorState[] = [];

        for (let index = 0; index < termins.length; index++) {
            const newError: IErrorState = {
                start_date: '',
                end_date: '',
                amount: '',
                basic_price: ''
            };

            if (termins[index].amount === "") {
                newError.amount = 'Amount wajib diisi';
                thisTerminIsValid = false;
            }

            if (termins[index].basic_price === "") {
                newError.basic_price = 'Basic Price wajib diisi';
                thisTerminIsValid = false;
            }
            listofErrors.push(newError);
        }

        setErrorState(listofErrors);
        return thisTerminIsValid;
    }

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
        const oldTermins = termins.slice();

        const attribute = event.target.name;
        const value = event.target.value;

        if (['basic_price', 'amount'].includes(attribute)) {
            switch (attribute) {
                case 'amount':
                    oldTermins[key].amount = numberToCurrency(value);
                    break;
                case 'basic_price':
                    oldTermins[key].basic_price = numberToCurrency(value);
                    break;
                default:
                    break;
            }
        }
        setParentTerminState(oldTermins);
    }

    const handleDateChanged = (date: Date | null, name: string, key: number) => {
        const oldTermins = termins.slice();
        if (name === 'start_date') {
            oldTermins[key].start_date = date!;
            oldTermins[key].end_date = addDays(date!, 1);
        } else {
            oldTermins[key].end_date = date!;
        }

        setParentTerminState(oldTermins);
    }

    return (
        <>
            {(!hideNote) &&
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <Typography variant="subtitle2">
                        Note: Closing yang akan disubmit akan dihitung sebagai termin pertama. Untuk termin kedua dan seterusnya bisa ditambahkan dengan menekan tombol "Add Termin" di bawah.
                    </Typography>
                </Grid>
            }

            <Grid item xs={12}>
                <Root>
                    <LocalRoot>
                        <Card>
                            <CardContent className={localClasses.mtoContainer}>
                                {
                                    termins.map((termin, key) => {
                                    let minDate = key === 0 ? addDays(new Date(endDate ? new Date(endDate).getTime() : new Date()), 1) : addDays(new Date(termins[key - 1].end_date), 1);
                                        return (
                                            <Grid container spacing={2} className={classes.terminContainer} key={key}>
                                                <Grid item xs={12} className={localClasses.terminTitleContainer}>
                                                    <p className={localClasses.terminTitle}>
                                                        {`Termin ${key + (startFromOne ? 1 : 2)}`}
                                                    </p>
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => handleDeleteTermin(key)}
                                                        startIcon={<DeleteOutline />}
                                                        style={{ textTransform: 'capitalize' }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sx={{ pt: 0 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={3} sx={{ pt: 0 }}>
                                                            <MuiDatePicker
                                                                label={`Start Date`}
                                                                value={termin.start_date}
                                                                minDate={termins.length - 1 > key ? new Date("1900-01-01") : minDate}
                                                                onChange={(date: Date | null) => handleDateChanged(date, 'start_date', key)}
                                                                disabled={termins.length - 1 > key}
                                                                newDesign3
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} sx={{ pt: 0 }}>
                                                            <MuiDatePicker
                                                                label={`End Date`}
                                                                value={termin.end_date}
                                                                fullWidth
                                                                minDate={termin.start_date}
                                                                onChange={(date: Date | null) => handleDateChanged(date, 'end_date', key)}
                                                                disabled={termins.length - 1 > key}
                                                                newDesign3
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} sx={{ pt: 0 }}>
                                                            <TextFieldND3
                                                                fullWidth
                                                                variant="outlined"
                                                                label="Amount"
                                                                name="amount"
                                                                value={termin.amount}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e, key)}
                                                                disabled={termins.length - 1 > key}
                                                                error={typeof errorState[key] !== "undefined" && errorState[key].amount !== ""}
                                                                helperText={typeof errorState[key] !== "undefined" && errorState[key].amount !== "" ? errorState[key].amount : ""}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3} sx={{ pt: 0 }}>
                                                            <TextFieldND3
                                                                fullWidth
                                                                variant="outlined"
                                                                label="Basic Price"
                                                                name="basic_price"
                                                                value={termin.basic_price}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e, key)}
                                                                disabled={termins.length - 1 > key}
                                                                error={typeof errorState[key] !== "undefined" && errorState[key].basic_price !== ""}
                                                                helperText={typeof errorState[key] !== "undefined" && errorState[key].basic_price !== "" ? errorState[key].basic_price : ""}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">Rp</InputAdornment>
                                                                }}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item xs={12} md>
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        label="Duration"
                                                                        name="duration"
                                                                        value={termin.duration}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanged(e, key)}
                                                                        InputProps={{
                                                                            endAdornment: <InputAdornment position="end">Bulan</InputAdornment>,
                                                                        }}
                                                                        disabled={termins.length - 1 > key}
                                                                        error={!!errorState[key].duration}
                                                                        helperText={errorState[key].duration}
                                                                    />
                                                                </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }

                                <Grid container>
                                    <Grid item xs={12} container justifyContent="center">
                                        <Button
                                            color="inherit"
                                            variant="outlined"
                                            startIcon={<Add />}
                                            onClick={handleAddTermin}
                                        >
                                            Add Termin
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {error && helperText && termins.length === 0 && (
                                <Typography 
                                    color="error" 
                                    variant="caption" 
                                    sx={{ 
                                        pt: 1,
                                        display: 'block'
                                    }}
                                >
                                    {helperText}
                                </Typography>
                            )}
                    </LocalRoot>
                </Root>
            </Grid>
        </>
    );
}

const useLocalStyles = generateStyle((theme: Theme) => ({
    terminTitleContainer: {
        display: 'flex',
        alignItem: 'center'
    },
    terminTitle: {
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
        marginRight: 10,
        lineHeight: "36.5px"
    },
    mtoContainer: {
        background: '#FAFAFA',
        padding: '24px 16px'
    }
}), "TerminForm"
)

export default TerminForm;