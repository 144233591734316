import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Grid, Theme, ButtonGroup, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem } from '@mui/material';
import Swal from 'sweetalert2';

/**
 * Components
 */
import UnitDetailForm, { IState } from './UnitDetailForm';
import UnitGalleryForm from './UnitGalleryForm';
import UnitFileForm from './UnitFileForm';
import LoadingScreen from '../../_components/LoadingScreen';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { generalErrorHandler, numberToCurrency, renderWarningButton } from '../../_utils/Helper';
import { PermissionContext } from '../../_contexts/PermissionContext';
import { generateStyle } from '../../_utils/DefaultStyle';
import { RequesterData } from './_components/_modals/AddLandlordModal';

const useStyles = generateStyle((theme: Theme) =>
({
    root: {
        maxWidth: '800px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    rootWider: {
        maxWidth: '100%',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    borderBottomLine: {
        borderBottom: '1px solid #eee'
    },
    dialogContent: {
        overflowY: 'hidden'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10,
    },
}), "UnitForm"
);

interface IProps {
    isListingSubmissionApartment?: boolean
}

const UnitForm = (props: IProps) => {
    const permissions = useContext(PermissionContext)
    const { Root, classes } = useStyles();
    const location = useLocation();
    const params: any = useParams();
    const navigate = useNavigate();
    const [formDetailChanged, setFormDetailChanged] = useState(false);

    const isListingSubmissionAparment = useMemo(() => {
        return Boolean(props.isListingSubmissionApartment)
    }, [props.isListingSubmissionApartment])

    const initialState = {
        code: '',
        signing_date: null,
        category: 0,
        pic_unit: null,
        apartment_id: null,
        is_owner: 1,
        name: '',
        tower: '',
        bedroom: 0,
        floor_level: '',
        maid_room: 0,
        unit_number: '',
        bathroom: 0,
        unit_area: '',
        view_direction: '',
        furnished: 'full',
        is_rented_guarantee: 2,
        landlord_id: null,
        landlord_label: '',
        agent_id: null,
        agent_label: '',
        assigned_advisor_id: null,
        assigned_advisor_label: '',
        service_charge_type: 0,
        service_charge: '',
        deposit_charge: '',
        is_rent: false,
        is_special_price: false,
        is_negotiable: false,
        is_dollar: false,
        is_sell: false,
        is_additional_data: false,
        is_meta: false,
        basic_price_1: '',
        basic_price_3: '',
        basic_price_6: '',
        basic_price_12: '',
        basic_price_sell: '',
        price_per_day: '',
        price_per_1_month_in_dollar: '',
        price_per_1_month: '',
        price_per_3_months: '',
        price_per_6_months: '',
        price_per_12_months: '',
        selling_price: '',
        certificate: 0,
        facilities: [],
        highlight: '',
        highlight_en: '',
        keterangan: '',
        meta_title: '',
        meta_keyword: '',
        meta_description: '',
        meta_title_en: '',
        meta_description_en: '',
        support_daily: false,
        support_1: false,
        support_3: false,
        support_6: false,
        pic_unit_name: '',
        apartment_name: '',
        submission_code: '',
        galleries: [],
        source_input: '',
        is_bu: false,
        zone_wing: ''
    };

    const currency = [
        'service_charge',
        'deposit_charge',
        'basic_price_1',
        'basic_price_3',
        'basic_price_6',
        'basic_price_12',
        'basic_price_sell',
        'price_per_day',
        'price_per_1_month_in_dollar',
        'price_per_1_month',
        'price_per_3_months',
        'price_per_6_months',
        'price_per_12_months',
        'selling_price'
    ];

    const marketplaces = [
        {
            key: 'rumah123',
            value: 'Rumah123'
        },
        // {
        //     key: 'rumah.com',
        //     value: 'Rumah.com'
        // },
        {
            key: 'olx',
            value: 'OLX'
        },
        // {
        //     key: 'mamikos',
        //     value: 'Mamikos'
        // },
        // {
        //     key: 'mitula',
        //     value: 'Mitula'
        // },
        // {
        //     key: 'trovit',
        //     value: 'Trovit'
        // }
    ];

    const [type, setType] = useState(typeof params.type !== 'undefined' ? params.type : 'details');
    const [state, setState] = useState(initialState);

    const [requesterData, setRequesterData] = useState<RequesterData>(null)

    const [uploadState, setUploadState] = useState({
        upload_to: '',
        account_id: '',
        apartment_name: ''
    })
    const [modalState, setModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [autopostAccounts, setAutopostAccounts] = useState<{
        id: string,
        email: string
    }[]>([]);

    const handleModalOpen = () => setModalState(true);
    const handleModalClose = () => setModalState(false);

    const getApartmentName = () => {
        setIsLoading(true);
        DefaultAxios.get(process.env.REACT_APP_API_URL + '/unit-marketplace/get-apartment-name', { params: { type: uploadState.upload_to, apartment_id: state.apartment_id } })
            .then(res => {
                setUploadState(prevState => ({
                    ...prevState,
                    apartment_name: res.data.apartment_name
                }));

                setAutopostAccounts(res.data.autopost_accounts)
            })
            .catch(err => {
                generalErrorHandler(err);
                setUploadState(prevState => ({
                    ...prevState,
                    apartment_name: ''
                }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (params.id) {
            DefaultAxios.get(process.env.REACT_APP_API_URL + '/unit/' + params.id)
                .then(res => {
                    const data = res.data;
                    const newState: any = { ...state };
                    for (let key in state) {
                        if (typeof data[key] !== 'undefined') {
                            if (currency.includes(key)) {
                                if (+data[key] && (+data['category'] === 1 || +data['category'] === 3)) {
                                    if (key === 'price_per_1_month') {
                                        newState['support_1'] = true;
                                    } else if (key === 'price_per_3_months') {
                                        newState['support_3'] = true;
                                    } else if (key === 'price_per_6_months') {
                                        newState['support_6'] = true;
                                    } else if (key === 'price_per_day') {
                                        newState['support_daily'] = true;
                                    }
                                }

                                if (data[key] === null || +data[key] === 0) {
                                    newState[key as keyof IState] = '';
                                } else {
                                    newState[key as keyof IState] = numberToCurrency(data[key]);
                                }
                            } else if (['is_rent', 'is_sell', 'is_negotiable', 'is_special_price', 'is_dollar', 'is_bu'].includes(key)) {
                                newState[key as keyof IState] = +data[key] === 1;
                            } else {
                                newState[key as keyof IState] = data[key];
                            }
                        }
                    }
                    setState(newState);

                    if (props.isListingSubmissionApartment && (data.submission_infos?.requester_email || data.submission_infos?.requester_name || data.submission_infos?.requester_phone)) {
                        setRequesterData({
                            email: data.submission_infos?.requester_email || undefined,
                            name: data.submission_infos?.requester_name || undefined,
                            phone: data.submission_infos?.requester_phone || undefined,
                        })
                    }
                })
                .catch(err => {
                    generalErrorHandler(err);
                });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (uploadState.upload_to) {
            setUploadState(prevState => ({
                ...prevState,
                account_id: ''
            }));
            getApartmentName();
        }
        // eslint-disable-next-line
    }, [uploadState.upload_to]);

    const renderForm = () => {
        if (params.id) {
            if (type === 'details') {
                return <UnitDetailForm
                    unitId={params.id}
                    mode="edit"
                    state={state}
                    isListingSubmissionApartment={props.isListingSubmissionApartment}
                    requesterData={requesterData}
                    changeFormDetailChanged={(data: boolean) => {
                        setFormDetailChanged(data)
                    }}
                />
            } else if (type === 'gallery') {
                return <UnitGalleryForm
                    id={params.id}
                    apiUrlImage={process.env.REACT_APP_API_URL + '/unit/' + params.id + '/image'}
                    apiUrl360={process.env.REACT_APP_API_URL + '/unit/' + params.id + '/360-photo'}
                    with360Videos
                />
            } else if (type === 'files') {
                return <UnitFileForm
                    unitId={params.id}
                />
            }
        } else {
            return <UnitDetailForm
                unitId={null}
                mode="add"
            />
        }
    }

    const handleNavigation = (route: string) => {
        if (formDetailChanged) {
            renderWarningButton('Ada data yang belum disimpan, apakah anda yakin ingin pindah menu?')
                .then((result) => {
                    if (result.value) {
                        setFormDetailChanged(false);
                        changeNavigation(route);
                    }
                })
        } else {
            changeNavigation(route);
        }
    }

    const changeNavigation = (route: string) => {
        if (route !== type) {
            if (route === 'details') {
                navigate(`/${isListingSubmissionAparment ? 'listing-submission-apartment' : 'unit'}/edit/${params.id}`);
            } else {
                navigate(`/${isListingSubmissionAparment ? 'listing-submission-apartment' : 'unit'}/edit/${params.id}/${route}`);
            }
            setType(route);
        }
    }

    const handleUploadToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUploadState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleUploadMarketplace = () => {
        if (uploadState.upload_to && uploadState.apartment_name && params.id) {
            setIsLoading(true);
            DefaultAxios.post(process.env.REACT_APP_API_URL + '/unit-marketplace', { type: uploadState.upload_to, unit_id: params.id, account_id: uploadState.account_id, apartment_name: uploadState.apartment_name })
                .then(res => {
                    Swal.fire({
                        title: 'Upload Success',
                        icon: 'success',
                        onAfterClose: handleModalClose,
                        timer: 1000
                    });
                })
                .catch(err => {
                    generalErrorHandler(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />
            <Dialog
                fullWidth
                maxWidth="md"
                open={modalState}
                onClose={handleModalClose}
            >
                <Root>
                    <DialogTitle>Upload Marketplace</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Upload To"
                                    name="upload_to"
                                    value={uploadState.upload_to}
                                    onChange={handleUploadToChange}
                                >
                                    {marketplaces.map(marketplace => <MenuItem key={marketplace.key} value={marketplace.key}>{marketplace.value}</MenuItem>)}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Account"
                                    name="account_id"
                                    value={uploadState.account_id}
                                    onChange={handleUploadToChange}
                                >
                                    {autopostAccounts.map(autopostAccount => <MenuItem key={autopostAccount.id} value={autopostAccount.id}>{autopostAccount.email}</MenuItem>)}
                                </TextField>
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nama Apartemen di Marketplace"
                                    name="apartment_name"
                                    value={uploadState.apartment_name}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={handleUploadMarketplace}
                            disabled={!(uploadState.upload_to && (uploadState.upload_to === 'olx' || uploadState.account_id) && uploadState.apartment_name && params.id)}
                        >
                            Upload
                        </Button>
                    </DialogActions>
                </Root>
            </Dialog>
            <Grid container spacing={3}>
                {
                    location.pathname !== '/unit/add'
                        ?
                        <>
                            <Grid item xs={12}>
                                <div className={`${classes.headerContainer} ${classes.borderBottomLine}`}>
                                    <h1 style={{ margin: 0, fontSize: 26, fontWeight: '600' }}>
                                        Unit {state.code || ''} - {location.pathname === `${isListingSubmissionAparment ? 'listing-submission-apartment' : '/unit/add'}` ? 'Add' : 'EDIT'}
                                    </h1>
                                    {
                                        location.pathname !== `${isListingSubmissionAparment ? 'listing-submission-apartment' : '/unit/add'}`
                                            ? <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonUploadMarketplace}
                                                onClick={handleModalOpen}
                                            >
                                                Upload Marketplace
                                            </Button>
                                            : null
                                    }
                                </div>
                            </Grid>
                            {
                                location.pathname !== `${isListingSubmissionAparment ? 'listing-submission-apartment' : '/unit/add'}` ?
                                    <Grid item xs={12}>
                                        <ButtonGroup
                                            color="primary"
                                            aria-label="outlined primary button group"
                                            fullWidth
                                        >
                                            <Button
                                                variant={type === 'details' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('details')}
                                            >
                                                Details
                                            </Button>
                                            {
                                                typeof permissions !== 'undefined' && permissions['unit.crud-image']
                                                    ? <Button
                                                        variant={type === 'gallery' ? 'contained' : 'outlined'}
                                                        onClick={() => handleNavigation('gallery')}
                                                    >
                                                        Gallery
                                                    </Button>
                                                    : null
                                            }
                                            <Button
                                                variant={type === 'files' ? 'contained' : 'outlined'}
                                                onClick={() => handleNavigation('files')}
                                            >
                                                Files
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    : null
                            }
                        </>
                        : null
                }

                <Grid item xs={12}>
                    {renderForm()}
                </Grid>
            </Grid>
        </Root>

    );
}

export default UnitForm;