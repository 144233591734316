import React, { useEffect, useState } from 'react'
import { Button, Chip, Grid, Theme, Typography, useTheme } from '@mui/material';

/**
 * Components
 */
import ModalSection from '../../../_components/_modalDetail/ModalSection';
import ModalDetail from '../../../_components/_modalDetail/ModalDetail';
import LoadingScreen from '../../../_components/LoadingScreen';
import ImagePreview from '../../../_components/ImagePreview';

/**
 * Icon
 */

/**
 * Utils
 */
import { generateStyle } from '../../../_utils/DefaultStyle';
import { formatDate, generalErrorHandler, numberToCurrency } from '../../../_utils/Helper';
import DefaultAxios from '../../../_utils/DefaultAxios';
import RejectRequestModal from './RejectRequestModal';
import { useNavigate } from 'react-router-dom';

/**
 * Modals
 */



interface Props {
    open: boolean;
    permissions: any;
    code: string;
    onClose: Function;
}

interface IDetail {
    id: number;
    is_cobroke: number;
    closing_id: number | null;
    customer_name: string;
    customer_phone: string;
    enquiry_customer_name: string;
    enquiry_customer_phone: string;
    agent_name: string | null;
    agent_phone: string | null;
    enquiry_id: number;
    apartment_id: number | null;
    unit_id: number;
    unit_bedroom: number | null;
    unit_furnished: string | null;
    unit_tower: string | null;
    unit_floor: number | null;
    unit_number: string | null;
    duration_in_month: number;
    payment_type: string;
    gmv_transaction: number;
    basic_price: number;
    holded_deposit_owner: number | null;
    holded_deposit_office: number | null;
    is_include_maintenance: number;
    is_digital_contract: number;
    agent_cobroke_internal_id: number | null;
    start_date: string;
    notes: string | null;
    status: number;
    is_termin: number;
    termin_notes: string;
    requested_by: number;
    canceled_by: number | null;
    canceled_reason: string | null;
    created_at: string;
    updated_at: string;
    closing_code: string | null;
    apartment_name: string | null;
    unit_code: string;
    enquiry_code: string;
    enquiry_label: string;
    end_date: string;
    duration: number;
    agent_cobroke_internal_name: string | null;
    payment_file_url: string;
    ktp_file_url: string;
    requested_by_name: string
}

const useStyles = generateStyle((theme: Theme) => ({
    cancel_overlay: {
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        zIndex: 1100,
        marginTop: "64px"
    },
    cancel_card: {
        textAlign: "center",
        width: "50%",
        marginLeft: "25%",
        marginTop: "10%"
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    h6_title: {
        color: theme.palette.primary.main,
    },
    actionButtonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
        overflow: 'auto',
        margin: '0 0',
        '& button, a': {
            marginLeft: '10px'
        }
    },
    extendActionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
    },
    footer: {
        display: 'flex',
        minHeight: '500px',
        backgroundColor: '#eee',
        padding: '10px',
    },
}), "ClosingModal_Detail"
)

const initialDetailValue: IDetail = {
    id: 0,
    is_cobroke: 0,
    closing_id: 0,
    customer_name: "",
    customer_phone: "",
    enquiry_customer_name: "",
    enquiry_customer_phone: "",
    agent_name: "",
    agent_phone: "",
    enquiry_id: 0,
    apartment_id: 0,
    unit_id: 0,
    unit_bedroom: 0,
    is_termin: 0,
    termin_notes: '',
    unit_furnished: "",
    unit_tower: "",
    unit_floor: 0,
    unit_number: "",
    duration_in_month: 0,
    payment_type: "",
    gmv_transaction: 0,
    basic_price: 0,
    holded_deposit_owner: 0,
    holded_deposit_office: 0,
    is_include_maintenance: 0,
    is_digital_contract: 0,
    agent_cobroke_internal_id: 0,
    start_date: "",
    notes: "",
    status: 0,
    requested_by: 0,
    canceled_by: 0,
    canceled_reason: "",
    created_at: "",
    updated_at: "",
    closing_code: "",
    apartment_name: "",
    unit_code: "",
    enquiry_code: "",
    enquiry_label: "",
    end_date: "",
    duration: 0,
    agent_cobroke_internal_name: "",
    payment_file_url: "",
    ktp_file_url: "",
    requested_by_name: ""
};


const ClosingRequestModal = (props: Props) => {
    const { code } = props;
    const { Root, classes } = useStyles();
    const navigate = useNavigate()
    const themes = useTheme()

    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState<IDetail>(initialDetailValue);
    const [openModalReject, setOpenModalReject] = useState(false)
    const [preview, setPreview] = useState('')

    useEffect(() => {
        const oldPopState = window.onpopstate

        window.onpopstate = (e: any) => {
            props.onClose();
        }

        return () => {
            window.onpopstate = oldPopState
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (code) {
            fetchDetail();
        }
        // eslint-disable-next-line
    }, [code]);

    const fetchDetail = () => {
        setIsLoading(true);
        DefaultAxios
            .get(`${process.env.REACT_APP_API_URL}/admin/closing-request/${code}`)
            .then(res => {
                setDetail(res.data);
            })
            .catch(err => {
                generalErrorHandler(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const formatPaymentType = (paymentType: string): string => {
        const paymentTypes: { [key: string]: string } = {
            cash: "Cash",
            "monthly-owner": "Monthly to Owner",
            jendelapay: "Jendelapay"
        };

        // kalo `paymentType` cocok dengan salah satu key di `paymentTypes`, kembalikan value nya
        if (paymentType in paymentTypes) {
            return paymentTypes[paymentType];
        }

        return paymentType.charAt(0).toUpperCase() + paymentType.slice(1);
    }

    const onCloseModal = () => {
        setOpenModalReject(false)
        window.history.pushState('', '', `/closing-request`)
    }

    const generateBasicInformation = () => {
        let listDetail: Array<any> = [];

        if (detail.id !== null) {
            listDetail = listDetail.concat([
                [(
                    <>
                        Requested By
                    </>
                ), <>
                    {detail.requested_by_name}
                </>],
                [`Status`, detail.status === 0 ? <Chip label="On Progress" color="warning" style={{ backgroundColor: "#F6E281", color: "#000" }} /> : detail.status === 1 ? <Chip label="Submitted" color="success" style={{ backgroundColor: themes.palette.primary.main, color: "#fff" }} /> : <Chip label="Canceled" color="error" style={{ backgroundColor: "#FFBABA", color: "#000" }} />],
                ...(detail.status === 2 ? [[`Alasan Cancel`, <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: detail.canceled_reason ?? '-' }}></div>]]: []),
                ...(detail.status === 1 ? [[`Kode Closing`, detail.closing_code ? detail.closing_code : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Nama Agent`, detail.agent_name ? detail.agent_name : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Nomor Hp Agent`, detail.agent_phone ? detail.agent_phone : "-"]] : []),
                [`Enquiry`, detail.enquiry_label ? detail.enquiry_label : "-"],
                [`Co-Broke PM Internal`, detail.agent_cobroke_internal_name ? detail.agent_cobroke_internal_name : "-"],
                [`Nama Tenant`, detail.customer_name ? detail.customer_name : "-"],
                [`Nomor Hp Tenant`, detail.customer_phone ? detail.customer_phone : "-"],
                ...(detail.is_cobroke === 0 ? [[`Kode Unit`, detail.unit_code ? detail.unit_code : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Nama Apartemen`, detail.apartment_name ? detail.apartment_name : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Jumlah Kamar`,
                    detail.unit_bedroom === 0 ? "Studio"
                        : detail.unit_bedroom === 1 ? "1 Kamar"
                            : detail.unit_bedroom === 2 ? "2 Kamar"
                                : detail.unit_bedroom === 3 ? "3 kamar"
                                    : detail.unit_bedroom ? `${detail.unit_bedroom} Kamar` : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Furnish`,
                    detail.unit_furnished === "semi" ? "Semi Furnished"
                        : detail.unit_furnished === "non" ? "Non Furnished"
                            : detail.unit_furnished === "full" ? "Full Furnished"
                                : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Tower`, detail.unit_tower ? detail.unit_tower : "-"]] : []),
                ...(detail.is_cobroke === 1 ? [[`Lantai dan Nomor Unit`, detail.unit_floor && detail.unit_number ? `${detail.unit_floor} / ${detail.unit_number}` : "-"]] : []),
                [`Notes`, detail.notes ? (<div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{ __html: detail.notes }}></div>) : "-"],
                ...(detail.status !== 1 ? [[`Bukti Pembayaran`,
                    detail.payment_file_url ? (
                        <img
                            src={detail.payment_file_url}
                            alt="Bukti Pembayaran"
                            style={{ width: 170, cursor: 'pointer' }}
                            onClick={() => {
                                setPreview(detail.payment_file_url)
                            }}
                        />
                    ) : "-"
                ]] : []),
                ...(detail.status !== 1 ? [[`Foto KTP`,
                    detail.ktp_file_url ? (
                        <img
                            src={detail.ktp_file_url}
                            alt="Foto KTP"
                            style={{ width: 170, cursor: 'pointer' }}
                            onClick={() => {
                                setPreview(detail.ktp_file_url)
                            }}
                        />
                    ) : "-"
                ]] : []),
            ])
        }
        return listDetail;
    }


    return (
        <Root>
            <LoadingScreen open={isLoading} fullScreen />

            <ModalDetail
                open={props.open}
                title={`${detail.customer_name}`}
                appBarProps={{
                    color: detail.status === 3 ? 'secondary' : 'primary',
                }}
                onCloseModal={() => props.onClose()}
                isLoading={isLoading}
                stickyHeader
            >
                <>
                    <Grid item md={6} xs={12}>
                        <ModalSection
                            title="Informasi Dasar"
                            titleContent={
                                (
                                    <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.h6_title}>
                                                Informasi Dasar {detail.is_cobroke === 1 ? <span>[Co-Broke]</span> : ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            content={generateBasicInformation()}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ModalSection
                            // title={`${[0, 2].includes(detail.cobroke_type) === false ? 'Agent' : 'Owner'} Information`}
                            titleContent={
                                (
                                    <Grid container justifyContent="space-between" style={{ marginBottom: "1em" }}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.h6_title}>
                                                Informasi Transaksi
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            content={
                                [
                                    [`Metode Pembayaran`, formatPaymentType(detail.payment_type)],
                                    [`Durasi`, `${detail.duration_in_month} Bulan`],
                                    [`Tanggal Check-In`, detail.start_date ? formatDate(detail.start_date) : "-"],
                                    [`Termin`, detail.is_termin === 1 ? "Yes" : "No"],
                                    ...(detail.is_termin === 1 ? [[`Termin Notes`, detail.termin_notes ? <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: detail.termin_notes }}></div> : "-"]]: []),
                                    [`${detail.is_termin === 1 ? 'Jumlah Transaksi (Termin 1)' : 'Jumlah Transaksi'}`, detail.gmv_transaction ? numberToCurrency(detail.gmv_transaction, "Rp.") : "-"],
                                    [`${detail.is_termin === 1 ? 'Basic Price (Termin 1)' : 'Basic Price'}`, detail.basic_price ? numberToCurrency(detail.basic_price, "Rp.") : "-"],
                                    // [`Deposit Ditahan Owner`, detail.holded_deposit_owner ? numberToCurrency(detail.holded_deposit_owner, "Rp.") : "-"], /* di hide after feedback demo */
                                    [`Deposit Ditahan Jendela360`, detail.holded_deposit_office ? numberToCurrency(detail.holded_deposit_office, "Rp.") : "-"],
                                    [`Include Maintenance`, detail.is_include_maintenance === 1 ? "Yes" : "No"],
                                    ...(detail.is_cobroke === 0 ? [[`Digital Contract`, detail.is_digital_contract === 1 ? "Yes" : "No"]]: []),
                                ]
                            }
                        />
                        {detail.status === 0 ?
                            <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: '1rem' }}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setOpenModalReject(true)}
                                        style={{ textTransform: 'none' }}
                                    >
                                        REJECT REQUEST
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate(`/closing-request/verify/${detail.id}`)}
                                        style={{ textTransform: 'none' }}
                                    >
                                        VERIFY REQUEST
                                    </Button>
                                </Grid>
                            </Grid> : null}
                    </Grid>
                </>
            </ModalDetail>
            <ImagePreview
                image={preview}
                onClose={() => setPreview('')}
            />
            <RejectRequestModal open={openModalReject} onClose={onCloseModal} setIsLoading={(value: boolean) => setIsLoading(value)} onSucces={() => fetchDetail()} id={detail.id} />
        </Root>
    )
}

export default ClosingRequestModal