import React, { useState, useReducer, useEffect } from 'react';
import { Theme, Grid, TextField, Button, Typography, MenuItem } from '@mui/material';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from "react-router-dom";
import Nationalities from '../../_data/nationalities.json'

/**
 * Components
 */
import MuiDatePicker from '../../_components/MuiDatePicker';
import LoadingScreen from '../../_components/LoadingScreen';
import TextArea from '../../_components/_form/TextArea';

/**
 * Utils
 */
import DefaultAxios from '../../_utils/DefaultAxios';
import { convertIndonesiaPhoneNumber, dateFormat, generalErrorHandler } from '../../_utils/Helper';
import { generateStyle } from '../../_utils/DefaultStyle';
import { IValidationAlias, IValidationErrors, IValidationRules, validateData } from '../../_utils/Validation';

interface IParams extends Record<string, string | undefined> {
    customer_id?: string
}

interface IState {
    name: string,
    phone: string,
    email: string,
    birth_date: Date | null,
    religion: string,
    notes: string
    bank_type: string,
    bank_account_number: string,
    bank_behalf_name: string,
    bank_branch: string,
    address: string,
    ktp_number: string,
    passport: string,
    nik: string,
    nationality: string
    sim_number: string
    npwp: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IProps {
    modal?: boolean
    id?: number
    submitModal?: () => void
}

const useStyles = generateStyle((theme: Theme) => ({
    rootContainer: {
        maxWidth: '750px',
        marginBottom: '70px',
        boxShadow: '0px 3px 15px #0000001A',
        '& .Mui-disabled': {
            backgroundColor: '#eee'
        }
    },
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
        margin: theme.spacing(2),
        marginTop: theme.spacing(0)
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        textAlign: 'right',
        marginBottom: '10px'
    }
}), "CustomerForm_Main"
)

const CustomerFormMain = (props: IProps) => {
    const { Root, classes } = useStyles();
    const API_URL = process.env.REACT_APP_API_URL + '/customer';
    const params = useParams<IParams>()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Error State
    // const errorReducer = (state: IErrorState, action: IErrorAction) => {
    //     if (action.type === 'SET_ITEM') {
    //         return {
    //             ...state,
    //             [action.name]: action.value
    //         }
    //     } else if (action.type === 'RESET_ITEM') {
    //         return {
    //             name: '',
    //             nickname: '',
    //             phone: '',
    //             email: '',
    //             bank_type: '',
    //             bank_account_number: '',
    //             bank_behalf_name: '',
    //             bank_branch: '',
    //             address: '',
    //             ktp_number: '',
    //             nik: '',
    //         }
    //     } else if (action.type === 'REPLACE_STATE') {
    //         const newState = action.value;
    //         return { ...newState as IErrorState };
    //     }

    //     return { ...state };
    // };

    const errorState = {
        name: '',
        phone: '',
        email: '',
        bank_type: '',
        bank_account_number: '',
        bank_behalf_name: '',
        bank_branch: '',
        address: '',
        ktp_number: '',
        nik: '',
        nationality: '',
        sim_number: '',
        npwp: '',
    };

    // const [errorState, setErrorState] = useReducer(errorReducer, {
    //     name: '',
    //     nickname: '',
    //     phone: '',
    //     email: '',
    //     bank_type: '',
    //     bank_account_number: '',
    //     bank_behalf_name: '',
    //     bank_branch: '',
    //     address: '',
    //     ktp_number: '',
    //     nik: '',
    // });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                email: '',
                birth_date: null,
                religion: '',
                notes: '',
                bank_type: '',
                bank_account_number: '',
                bank_behalf_name: '',
                bank_branch: '',
                address: '',
                ktp_number: '',
                passport: '',
                nik: '',
                nationality: '',
                sim_number: '',
                npwp: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        email: '',
        birth_date: null,
        religion: '',
        notes: '',
        bank_type: '',
        bank_account_number: '',
        bank_behalf_name: '',
        bank_branch: '',
        address: '',
        ktp_number: '',
        passport: '',
        nik: '',
        nationality: '',
        sim_number: '',
        npwp: '',
    });

    const [error, setError] = useState<IValidationErrors>(errorState);

    useEffect(() => {
        if (props.modal && props.id !== 0) {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${props.id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        else if (typeof params.customer_id !== "undefined") {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.customer_id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;

            if (target.name === 'phone') {
                setInputState({ name: target.name, value: convertIndonesiaPhoneNumber(value), type: 'SET_ITEM' });
            } else {
                setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
            }
        }

        setError((prev) => ({
            ...prev,
            [target.name]: "",
          }));
    }

    // TODO: might break
    const handleDateChanged = (name: string, date: Date | null) => {
        setInputState({ name, value: date, type: 'SET_ITEM' });
    }

    const submitForm = () => {
        const { isValid, errors } = validateData(
            inputState,
            inputStateRules,
            inputStateAlias
          );
      
          if (!isValid) {
            setError(errors);
            return;
          }

        let axios;
        let payload: any = {
            ...inputState,
            sim_number: inputState.nationality === 'Indonesian' ? inputState.sim_number : null,
            npwp: inputState.nationality === 'Indonesian' ? inputState.npwp : null,
        };

        if (payload.birth_date) {
            payload.birth_date = dateFormat(payload.birth_date, 'YYYY-MM-DD');
        }

        if (props.modal && props.id !== 0) {
            axios = DefaultAxios.patch(`${API_URL}/${props.id}`, payload)
        } else if (typeof params.customer_id !== "undefined") {
            axios = DefaultAxios.patch(`${API_URL}/${params.customer_id}`, payload)
        } else {
            axios = DefaultAxios.post(`${API_URL}`, payload);
        }

        setLoading(true);
        axios
            .then(res => {
                Swal.fire({
                    title: `${typeof params.customer_id !== "undefined" || props.modal ? 'Customer Updated' : 'Customer Submitted'}`,
                    icon: 'success',
                    onAfterClose: () => {
                        if (props.modal) {
                            props.submitModal && props.submitModal()
                        } else {
                            navigate(`${typeof params.customer_id !== "undefined" ? `/customer/${params.customer_id}` : '/customer'}`, {
                                state: { reload: true }
                            })
                        }
                    },
                    timer: 1000
                });
            })
            .catch(error => {
                generalErrorHandler(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#eee';
        let timeout: any;
        return () => {
            clearTimeout(timeout);
            document.body.style.backgroundColor = '#fff';
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Root>
            <Grid container spacing={2}>
                <LoadingScreen open={loading} fullScreen />

                <Grid item xs={12}>
                    <Typography variant="h4">
                        Informasi Dasar
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        error={!!error.name}
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={inputState.name}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={error.name}
                        placeholder="Name according to ID card / proof of ownership"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        error={!!error.phone}
                        label="Phone Number"
                        variant="outlined"
                        name="phone"
                        value={inputState.phone}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={error.phone}
                        type="text"
                        placeholder="628xxxxxxxxxx"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        error={!!errorState.email}
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={inputState.email}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.email}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextArea
                        label="Alamat"
                        name="address"
                        value={inputState.address}
                        onChange={handleInputChanged}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Agama"
                        variant="outlined"
                        name="religion"
                        value={inputState.religion}
                        onChange={handleInputChanged}
                        placeholder="C: Islam"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        variant="outlined"
                        onChange={handleInputChanged}
                        label="Nationality (Wajib diisi)"
                        name="nationality"
                        value={inputState.nationality}
                        fullWidth
                        error={!!error.nationality}
                        helperText={error.nationality}
                    >
                        {
                            Nationalities.map(nationality =>
                                <MenuItem key={nationality} value={nationality}>{nationality}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <MuiDatePicker
                        label="Tanggal Lahir"
                        value={inputState.birth_date}
                        onChange={(date: any) => handleDateChanged('birth_date', date)}
                    />
                </Grid>
                {(inputState.nationality && inputState.nationality !== "Indonesian") ?
                    <Grid item xs={6}>
                        <TextField
                            label="Passport"
                            variant="outlined"
                            name="passport"
                            value={inputState.passport}
                            onChange={handleInputChanged}
                            fullWidth
                            placeholder="Passpor"
                        />
                    </Grid>
                    :
                    <Grid item xs={6}>
                        <TextField
                            label="KTP Number"
                            variant="outlined"
                            name="ktp_number"
                            value={inputState.ktp_number}
                            onChange={handleInputChanged}
                            fullWidth
                            type="number"
                            placeholder="16 digit numbers: 3314800000000000"
                        />
                    </Grid>
                }
                {
                    inputState.nationality === 'Indonesian' &&
                    <>
                        <Grid item xs={6}>
                            <TextField
                                label="Nomor SIM"
                                variant="outlined"
                                name="sim_number"
                                value={inputState.sim_number}
                                onChange={handleInputChanged}
                                fullWidth
                                type="number"
                                placeholder='14 digit numbers: 3314800000000000'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="NPWP"
                                variant="outlined"
                                name="npwp"
                                value={inputState.npwp}
                                onChange={handleInputChanged}
                                fullWidth
                                type="number"
                                placeholder='15 digit numbers: 3314800000000000'
                            />
                        </Grid>
                    </>
                }

                {/* <Grid item xs={12}>
                        <Typography variant="h4">
                            Informasi Bank
                        </Typography>
                    </Grid>

                    <Grid item xs={2}>
                        <TextField
                            multiline
                            label="Nama Bank"
                            variant="outlined"
                            name="bank_type"
                            placeholder="C: BCA"
                            value={inputState.bank_type}
                            onChange={handleInputChanged}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField
                            multiline
                            label="Nomor Rekening Bank"
                            variant="outlined"
                            name="bank_account_number"
                            value={inputState.bank_account_number}
                            onChange={handleInputChanged}
                            placeholder="C: 2063388360"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            label="Nama Pemilik Rekening Bank"
                            variant="outlined"
                            name="bank_behalf_name"
                            placeholder="C: Vidi Vici Digital"
                            value={inputState.bank_behalf_name}
                            onChange={handleInputChanged}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            label="Bank KCP"
                            variant="outlined"
                            name="bank_branch"
                            placeholder="C: Kemanggisan"
                            value={inputState.bank_branch}
                            onChange={handleInputChanged}
                            fullWidth
                        />
                    </Grid>
                    */}
                <Grid item xs={12} className={classes.gridSubmit}>
                    <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Root>
    );
}

const inputStateAlias: IValidationAlias = {
    name: "Name",
    phone: "Phone",
    nationality: "Nationality",
  };
  
  const inputStateRules: IValidationRules = {
    name: "required",
    phone: "required",
    nationality: "required",
  };

export default CustomerFormMain;