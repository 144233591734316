import React, { useState, useReducer, useEffect, useMemo } from 'react';
import {
    Theme,
    Grid,
    TextField as CommonTextField,
    Button,
    Typography
} from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";

// Components
import LoadingScreen from '../../../_components/LoadingScreen';

// Utils
import DefaultAxios from '../../../_utils/DefaultAxios';
import AsyncAutoComplete, { IAutoCompleteOption } from '../../../_components/_form/AsyncAutoComplete';

// Icon
import { generalErrorHandler, isValidEmail, renderSuccessButton } from '../../../_utils/Helper';
import { generateStyle } from '../../../_utils/DefaultStyle';
import TextFieldND2 from '../../../_components/_formElements/_newDesign2/TextFieldND2';

interface IParams extends Record<string, string | undefined> {
    agent_id?: string
}

interface IState {
    name: string,
    phone: string,
    email: string,
    notes: string,
    address: string,
    agent_office_id: string;
    agent_office_name: string;
    bank_type: string;
    bank_behalf_name: string;
    bank_branch: string;
    bank_number: string
}

interface IAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

interface IErrorState {
    name: string;
    phone: string;
    email: string
}

interface IErrorAction {
    name: string,
    value: object | Date | null | string | number,
    type: string;
}

const useStyles = generateStyle((theme: Theme) => ({
    submit_button: {
        marginLeft: '8px'
    },
    delete_button: {
        float: 'right'
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        '& .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
    },
    label: {
        backgroundColor: 'white',
        padding: "2px"
    },
    paper_label: {
        position: "absolute",
        marginTop: "-45px",
        padding: "1px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)"
    },
    paper_label_text: {
        backgroundColor: "#0094ff",
        fontSize: "1.5em",
        padding: "13px",
        borderRadius: "4px",
        color: "white"
    },
    grid: {
        paddingBottom: '20px'
    },
    gridSubmit: {
        paddingTop: '10px'
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }
}), "Agent_Form"
)

interface IProps {
    newDesign?: boolean
    onSuccessSubmit?: (data?: IAgentResponse) => void
    onSubmit?: number
    onReset?: number
    isClosingRequest?: boolean
    initialName?: string
    initialPhone?: string
}

export interface IAgentResponse {
    id:               string;
    name:             string;
    phone:            string;
    address:          null;
    bank_behalf_name: null;
    bank_number:      null;
    bank_type:        null;
    bank_branch:      null;
    email:            string;
    notes:            null;
    is_active:        number;
    created_by:       number;
    modified_by:      number;
    updated_at:       Date;
    created_at:       Date;
}

const AgentForm = (props: IProps) => {
    const { Root, classes } = useStyles();
    const API_URL = `${process.env.REACT_APP_API_URL}/agent`;
    const params = useParams<IParams>()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const TextField = useMemo(() => {
        if (props.newDesign) {
            return TextFieldND2
        }

        return CommonTextField
    }, [props.newDesign])

    // Error State
    const errorReducer = (state: IErrorState, action: IErrorAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                email: ''
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IErrorState };
        }

        return { ...state };
    };

    const [errorState, setErrorState] = useReducer(errorReducer, {
        name: '',
        phone: '',
        email: ''
    });

    // Input State
    const inputReducer = (state: IState, action: IAction) => {
        if (action.type === 'SET_ITEM') {
            return {
                ...state,
                [action.name]: action.value
            }
        } else if (action.type === 'RESET_ITEM') {
            return {
                name: '',
                phone: '',
                email: '',
                notes: '',
                address: '',
                agent_office_id: '',
                agent_office_name: '',
                bank_type: '',
                bank_behalf_name: '',
                bank_number: '',
                bank_branch: '',
            }
        } else if (action.type === 'REPLACE_STATE') {
            const newState = action.value;
            return { ...newState as IState };
        }

        return { ...state };
    };

    const [inputState, setInputState] = useReducer(inputReducer, {
        name: '',
        phone: '',
        email: '',
        notes: '',
        address: '',
        agent_office_id: '',
        agent_office_name: '',
        bank_type: '',
        bank_behalf_name: '',
        bank_number: '',
        bank_branch: '',
    });

    useEffect(() => {
        if (props.isClosingRequest) {
            console.log('ini closing', props.initialName);
            
            setInputState({
                type: 'REPLACE_STATE',
                name: '',
                value: {
                    ...inputState, 
                    phone: props.initialPhone,
                    name: props.initialName
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isClosingRequest, props.initialName, props.initialPhone])

    useEffect(() => {
        if (typeof params.agent_id !== "undefined") {
            setLoading(true);
            DefaultAxios
                .get(`${API_URL}/${params.agent_id}`)
                .then(res => {
                    const data = res.data;
                    setInputState({ name: '', value: data, type: 'REPLACE_STATE' });
                    setLoading(false);
                })
        } else {
            setInputState({ name: '', value: '', type: 'RESET_ITEM' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const handleAutocompleteInputChanged = (e: any, name: string) => {
        setInputState({ name, value: null, type: 'SET_ITEM' });
    }

    const handleAutocompleteOffice = (name: string, value: IAutoCompleteOption) => {
        const newState = { ...inputState };
        newState.agent_office_id = value.id.toString();
        newState.agent_office_name = value.label;

        setInputState({ name: '', value: newState, type: 'REPLACE_STATE' });

    }

    const handleInputChanged = (e: any) => {
        const target = e.target;
        if (target) {
            let value = target.value;
            setInputState({ name: target.name, value: value, type: 'SET_ITEM' });
        }
    }

    const checkValidation = () => {
        let isValid = true;
        let newError = { ...errorState };

        if (!inputState.name) {
            newError.name = 'Nama wajib diisi';
            isValid = false;
        } else {
            newError.name = '';
        }

        if (!inputState.phone) {
            newError.phone = 'No Hp wajib diisi';
            isValid = false;
        } else {
            newError.phone = '';
        }

        if (inputState.email !== null && inputState.email !== "" && !isValidEmail(inputState.email)) {
            newError.email = 'Format email salah';
            isValid = false;
        } else {
            newError.email = '';
        }

        setErrorState({ type: 'REPLACE_STATE', name: '', value: newError });
        return isValid;
    }

    useEffect(() => {
        if (props.isClosingRequest) {
            setInputState({
                name: '',
                type: 'REPLACE_STATE',
                value: {
                    ...inputState,
                    name: props.initialName,
                    phone: props.initialPhone
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isClosingRequest, props.initialName, props.initialPhone])

    const submitForm = () => {
        if (!checkValidation()) {
            return;
        }

        let axios;
        if (typeof params.agent_id !== "undefined") {
            axios = DefaultAxios.patch(`${API_URL}/${params.agent_id}`, inputState)
        } else {
            axios = DefaultAxios.post(API_URL, inputState);
        }

        setLoading(true);
        axios
            .then(res => {
                renderSuccessButton("Agent Submitted")
                    .then(resSwal => {
                        if (typeof props.onSuccessSubmit !== 'undefined') {
                            return props.onSuccessSubmit(res.data)
                        }

                        navigate(`${typeof params.agent_id !== "undefined" ? `/agent/${params.agent_id}` : '/agent'}`, {
                            state: { reload: true }
                        })
                    })
            })
            .catch(error => {
                generalErrorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const resetAllForm = () => {
        setInputState({name: '', type: 'RESET_ITEM', value: null})
        setErrorState({name: '', type: 'RESET_ITEM', value: null})
    }

    useEffect(() => {
        if (props.onSubmit) {
            submitForm()
        }

        // eslint-disable-next-line
    }, [props.onSubmit])

    useEffect(() => {
        if (props.onReset) {
            resetAllForm()
        }

        // eslint-disable-next-line
    }, [props.onReset])

    return (
        <Root>
            <Grid container spacing={2}>
                <LoadingScreen open={loading} fullScreen />
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: '500' }}>
                        Informasi Dasar
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        error={!!errorState.name}
                        label="Nama"
                        variant="outlined"
                        name="name"
                        value={inputState.name}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.name}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        error={!!errorState.phone}
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        value={inputState.phone}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.phone}
                        type="number"
                        placeholder="628xxxxxxxxxx"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        error={!!errorState.email}
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={inputState.email}
                        onChange={handleInputChanged}
                        fullWidth
                        helperText={errorState.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AsyncAutoComplete
                        label="Agent Office"
                        name="agent_office_id"
                        initialQuery={inputState.agent_office_name}
                        onChange={handleAutocompleteOffice}
                        onInputChange={handleAutocompleteInputChanged}
                        url={`${process.env.REACT_APP_API_URL}/autocomplete/agent-office`}
                        placeholder="Agent Office"
                        newDesign={props.newDesign ? 2 : undefined}
                    />
                </Grid>

                {/* <Grid item xs={12}>
                <TextField
                    multiline
                    label="Address"
                    variant="outlined"
                    name="address"
                    value={inputState.address}
                    onChange={handleInputChanged}
                    fullWidth
                />
            </Grid> */}

                <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: '500' }}>
                        Informasi Bank
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        multiline
                        label="Nama Bank"
                        variant="outlined"
                        name="bank_type"
                        placeholder="C: BCA"
                        value={inputState.bank_type}
                        onChange={handleInputChanged}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        multiline
                        label="Nomor Rekening Bank"
                        variant="outlined"
                        name="bank_number"
                        value={inputState.bank_number}
                        onChange={handleInputChanged}
                        placeholder="C: 2063388360"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        multiline
                        label="Nama Pemilik Rekening Bank"
                        variant="outlined"
                        name="bank_behalf_name"
                        placeholder="C: Vidi Vici Digital"
                        value={inputState.bank_behalf_name}
                        onChange={handleInputChanged}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        multiline
                        label="Bank KCP"
                        variant="outlined"
                        name="bank_branch"
                        placeholder="C: Kemanggisan"
                        value={inputState.bank_branch}
                        onChange={handleInputChanged}
                        fullWidth
                    />
                </Grid>
                {/* <Grid item xs={12}>
                <TextField
                    multiline
                    label="Notes"
                    variant="outlined"
                    name="notes"
                    value={inputState.notes}
                    onChange={handleInputChanged}
                    fullWidth
                />
            </Grid> */}
                {
                    typeof props.onSubmit !== 'undefined' ?
                        null :
                        <Grid item xs={12} className={classes.gridSubmit}>
                            <Button variant="contained" color="primary" onClick={submitForm} className={classes.submit_button}>
                                Submit
                            </Button>
                        </Grid>
                }
            </Grid>
        </Root>
    );
}

export default AgentForm;